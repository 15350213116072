import React, { useRef, useState } from 'react'
import "./flatpickrall.scss"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import { IconCalendarLight } from '../../../icon/icons';
const FlatpickrAll = () => {

 
    const [selectedDate, setSelectedDate] = useState(null);
  const flatpickrRef = useRef(null); // Create a ref for Flatpickr

  // Handler to open the Flatpickr calendar when the icon is clicked
  const handleIconClick = () => {
    flatpickrRef.current.flatpickr.open(); // Open Flatpickr using the ref
  };


  return (
    <>
     <div className="date-input-inner">
      <Flatpickr
      
        id="date"
        className="date_picker"
        placeholder="Select dates"
        name="date"
        options={{
          mode: "range",
          disableMobile: true,
          minDate: "today",
          dateFormat: "d/m/Y",
        }}
        value={selectedDate} // Bind selected date
        onChange={(date) => setSelectedDate(date)} // Update state on date change
        ref={flatpickrRef} // Attach ref to Flatpickr
      />
      <span onClick={handleIconClick}>
        <IconCalendarLight /> {/* Icon to open calendar */}
      </span>
    </div>
    </>
  )
}
 
export default FlatpickrAll