import React, { useState } from "react";

const UseSidebar = () => {
  const [IsCollapsed, setIsCollapsed] = useState(false);
  return {
    IsCollapsed,
    setIsCollapsed,
  };
};

export default UseSidebar;
