import React, { useState } from "react";
import "./descopeitemsmodal.scss";
import { IconClose } from "../../../../../icon/icons";
import Modal from "react-bootstrap/Modal";
import { Autocomplete, TextField } from "@mui/material";


const DescopeItemsModal = (props) => {
  

  const { showItemsmodal, handleCloseItemsmodal } = props;

  const TypesList = [
    { _id: "1", title: "Demo-1" },
    { _id: "2", title: "Demo-2" },
    { _id: "3", title: "Demo-3" },
    { _id: "4", title: "Demo-4" },
  ];

  const selectedType = { _id: "2", title: "Demo-2" };

  const [divs, setDivs] = useState([1]);

  const addDiv = () => {
    setDivs([...divs, divs.length + 1]);
  };

  const removeDiv = (index) => {
    setDivs(divs.filter((_, i) => i !== index));
  };

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data modal-descop-items"
        show={showItemsmodal}
        onHide={handleCloseItemsmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseItemsmodal}>
              <IconClose />
            </button>
          </div>
          <div className="form-data-main purchase-form-new">
          <div className="page-card-bg">
            <div className="form-new-grid-main">
            <div className="purchase-overflow-data">
              <div className="div-label-all-grid">
              <div className='list-sno-main'> 
                <label>S. No.</label>
                </div>
                <div className="input-main-data">
                  <label>Ref. BOQ</label>
                </div>
                <div className="input-main-data">
                  <label>Description</label>
                </div>

                <div className="input-main-data">
                  <label>Unit</label>
                </div>
                <div className="input-main-data">
                  <label>Qty</label>
                </div>

                <div className="input-main-data">
                  <label>Unit price</label>
                </div>
                <div className="input-main-data">
                  <label>Amount</label>
                </div>
                <div className="remove-btn"></div>
              </div>

              {divs.map((div, index) => (
                <div className="input-first-div-main" key={index}>
                  <div className='list-sno-main'> 
                <label>1</label>
                </div>
                  <div className="input-main-data">
                    <input placeholder="item name" />
                  </div>
                  <div className="input-main-data">
                    <input placeholder="item name" />
                  </div>

                  <div className="input-main-data">
                    <Autocomplete
                      fullWidth
                      className="automcomplete_select_info"
                      autoComplete={false}
                      id="free-solo-demo"
                      options={TypesList}
                      getOptionLabel={(option) => option?.title}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Type" />
                      )}
                      value={selectedType}
                      onChange={(e, value) => {
                        console.log("Selected Value:", value);
                      }}
                    />
                  </div>

                  <div className="input-main-data">
                    <Autocomplete
                      fullWidth
                      className="automcomplete_select_info"
                      autoComplete={false}
                      id="free-solo-demo"
                      options={TypesList}
                      getOptionLabel={(option) => option?.title}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Type" />
                      )}
                      value={selectedType}
                      onChange={(e, value) => {
                        console.log("Selected Value:", value);
                      }}
                    />
                  </div>

                  <div className="input-main-data">
                    <input placeholder="note" />
                  </div>

                  <div className="input-main-data">
                    <input placeholder="note" />
                  </div>
 
                  {/* {index !== 0 && ( */}
                  <div className="remove-btn">
                    <button onClick={() => removeDiv(index)}>
                      {" "}
                      <IconClose />
                    </button>
                  </div>
                  {/* )} */}
                </div>
              ))}
            </div>

            <div className="add-more-owner-btn">
              <button className="common-button" onClick={addDiv}>
                <span>+</span> Add item
              </button>
            </div>
            </div>
          </div>
          <div className="btn-form-send">
        <button className="common-button">Descope</button>

        <button className="common-button cancel-btn">Cancel</button>
      </div>
          </div>
         
      
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DescopeItemsModal;
