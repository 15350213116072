import React from "react";
import "./enquiryestimationlist.scss";
import {
  CommonFilters,
  EnquiryEstimationTableList,
  TitleHeader,
} from "../../../../components";
import { useNavigate } from "react-router-dom";
const EnquiryEstimationList = () => {
  const breadcrumbs = [{ label: "Enquiry", link: "" }];
  const navigate = useNavigate();
  const handleAddClientClick = () => {
    navigate("/estimation/add-estimation");
  };

  return (
    <>
      <TitleHeader
        heading="Estimation"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add Client"
        onButtonClick={handleAddClientClick}
      />
      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Enquiry List" />
        <EnquiryEstimationTableList />
      </div>
    </>
  );
};

export default EnquiryEstimationList;
