import React, { useState } from 'react'
import "./addrfqproject.scss"
import { AddPurchaseForm, AddRfqProjectForm, TitleHeader } from '../../../../../components'
import { useNavigate } from 'react-router-dom';


const AddRfqProject = () => {

  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Vehicles", link: "" },
    { label: "Add New Vehicle", link: "" },
  ];
 
  const handleAddClientClick = () => {
    // navigate("/subcon-vendor/add-subcon");
  };

  return (
    <>
     <TitleHeader
        heading="Add New RFQ - Project"
        breadcrumbs={breadcrumbs}
        buttonText="View BOQ"
        onButtonClick={handleAddClientClick}
      />
    

     <AddRfqProjectForm/>
  

    </>
  )
}

export default AddRfqProject 