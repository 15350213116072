import React, { useState } from "react";
import "./polisttable.scss";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { IconButtonDots, IconClose, IconEyes } from "../../../../../icon/icons";
import Price from "../../../../layout/price";
import TableActionButton from "../../../../layout/tableactionbutton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonPaginate from "../../../../layout/commonpaginate";

import PolistTableUtils from "./polisttableUtils";
import DescopeItemsModal from "../descopeitemsmodal";
const PolistTable = () => {
  const data = [
    {
      date: "19 Nov, 2023",
      number: "245879",
      P_number: "245879",
      project_name: "Project one",
      Project_Types: "Main",
      Project_category: "Civil",
      project_id: "#2458",
      user_name: "Jenny Wilson",
      user_id: "245879",
      price_syb: "$",
      price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      P_number: "245879",
      project_name: "Project one",
      Project_Types: "Main",
      Project_category: "Civil",
      project_id: "#2458",
      user_name: "Jenny Wilson",
      user_id: "245879",
      price_syb: "$",
      price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      P_number: "245879",
      project_name: "Project one",
      Project_Types: "Main",
      Project_category: "Civil",
      project_id: "#2458",
      user_name: "Jenny Wilson",
      user_id: "245879",
      price_syb: "$",
      price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      P_number: "245879",
      project_name: "Project one",
      Project_Types: "Main",
      Project_category: "Civil",
      project_id: "#2458",
      user_name: "Jenny Wilson",
      user_id: "245879",
      price_syb: "$",
      price_value: "100,000.00",
    },
  ];



  const {
    showItemsmodal,
    setShowItemsModal,
    handleCloseItemsmodal,
    handleShowItemsmodal,
  } = PolistTableUtils();

  // Profile Drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const options = [
    
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
  ];

  return (
    <>
      <div className="table-data-main Estimation-table-new">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Quote number</th>
              <th>PO number</th>
              <th>Project Name</th>
              <th>Project Types</th>
              <th>Project category</th>
              <th>Client</th>
              <th>Total Amount (Incl. 5% VAT)</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="user-info">
                    <span>{item.date}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <span>#{item.number}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <span>#{item.P_number}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.project_name}</p>
                    <span>{item.project_id}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.Project_Types}</p>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.Project_category}</p>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.user_name}</p>
                    <span>{item.user_id}</span>
                  </div>
                </td>

                <td>
                  <div
                    className="price-option-table"
                    style={{ width: "250px" }}
                  >
                    <div className="price-all-main">
                      <Price
                        price_main={item.price_value}
                        price_symbol={item.price_syb}
                      />
                    </div>
                  </div>
                </td>

                <td>
                  <div className="table-inner-grid-dta">
                    <div className="btn-table-new">
                      {/* <div className="btn-dis-new btn-create-quote">
                        <button className="common-button">Create Quote</button>
                      </div> */}
                      <div className="btn-dis-new">
                        <button
                          className="btn-viwe-icon"
                          onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>
                        <TableActionButton options={options} isOpen={true} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="profile_slider_bar side_barcomman_main"
      >
        <div className="side_bar_inner_data">
          <div className="closemodal_btn">
            <button onClick={toggleDrawer}>
              <IconClose />
            </button>
          </div>

          <div className="div_data_main">
            <div className="heading-main">
              <h2 className="common-heading">More details</h2>
            </div>
            <div className="data-list-flex">
              <div className="list-data-one">
                <p>Date</p>
                <span>19 Nov, 2023</span>
              </div>
              <div className="list-data-one">
                <p>Quote number</p>
                <span>#245879</span>
              </div>

              <div className="list-data-one">
                <p>PO number</p>
                <span>#245879</span>
              </div>
              <div className="list-data-one">
                <p>Project Name</p>
                <span>ABC</span>
              </div>

              <div className="list-data-one">
                <p>Project Number</p>
                <span>#1458</span>
              </div>
              <div className="list-data-one">
                <p>Project Types</p>
                <span>Enquiry Name</span>
              </div>

              <div className="list-data-one">
                <p>Client</p>
                <span>Jenny Wilson</span>
              </div>

              <div className="list-data-one">
                <p>Client Number</p>
                <span>1458</span>
              </div>

              <div className="list-data-one">
                <p>Project category</p>
                <span>Name Here</span>
              </div>

              <div className="list-data-one">
                <p>Discount</p>
                {/* <span>20,000.00</span> */}
                <div className="price-new-Drawer">
                  <Price price_main="20,000.00" price_symbol="" />
                </div>
              </div>

              <div className="list-data-one">
                <p> Sub Total Amount (Excl. VAT) </p>
                <div className="price-new-Drawer">
                  <Price price_main="10,000.00" price_symbol="" />
                </div>
              </div>

              <div className="list-data-one">
                <p> VAT Amount (5%) </p>
                <div className="price-new-Drawer">
                  <Price price_main="5,000.00" price_symbol="" />
                </div>
              </div>

              <div className="list-data-one">
                <p> Contractual completion date</p>
                <span>23 Dec, 2024</span>
              </div>

              <div className="list-data-one">
                <p> Total Amount (Incl. VAT) </p>
                <div className="price-new-Drawer">
                  <Price price_main="105,000.00" price_symbol="" />
                </div>
              </div>
            </div>
            <div className="button-mid-data">
              <button class="common-button"   onClick={() => handleShowItemsmodal()}>Descope items</button>
            </div>
          </div>
         
        </div>
        <div className="bottom-btn-attachmenet">
            <button
              class="common-button"
              // onClick={() => handleShowProfilemodal()}
            >
              View BOQ
            </button>

            <button class="common-button">View PO</button>
          </div>
      </Drawer>

      <div className="paginate_all_common">
      <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
      </div>


      <DescopeItemsModal
       showItemsmodal={showItemsmodal}
       handleCloseItemsmodal={handleCloseItemsmodal}
      />
    </>
  );
};

export default PolistTable;
