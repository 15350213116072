import React from "react";
import "./topagents.scss";
import { NavLink } from "react-router-dom";
import { IconArrowRight } from "../../../../icon/icons";
import Table from "react-bootstrap/Table";


const data = [
  {
    rank: 1,
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    totalenquries: "1,150",
    totalapprovedenquiries: "1,108",
    quotessubmitted: "2,108",
    quotesapproved: "108",
  },
  {
    rank: 1,
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    totalenquries: "1,150",
    totalapprovedenquiries: "1,108",
    quotessubmitted: "2,108",
    quotesapproved: "108",
  },
  {
    rank: 1,
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    totalenquries: "1,150",
    totalapprovedenquiries: "1,108",
    quotessubmitted: "2,108",
    quotesapproved: "108",
  },
  {
    rank: 1,
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    totalenquries: "1,150",
    totalapprovedenquiries: "1,108",
    quotessubmitted: "2,108",
    quotesapproved: "108",
  },
];


const TopAgents = () => {


  return (
    <div className="page-card-bg">
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> Top Agents - Monthly </h2>
        </div>
        <div className="view-all">
          <NavLink>
            View all{" "}
            <span>
              {" "}
              <IconArrowRight />{" "}
            </span>
          </NavLink>
        </div>
      </div>

      <div className="all-table-data">
        <div className="table-data-main table-all-inner table-agent-data">
          <Table responsive>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Agent Name </th>
                <th>
                  {" "}
                  <div className="table-text-agent"> Total Enquries </div>{" "}
                </th>
                <th>
                  <div className="table-text-agent">
                    Total approved
                    <br />
                    enquiries
                  </div>
                </th>
                <th>
                  <div className="table-text-agent">
                    Value of quotes
                    <br />
                    submitted
                  </div>
                </th>
                <th>
                  <div className="table-text-agent">
                    Value of Quotes
                    <br />
                    Approved
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="user-rank">
                      <p>{item.rank}</p>
                    </div>
                  </td>

                  <td>
                    <div className="user-type">
                      <span>{item.name}</span>
                      <p>#{item.id}</p>
                    </div>
                  </td>
                  <td>
                    <div className="user-type">
                      <span>{item.totalenquries}</span>
                    </div>
                  </td>

                  <td>
                    <div className="user-type">
                      <span>{item.totalapprovedenquiries}</span>
                    </div>
                  </td>

                  <td>
                    <div className="user-type">
                      <span>{item.quotessubmitted}</span>
                    </div>
                  </td>

                  <td>
                    <div className="user-type">
                      <span>{item.quotesapproved}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TopAgents;
