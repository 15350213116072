import React from "react";
import "./recentlyaddedclients.scss";
import { NavLink } from "react-router-dom";
import { IconArrowRight } from "../../../../icon/icons";
import Table from "react-bootstrap/Table";

const data = [
  {
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  }, 
  {
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
];

const RecentlyAddedClients = () => {
  return (
    <div className="page-card-bg">
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> Recently Added clients </h2>
        </div>
        <div className="view-all">
          <NavLink>
            View all{" "}
            <span>
              {" "}
              <IconArrowRight />{" "}
            </span>
          </NavLink>
        </div>
      </div>

      <div className="all-table-data">
        <div className="table-data-main table-all-inner">
          <Table responsive>
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Source </th>
                <th>Date added</th>
                <th>Added by</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="user-info">
                      <p>{item.name}</p>
                      <span>#{item.id}</span>
                    </div>
                  </td>
                  <td>
                    <div className="user-info">
                      <p>{item.role}</p>
                    </div>
                  </td>

                  <td>
                    <div className="user-info">
                      <p>{item.date}</p>
                    </div>
                  </td>

                  <td>
                    <div className="user-info">
                      <p>{item.Added_by_name}</p>
                      <span>#{item.Added_by_id}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RecentlyAddedClients;
