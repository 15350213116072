import React, { useState } from "react";

const VendorTableDataUtils = () => {
  const [showProfilemodal, setShowProfileModal] = useState(false);
  const handleCloseProfilemodal = () => setShowProfileModal(false);
  const handleShowProfilemodal = () => setShowProfileModal(true);

  const [showProjectmodal, setShowProjectModal] = useState(false);
  const handleCloseProjectmodal = () => setShowProjectModal(false);
  const handleShowProjectmodal = () => setShowProjectModal(true);

  return {
    showProfilemodal,
    setShowProfileModal,
    handleCloseProfilemodal,
    handleShowProfilemodal,
    showProjectmodal,
    setShowProjectModal,
    handleCloseProjectmodal,
    handleShowProjectmodal,
  };
};

export default VendorTableDataUtils;
