import React from 'react'
import "./pricehistorymodal.scss"
import Modal from "react-bootstrap/Modal";

import { IconArrowUp, IconClose, IconDownload, IconPrint } from '../../../../icon/icons';
import Table from "react-bootstrap/Table";
import Price from '../../../layout/price';


const data = [
    {
      date:"03 Nov, 2024",
      u_name:"Mark Smith",
      id:"245879",
      u_price_icon:"",
      u_price:"120,000.00",
      Price_change:"100,000.00",
      price_syb: <IconArrowUp />,
    },
   
  ];



const PriceHistoryModal = (props) => {
    const { showItemsmodal, handleCloseItemsmodal } = props;
  return (
    <>
    
    <Modal
        className="modal-all-data  project-list-modal-new price-modal-new"
        show={showItemsmodal}
        onHide={handleCloseItemsmodal}
        centered
      >
        <Modal.Body>
          
        <div className="closemodal_btn">
            <button onClick={handleCloseItemsmodal}>
              <IconClose />
            </button>
          </div>

          <div className="heading-main">
              <h2 className="common-heading">Price history</h2>
            </div>

          <div className="table-data-main table-old-data-main">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Updated on</th>
                      <th>Updated by</th>
                      <th>Updated Price </th>
                      <th>Price change</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="user-info">
                            <p>{item.date}</p>
                          </div>
                        </td>


                        <td>
                      <div className="user-info">
                        <p>{item.u_name}</p>
                        <span>#{item.id}</span>
                      </div>
                    </td>

                    <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.u_price}
                        price_symbol={item.u_price_icon}
                      />
                    </div>
                  </div>
                  <div className="user-info"></div>
                </td>

                <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.Price_change}
                        price_symbol={item.price_syb}
                      />
                    </div>
                  </div>
                  <div className="user-info"></div>
                </td>
                      
                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PriceHistoryModal