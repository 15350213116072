import React from "react";
import "./detailsmodal.scss";
import Modal from "react-bootstrap/Modal";


import {
  IconCalendarLight,
  IconClose,
  IconFile,
  IconFileAdd,
} from "../../../../../icon/icons";
import DetailsModalUtils from "./detailsmodalUtils";
import FlatpickrAll from "../../../../layout/flatpickrall";

const DetailsModal = (props) => {
  const { showAddclientmodal, handleCloseAddclientmodal } = props;
  const {
    emiratesIdFile,
    setEmiratesIdFile,
    passportFile,
    setPassportFile,
    visaFile,
    setVisaFile,
    handleFileChange,
  } = DetailsModalUtils();
  return (
    <>
      <Modal
        className="modal-all-data modal-big-data"
        show={showAddclientmodal}
        onHide={handleCloseAddclientmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseAddclientmodal}>
              <IconClose />
            </button>
          </div>

          <div className="modal-inner-data">
            <div className="heading-main">
              <h2 className="common-heading">Add more details - Individual</h2>
            </div>

            <div className="add-client-form-data">
              <div className="form-all-grid">
                <div className="input-main-data">
                  <label>Emirates ID Number</label>
                  <input placeholder="874211442475" />
                </div>

                <div className="input-main-data">
                  <label>Emirates ID Expiry Date</label>
                  <FlatpickrAll/>
                </div>

                <div className="input-main-data">
                  <label>Emirates ID Attachment</label>
                  <div
                    className="wrap-image-false"
                    onClick={() =>
                      document.querySelector(".input-field-emirates").click()
                    }
                  >
                    {!emiratesIdFile ? (
                      <div className="icon-bs">
                        <IconFileAdd
                          size={100}
                          className="icon-upload"
                        />
                      </div>
                    ) : (
                      <div className="icon-bs">
                        <IconFile />
                      </div>
                    )}
                    <p className="file-name">
                      {emiratesIdFile
                        ? emiratesIdFile.name
                        : "Add Attachment"}
                    </p>
                    <input
                      type="file"
                      hidden
                      className="input-field-emirates"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                    />
                  </div>


                 
                </div>
              </div>

              <div className="form-all-grid">
                <div className="input-main-data">
                  <label>Passport Number</label>
                  <input placeholder="874211442475" />
                </div>

                <div className="input-main-data">
                  <label>Passport Expiry Date</label>
                   <FlatpickrAll/>
                </div>

                <div className="input-main-data">
                  <label>Passport Attachment</label>
                  <div
                    className="wrap-image-false"
                    onClick={() =>
                      document.querySelector(".input-field-passport").click()
                    }
                  >
                    {!passportFile ? (
                      <div className="icon-bs">
                        <IconFileAdd
                          size={100}
                          className="icon-upload"
                        />
                      </div>
                    ) : (
                      <div className="icon-bs">
                        <IconFile />
                      </div>
                    )}
                    <p className="file-name">
                      {passportFile
                        ? passportFile.name
                        : "Add Attachment"}
                    </p>
                    <input
                      type="file"
                      hidden
                      className="input-field-passport"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setPassportFile)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-all-grid">
                <div className="input-main-data">
                  <label>Visa Number</label>
                  <input placeholder="874211442475" />
                </div>

                <div className="input-main-data">
                  <label>Visa Expiry Date</label>
                  <FlatpickrAll/>
                </div>
                <div className="input-main-data">
                  <label>Visa Attachment</label>
                  <div
                    className="wrap-image-false"
                    onClick={() =>
                      document.querySelector(".input-field-visa").click()
                    }
                  >
                    {!visaFile ? (
                      <div className="icon-bs">
                        <IconFileAdd
                          size={100}
                          className="icon-upload"
                        />
                      </div>
                    ) : (
                      <div className="icon-bs">
                        <IconFile />
                      </div>
                    )}
                    <p className="file-name">
                      {visaFile ? visaFile.name : "Add Attachment"}
                    </p>
                    <input
                      type="file"
                      hidden
                      className="input-field-visa"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setVisaFile)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="btn-form-send">
              <button className="common-button">Submit</button>

              <button
                className="common-button cancel-btn"
                onClick={handleCloseAddclientmodal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailsModal;
