import React from "react";
import "./descopelist.scss";
import {
  CommonFilters,
  DescopeTableData,
  TitleHeader,
} from "../../../components";
const DescopeList = () => {
  const breadcrumbs = [
    { label: "Purchase", link: "" },
    { label: "Purchase Return", link: "" },
  ];

  return (
    <>
      <TitleHeader heading="Descope List" breadcrumbs={breadcrumbs} />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Enquiry List" />
        <DescopeTableData />
      </div>
    </>
  );
};

export default DescopeList;
