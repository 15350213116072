import React from "react";
import "./addquotationform.scss";
import { IconDocs } from "../../../../../icon/icons";
const AddQuotationForm = () => {
  return (
    <>
      <div className="form-data-main">
        <div className="page-card-bg">
          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Project Number</label>
              <input placeholder="#2546221" />
            </div>

            <div className="input-main-data">
              <label>Project Name</label>
              <input placeholder="ERP Dashboard design" />
            </div>

            <div className="input-main-data">
              <label>Project Type</label>
              <input placeholder="Enquiry" />
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Quote number</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              <label>Client name & Client Code</label>
              <input placeholder="Ahsan Hassan -25482" />
            </div>

            <div className="input-main-data">
              <label>Category</label>
              <input placeholder="Civil" />
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Applicable Authorities</label>
              <input placeholder="Authority 1" />
            </div>

            <div className="input-main-data"></div>

            <div className="input-main-data"></div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Discount</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              <label>Sub Total Amount (Excl. VAT)</label>
              <input placeholder="Ahsan Hassan -25482" />
            </div>

            <div className="input-main-data">
            <div className="input-check">
                {/* <input type="checkbox" />
                <label>VAT Applicable</label> */}
                 <div class="remember_me_input">
                  <input type="checkbox" id="remember" />
                  <label for="remember" class="checkbox-label"></label>
                  <span>VAT Applicable</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>VAT Amount (5%)</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              <label>Total Amount (Incl. VAT)</label>
              <input placeholder="Ahsan Hassan -25482" />
            </div>

            <div className="input-main-data">
              {/* <label>Category</label>
            <input placeholder="Civil" /> */}
            </div>
          </div>

          <div className="title-button-data dis-gird-btn-new">
            <p>Add Attachment</p>
            <div className="btn-inner-flex">
              <button className="common-button">
                <span>
                  <IconDocs />
                </span>{" "}
                upload (Xlsx)
              </button>

              <button className="common-button">
                <span>
                  <IconDocs />
                </span>{" "}
                Quote (PDF)
              </button>
            </div>
          </div>
        </div>

        <div className="btn-form-send">
          <button className="common-button">Submit</button>

          <button className="common-button cancel-btn">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default AddQuotationForm;
