import React from "react";
import "./addenquiryestimation.scss";
import { AddEstimateForm, TitleHeader } from "../../../../components";
const AddenquiryEstimation = () => {
  const breadcrumbs = [
    { label: "Inquiry", link: "" },
    { label: "Add Inquiry", link: "" },
  ];
  return (
    <>
      <TitleHeader heading="Add New Estimate" breadcrumbs={breadcrumbs} />

      <AddEstimateForm />
    </>
  );
};

export default AddenquiryEstimation;
