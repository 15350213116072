import React, { useState } from 'react'
import "./addporegister.scss"
import { CommonFilters, PoRegisterFrom, PoRegisterlistTable, PoRegisterSelectModal, TitleHeader } from '../../../../components'

const AddPoRegister = () => {

  const breadcrumbs = [
    { label: "Vehicles", link: "" },
    { label: "Add New Vehicle", link: "" }
  ];
  const [showPoListmodal, setShowPoListModal] = useState(false);
const handleClosePoListmodal = () => setShowPoListModal(false);
const handleShowPoListmodal = () => setShowPoListModal(true);
const handleShowPoListModal = () => {
  handleShowPoListmodal(true);
};





  return (
 <>
 
        <TitleHeader
          heading="Add New  PO"
          breadcrumbs={breadcrumbs}
          buttonText="+ View BOQ"
          onButtonClick={handleShowPoListModal}
        />

        <PoRegisterFrom/>

 </>
  )
}

export default AddPoRegister