import React from 'react'
import "./calender.scss"
const Calender = () => {
  return (
    <div className='calender-main-box'>
       <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> Calender</h2>
          </div>
          {/* <div className="date-all-btn"></div> */}
        </div>
      </div>

    </div>
  )
}

export default Calender