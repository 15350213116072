import React, { useState } from "react";
import "./enquiryselectlistmodal.scss";
import Price from "../../../../layout/price";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import CommonFilters from "../../../../layout/commonfilters";
import Table from "react-bootstrap/Table";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import { useNavigate } from "react-router-dom";
import CommonPaginate from "../../../../layout/commonpaginate";

const data = [
  {
    date: "19 Nov, 2023",
    Enquiry_Number: "#245879",
    Description: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
    client_name: "Mark Smith",
    client_no: "#2458",
    Source_Enquiry: "Marketing",
    Agent_Employe_name: "Jenny Wilson",
    Agent_Employe_no: "Agent",
    Location: "4140 Parker Rd. Allentown",
  },
  {
    date: "19 Nov, 2023",
    Enquiry_Number: "#245879",
    Description: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
    client_name: "Mark Smith",
    client_no: "#2458",
    Source_Enquiry: "Marketing",
    Agent_Employe_name: "Jenny Wilson",
    Agent_Employe_no: "Agent",
    Location: "4140 Parker Rd. Allentown",
  },
  {
    date: "19 Nov, 2023",
    Enquiry_Number: "#245879",
    Description: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
    client_name: "Mark Smith",
    client_no: "#2458",
    Source_Enquiry: "Marketing",
    Agent_Employe_name: "Jenny Wilson",
    Agent_Employe_no: "Agent",
    Location: "4140 Parker Rd. Allentown",
  },
];

const EnquirySelectListModal = (props) => {
  const { showDocumentmodal, handleCloseDocumentmodal } = props;

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const navigate = useNavigate();
  const handlePageChange = () => {
    navigate("/estimation/add-quotation");
  };

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal-new"
        show={showDocumentmodal}
        onHide={handleCloseDocumentmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseDocumentmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="Enquiry List" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                 
                  <th>Date</th>
                  <th>Enquiry Number </th>
                  <th>Description</th>
                  <th>Client</th>
                  <th>Source of Enquiry</th>
                  <th>Agent / Employee</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>

                    <td>
                      <div className="btn-dis-new">
                        <button
                          className="common-button"
                          onClick={() => {
                            handlePageChange();
                          }}
                        >
                          Select
                        </button>
                        <button
                          className="btn-viwe-icon"
                          // onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>
                      </div>
                      <div className="user-info">
                        </div>
                    </td>
                   
                    

                    <td>
                      <div className="user-info">
                        <span>{item.date}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Enquiry_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="contact-info">
                        <span>{item.Description}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.client_name}</p>
                        <span>#{item.client_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Source_Enquiry}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Agent_Employe_name}</p>
                        <span>#{item.Agent_Employe_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Location}</span>
                      </div>
                    </td>

                   
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="paginate_all_common">
            <CommonPaginate
              itemsPerPage={itemsPerPage}
              paginate={paginate}
              length={data?.length}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnquirySelectListModal;
