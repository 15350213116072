import React, { useState } from "react";
import "./purchasequotationstable.scss";
import TableActionButton from "../../../../layout/tableactionbutton";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import Table from "react-bootstrap/Table";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CommonPaginate from "../../../../layout/commonpaginate";
import PurchaseQuotationsTableUtils from "./purchasequotationstable";
import Price from "../../../../layout/price";
import PurchaseQuoteitemsModal from "../purchasequoteitemsmodal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const data = [
  {
    date: "19 Nov, 2023",
    number: "245879",
    Project_Enquiry: "Project",
    project_name: "Project one",
    project_id: "#2458",
    Client_name: "Mark smith",
    Client_id: "#1458",
    Vendor: "Vendor",
    Type: "MEP",
    Department: "Material",
    Project_Title:"Name Here" ,
    Project_id:"#457841" 
   
  },

  {
    date: "19 Nov, 2023",
    number: "245879",
    Project_Enquiry: "Project",
    project_name: "Project one",
    project_id: "#2458",
    Client_name: "Mark smith",
    Client_id: "#1458",
    Vendor: "Vendor",
    Type: "MEP",
    Department: "Material",
    Project_Title:"Name Here" ,
    Project_id:"#457841" 
  
  },

  {
    date: "19 Nov, 2023",
    number: "245879",
    Project_Enquiry: "Project",
    project_name: "Project one",
    project_id: "#2458",
    Client_name: "Mark smith",
    Client_id: "#1458",
    Vendor: "Vendor",
    Type: "MEP",
    Department: "Material",
    Project_Title:"Name Here" ,
    Project_id:"#457841" 
   
  },

  {
    date: "19 Nov, 2023",
    number: "245879",
    Project_Enquiry: "Project",
    project_name: "Project one",
    project_id: "#2458",
    Client_name: "Mark smith",
    Client_id: "#1458",
    Vendor: "Vendor",
    Type: "MEP",
    Department: "Material",
    Project_Title:"Name Here" ,
    Project_id:"#457841" 
  
  },
];

const PurchaseQuotationsTable = () => {
  const options = [
   
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
  ];


  // Items Drawer
const [isOpen, setIsOpen] = useState(false);
const toggleDrawer = () => {
  setIsOpen((prevState) => !prevState);
};

// Pagination all status
const [page, setPage] = React.useState(2);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(5);
const indexOfLastPost = currentPage * itemsPerPage;
const indexOfFirstPost = indexOfLastPost - itemsPerPage;
const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
const paginate = ({ selected }) => {
  setCurrentPage(selected + 1);
};

const {
  showItemsmodal,
  setShowItemsModal,
  handleCloseItemsmodal,
  handleShowItemsmodal,
} = PurchaseQuotationsTableUtils();

  return (
    <>
      <div className="table-data-main Estimation-table-new">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Quote Number</th>
              <th>Project/Enquiry</th>

              <th> Name</th>
              <th> Project Title  </th>
              <th>Client</th>
              <th>Subcontractor</th>

              <th>Type</th>

              <th>Department</th>

           
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="user-info">
                    <span>{item.date}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <span>#{item.number}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span>{item.Project_Enquiry}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.project_name}</p>
                    <span>{item.project_id}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.Project_Title}</p>
                    <span>{item.Project_id}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.Client_name}</p>
                    <span>{item.Client_id}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span>{item.Vendor}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span>{item.Type}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span>{item.Department}</span>
                  </div>
                </td>

               

                <td>
                  <div className="table-inner-grid-dta">
                    <div className="btn-table-new">
                     
                      <div className="btn-dis-new">
                        <button
                          className="btn-viwe-icon"
                          onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>

                        <TableActionButton options={options} isOpen={true} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Drawer 
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="profile_slider_bar side_barcomman_main"
    >
      <div className="side_bar_inner_data">
        <div className="closemodal_btn">
          <button onClick={toggleDrawer}>
            <IconClose />
          </button>
        </div>
        <div className="div_data_main">
            <div className="heading-main">
              <h2 className="common-heading">More details</h2>
            </div>
            <div className="data-list-flex">
              <div className="list-data-one">
                <p>Date</p>
                <span>03 Aug, 2024</span>
              </div>
              <div className="list-data-one">
                <p>Quote Number</p>
                <span>#457841</span>
              </div>
              <div className="list-data-one">
                <p>Project Name</p>
                <span>ABC</span>
              </div>
              <div className="list-data-one">
                <p>Project Number</p>
                <span>#1458</span>
              </div>
             
              <div className="list-data-one">
                <p>Client</p>
                <span>Mark smith</span>
              </div>
              
              <div className="list-data-one">
                <p>Subcontractor/Vendor</p>
                <span>Vendor</span>
              </div>
              <div className="list-data-one">
                <p>Type</p>
                <span>Material</span>
              </div>
              
              <div className="list-data-one">
                <p>Department</p>
                <span>Civil</span>
              </div>

              <div className="list-data-one">
                <p> Sub Total Amount (Excl. VAT) </p>
                <div className="price-new-Drawer">
                  <Price price_main="10,000.00" price_symbol="" />
                </div>
              </div>
              <div className="list-data-one">
                <p> VAT Amount (5%) </p>
                <div className="price-new-Drawer">
                  <Price price_main="5,000.00" price_symbol="" />
                </div>
              </div>
              <div className="list-data-one">
                <p> Total Amount (Incl. VAT) </p>
                <div className="price-new-Drawer">
                  <Price price_main="105,000.00" price_symbol="" />
                </div>
              </div>
            </div>
          </div>
      </div>

      <div className="bottom-btn-attachmenet">
        <button
          class="common-button"
          onClick={() => handleShowItemsmodal()}
        >
        View Items
        </button>

        <button
          class="common-button"
          
        >
        Comments
        </button>
      </div>
    </Drawer>

      <div className="paginate_all_common">
      <CommonPaginate
        itemsPerPage={itemsPerPage}
        paginate={paginate}
        length={data?.length}
      />
    </div>
  
    <PurchaseQuoteitemsModal
     showItemsmodal={showItemsmodal}
     handleCloseItemsmodal={handleCloseItemsmodal}
    />
    </>
  );
};

export default PurchaseQuotationsTable;
