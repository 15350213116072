import React, { useState } from "react";
import "./purchaselisttable.scss";
import CommonPaginate from "../../../../layout/commonpaginate";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import TableActionButton from "../../../../layout/tableactionbutton";
import Table from "react-bootstrap/Table";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import PurchaseListTableUtils from "./purchaselisttableUtils";
import ItemsModal from "../poitemsmodal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const data = [
  {
    date: "19 Nov, 2023",
    number: "245879",
    project_name: "Project one",
    project_id: "#2458",
    Type: "MEP",
    Requested_by: "David",
    Department: "Material",
    Approval: "Approved",
    Priority: "Urgent",
  },
  {
    date: "19 Nov, 2023",
    number: "245879",
    project_name: "Project one",
    project_id: "#2458",
    Type: "MEP",
    Requested_by: "David",
    Department: "Material",
    Approval: "Pending",
    Priority: "Urgent",
  },
  {
    date: "19 Nov, 2023",
    number: "245879",
    project_name: "Project one",
    project_id: "#2458",
    Type: "MEP",
    Requested_by: "David",
    Department: "Material",
    Approval: "Delivery Pending",
    Priority: "Urgent",
  },
  {
    date: "19 Nov, 2023",
    number: "245879",
    project_name: "Project one",
    project_id: "#2458",
    Type: "MEP",
    Requested_by: "David",
    Department: "Material",
    Approval: "Completed",
    Priority: "Urgent",
  },
];

const PurchaseListTable = () => {
  const {
    showItemsmodal,
    setShowItemsModal,
    handleCloseItemsmodal,
    handleShowItemsmodal,
  } = PurchaseListTableUtils();

  // Items Drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const options = [
    { 
      title: "Cancel PR",
      icon: "",
      action: () => {
        // console.log("Vishal");
      },
    },
    
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
  ];

  return (
    <>
      <div className="table-data-main Estimation-table-new">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>PR No.</th>

              <th>Project Name</th>
              <th>Department</th>
              <th>Type</th>
              <th>Requested by</th>

              <th>Status</th>

              <th>Priority</th>
              {/* <th>View</th> */}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="user-info">
                    <span>{item.date}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <span>#{item.number}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.project_name}</p>
                    <span>{item.project_id}</span>
                  </div>
                </td>

                <td>
                  <div className="user-name">
                    <p>{item.Department}</p>
                  </div>
                </td>

                <td>
                  <div className="user-name">
                    <p>{item.Type}</p>
                  </div>
                </td>

                <td>
                  <div className="user-name">
                    <p>{item.Requested_by}</p>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span
                      className={`${
                        item.Approval === "Approved"
                          ? "approved"
                          : item.Approval === "Pending"
                          ? "pending"
                          : item.Approval === "Delivery Pending"
                          ? "delivery-pending"
                          : item.Approval === "Completed"
                          ? "completed"
                          : "rejected"
                      }`}
                    >
                      {item.Approval}
                    </span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <span
                      className={`${
                        item.Priority === "Urgent" ? "urgent" : ""
                      }`}
                    >
                      {item.Priority}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="table-inner-grid-dta">
                    <div className="btn-table-new">
                      {/* <div className="btn-dis-new btn-create-quote">
                        <button className="common-button">Create Quote</button>
                      </div> */}
                      <div className="btn-dis-new">
                        <button
                          className="btn-viwe-icon"
                          onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>

                        <TableActionButton options={options} isOpen={true} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="profile_slider_bar side_barcomman_main"
      >
        <div className="side_bar_inner_data">
          <div className="closemodal_btn">
            <button onClick={toggleDrawer}>
              <IconClose />
            </button>
          </div>
          <div className="div_data_main">
            <div className="heading-main">
              <h2 className="common-heading">More details</h2>
            </div>
            <div className="data-list-flex">
              <div className="list-data-one">
                <p>Date</p>
                <span>03 Aug, 2024</span>
              </div>
              <div className="list-data-one">
                <p>PR No.</p>
                <span>#457841</span>
              </div>
              <div className="list-data-one">
                <p>Project Name</p>
                <span>ABC</span>
              </div>
              <div className="list-data-one">
                <p>Project Number</p>
                <span>#1458</span>
              </div>
              <div className="list-data-one">
                <p>Category</p>
                <span>Material</span>
              </div>
              <div className="list-data-one">
                <p>Status</p>
                <span>Pending</span>
              </div>
              <div className="list-data-one">
                <p>Priority</p>
                <span>Urgent</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-btn-attachmenet">
          <button class="common-button" onClick={() => handleShowItemsmodal()}>
            View Items
          </button>

          <button class="common-button">Comments</button>
        </div>
      </Drawer>

      <div className="paginate_all_common">
        <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
      </div>

      <ItemsModal
        showItemsmodal={showItemsmodal}
        handleCloseItemsmodal={handleCloseItemsmodal}
      />
    </>
  );
};

export default PurchaseListTable;
