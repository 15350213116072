import React from "react";
import {
  CommonFilters,
  EnqtableList,
  TitleHeader,
} from "../../../../components";
import { useNavigate } from "react-router-dom";

const EnquiryMarketingList = () => {
  const breadcrumbs = [{ label: "Enquiry", link: "" }];

  const navigate = useNavigate();
  const handleAddClientClick = () => {
    navigate("/estimation/add-enquiry");
  };
  return (
    <>
      <TitleHeader
        heading="Enquiry"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add New"
        onButtonClick={handleAddClientClick}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Enquiry List" />
        <EnqtableList />
      </div>
    </>
  );
};

export default EnquiryMarketingList;
