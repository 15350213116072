import React from "react";
import "./commonpaginate.scss";
import ReactPaginate from "react-paginate";
const CommonPaginate = ({ itemsPerPage, paginate, length }) => {
  return (
    <div className="paginate_all_common">
      <ReactPaginate
        pageCount={Math.ceil(length / itemsPerPage)}
        onPageChange={paginate}
        containerClassName={"navigationButtons"}
        previousLinkClassName={"previousButton"}
        nextLinkClassName={"nextButton"}
        disabledClassName={"navigationDisabled"}
        activeClassName={"active"}
        pageRange={4}
        marginPagesDisplayed={3}
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.172"
            height="14"
            viewBox="0 0 7.172 14"
          >
            <path
              id="fi-rr-angle-small-down"
              d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
              transform="translate(7.172) rotate(90)"
              fill="#454AA6"
            />
          </svg>
        }
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.172"
            height="14"
            viewBox="0 0 7.172 14"
          >
            <path
              id="fi-rr-angle-small-down"
              d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
              transform="translate(15.086 -5) rotate(90)"
              fill="#454AA6"
            />
          </svg>
        }
      />
    </div>
  );
};

export default CommonPaginate;
