import React from "react";
import "./subcondashboard.scss";
import {
  DashAddedSubcon,
  DashAddedVendors,
  DashExpiringDocuments,
  DashSubconVendors,
  DashTotalVendors,
  TitleHeader,
} from "../../../components";

const SubconDashboard = () => {
  const breadcrumbs = [{ label: "Dashboard", link: "" }];
  return (
    <>
      <TitleHeader heading="Dashboard" breadcrumbs={breadcrumbs} />

      <div className="dashboard-data-top">
        <div className="flex-tiles-grid">
          <div className="dashboard-total-value gird-width-box ">
            <DashTotalVendors />
          </div>
          <div className="dashboard-all-chart gird-width-box">
            <DashSubconVendors />
          </div>
          <div className="dashboard-all-table gird-width-box">
            <DashAddedVendors />
          </div>
          <div className="dashboard-all-table gird-width-box">
            <DashAddedSubcon />
          </div>
          <div className="dashboard-all-table gird-width-box">
            <DashExpiringDocuments />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubconDashboard;
