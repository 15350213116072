import React from "react";
import "./addquotation.scss";
import { AddQuotationForm, TitleHeader } from "../../../../components";
const AddQuotation = () => {
  const breadcrumbs = [
    { label: "Quotations", link: "" },
    { label: "Add Quotation", link: "" },
  ];

  return (
    <>
      <TitleHeader heading="Add New Quotation" breadcrumbs={breadcrumbs} />

      <AddQuotationForm />
    </>
  );
};

export default AddQuotation;
