import React, { useState } from 'react'
import "./poregisterlist.scss"
import { CommonFilters, PoRegisterlistTable, PoRegisterSelectModal, TitleHeader } from '../../../../components'
import { useNavigate } from 'react-router-dom';
const PoRegisterList = () => {
    const breadcrumbs = [{ label: "PO Register", link: "" }];
    const [showPoListmodal, setShowPoListModal] = useState(false);
  const handleClosePoListmodal = () => setShowPoListModal(false);
  const handleShowPoListmodal = () => setShowPoListModal(true);
  const handleShowPoListModal = () => {
    handleShowPoListmodal(true);
  };

  return (
     <>
     <TitleHeader
        heading="PO Register"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add New"
        onButtonClick={handleShowPoListModal}
      />

     <div className="page-card-bg table-data-new">
        <CommonFilters heading="PO Register" />
        <PoRegisterlistTable/>
      </div>
     
      <PoRegisterSelectModal
        showPoListmodal={showPoListmodal}
        handleClosePoListmodal={handleClosePoListmodal}
      />

     </>
  )
} 
 
export default PoRegisterList