import React, { useState } from "react";
import "./totalclients.scss";
import { IconCalendarLight } from "../../../../icon/icons";


import TotalClientsUtils from "./totalClientsUtils.";
import Chart from "react-apexcharts";
import FlatpickrAll from "../../../layout/flatpickrall";

const TotalClients = () => {
  const { themeMode } = TotalClientsUtils();

  // const [chartData, setChartData] = useState({
  //   series: [
  //     {
  //       name: "Number of Clients",
  //       data: [10, 20, 15, 25, 20, 18, 25],
  //     },
  //     {
  //       name: "Hidden Series", // Second series with a hidden legend
  //       data: [0],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "line",
  //       height: 350,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     stroke: {
  //       curve: "smooth",
  //       width: 2,
  //     },
  //     title: {
  //       text: "Total Clients",
  //       align: "left",
  //     },
  //     colors: ["#f4c20d", "#ffffff00"], // Make the second series transparent if needed
  //     xaxis: {
  //       categories: [
  //         "15 Jun",
  //         "20 Jun",
  //         "25 Jun",
  //         "30 Jun",
  //         "1 Jul",
  //         "5 Jul",
  //         "10 Jul",
  //         "15 Jul",
  //       ],
  //       labels: {
  //         style: {
  //           colors: "var(--btn-text)",
  //         },
  //       },
  //     },
  //     yaxis: {
  //       min: 5,
  //       max: 30,
  //       tickAmount: 5,
  //       labels: {
  //         style: {
  //           colors: "var(--btn-text)",
  //         },
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       horizontalAlign: 'right',
  //       floating: true,
  //       offsetY: -25,
  //       offsetX: -5,
  //       labels: {
  //         colors: "var(--btn-text)",
  //       },
  //       formatter: function(seriesName) {
  //         // Only show legend for "Number of Clients"
  //         return seriesName === "Number of Clients" ? seriesName : "";
  //       },
  //     },
  //   },
  // });

 
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Number of Clients",
        data: [10, 20, 15, 25, 20, 18, 25],
      },
      {
        name: "", // Hidden series
        data: [0],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      title: {
        text: "Total Clients",
        align: "left",
      },
      colors: ["#f4c20d", "var(--card_box_bg)"], // Use dynamic background color for the second series
      xaxis: {
        categories: [
          "15 Jun",
          "20 Jun",
          "25 Jun",
          "30 Jun",
          "1 Jul",
          "5 Jul",
          "10 Jul",
          "15 Jul",
        ],
        labels: {
          style: {
            colors: "var(--btn-text)",
          },
        },
      },
      yaxis: {
        min: 5,
        max: 30,
        tickAmount: 5,
        labels: {
          style: {
            colors: "var(--btn-text)",
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        labels: {
          colors: "var(--btn-text)",
        },
        markers: {
          shape: 'line',  // Change shape to 'line'
          width: 80, // Line width
          height: 8, // Line height (optional)
        },
         
      },
    },
  });

  return (
    <div
      className={`page-card-bg ${
        themeMode === "dark" ? "dark-mode" : "light-mode"
      }`}
    >
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> Total Clients</h2>
        </div>
        <div className="date-all-btn">
        <FlatpickrAll/>
        </div>
      </div>

      <div className="chart-new-data single-line-chart chart-title-hide">
        <Chart
          key={JSON.stringify(chartData)}
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      </div>
    </div>
  );
};

export default TotalClients;
