import React, { useState } from "react";
import "./subconrprojectlist.scss";
import Modal from "react-bootstrap/Modal";
import { IconClose } from "../../../../../icon/icons";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CommonFilters from "../../../../layout/commonfilters";
import Price from "../../../../layout/price";
import CommonPaginate from "../../../../layout/commonpaginate";
const SubconrProjectList = (props) => {
  const data = [
    {
      id: 245879,
      name: "Mark Smith",
      p_name: "Project one",
      p_id: 245879,
      department: "Manpower",
      type: "Material",
      price_syb: "AED",
      price_value: "1000.00",
      value: "AED 1000.00",
    },
    {
      id: 245879,
      name: "Mark Smith",
      p_name: "Project one",
      p_id: 245879,
      department: "Manpower",
      type: "Material",
      value: "AED 1000.00",
      price_syb: "AED",
      price_value: "1000.00",
    },
    {
      id: 245879,
      name: "Mark Smith",
      p_name: "Project one",
      p_id: 245879,
      department: "Manpower",
      type: "Material",
      value: "AED 1000.00",
      price_syb: "AED",
      price_value: "1000.00",
    },
  ];

  const { showProjectmodal, handleCloseProjectmodal } = props;

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal"
        show={showProjectmodal}
        onHide={handleCloseProjectmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseProjectmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="Projects lists" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client </th>
                  <th>Department</th>
                  <th>Type</th>
                  <th>Total PO's value</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <p>{item.p_name}</p>
                        <span>#{item.p_id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.name}</p>
                        <span>#{item.id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.department}</span>
                      </div>
                    </td>
                    <td>
                      <div className="user-info">
                        <span>{item.type}</span>
                      </div>
                    </td>
                    <td>
                      <div className="price-option-table">
                        <div className="price-all-main">
                          <Price
                            price_main={item.price_value}
                            price_symbol={item.price_syb}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="paginate_all_common">
            <CommonPaginate
              itemsPerPage={itemsPerPage}
              paginate={paginate}
              length={data?.length}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubconrProjectList;
