import React, { useState } from 'react'
import './vendorform.scss'
import { Autocomplete, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import VendorDetailsModal from '../vendordetailsmodal';
const VendorForm = () => {

  const [showAddVendormodal, setShowAddVendorModal] = useState(false);
  const handleCloseAddVendormodal = () => setShowAddVendorModal(false);
  const handleShowAddVendormodal = () => setShowAddVendorModal(true);

    // Multiple Type
    const TypesListNew = [
      { title: "Type 1" }, 
      { title: "Type 2" },
      { title: "Type 3" },
      // Add more types here
    ];
    const [selectedNewTypes, setSelectedNewTypes] = React.useState([]);
  return (
    <>
     <div className="form-data-main">
    <div className="page-card-bg">
      <div className="form-all-grid">
        <div className="input-main-data">
          <label>Vendor code</label>
          <input placeholder="#247845" />
        </div>

        <div className="input-main-data">
          <label>Vendor Name </label>
          <input placeholder="Mark Smith" />
        </div>

        <div className="input-main-data">
          <label>Type</label>
          <Autocomplete
            multiple
            fullWidth
            className="automcomplete_select_info_multiple"
            id="multiple-select-demo"
            options={TypesListNew}
            getOptionLabel={(option) => option?.title}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Type" />
            )}
            value={selectedNewTypes}
            onChange={(e, value) => {
              setSelectedNewTypes(value);
              console.log("Selected Values:", value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option?.title} {...getTagProps({ index })} />
              ))
            }
          />
        </div>
      </div>

      <div className="form-all-grid">
        <div className="input-main-data">
          <label>Contact Number </label>
          <input placeholder="(1+) 12457875" />
        </div>

        <div className="input-main-data">
          <label>Email </label>
          <input placeholder="example@gmail.com" />
        </div>

        <div className="input-main-data">
          <label>Department</label>
          <Autocomplete
            multiple
            fullWidth
            className="automcomplete_select_info_multiple"
            id="multiple-select-demo"
            options={TypesListNew}
            getOptionLabel={(option) => option?.title}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Type" />
            )}
            value={selectedNewTypes}
            onChange={(e, value) => {
              setSelectedNewTypes(value);
              console.log("Selected Values:", value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option?.title} {...getTagProps({ index })} />
              ))
            }
          />
        </div>
      </div>

      <div className="form-all-grid">
        <div className="input-main-data">
          <label>Contact Name </label>
          <input placeholder="Abc" />
        </div>

        <div className="input-main-data">
          <label>Contact Number </label>
          <input placeholder="(1+) 12457875" />
        </div>

        <div className="input-main-data">
          <label>Contact Email </label>
          <input placeholder="example@gmail.com" />
        </div>
      </div>

      <div className="title-button-data">
        <button
          className="common-button"
          onClick={() => handleShowAddVendormodal()}
        >
          <span>+</span> Add More Details
        </button>
      </div>
    </div>

    <div className="btn-form-send">
      <button className="common-button">Submit</button>

      <button className="common-button cancel-btn">Cancel</button>
    </div>
     </div>

     <VendorDetailsModal
      showAddVendormodal={showAddVendormodal}
      handleCloseAddVendormodal={handleCloseAddVendormodal}
     />
    </>
   
  )
}

export default VendorForm
