import React from 'react'
import "./purchasedashboard.scss"
import { PoAndRfqChart, PurchaseQuotationsReceived, PurchaseRequestChart, RfqChart, TitleHeader, TopSubContractors, TopVendors, TotalPurchaseChart, TotalRfqChart } from '../../../components'

const PurchaseDashboard = () => {

    const breadcrumbs = [{ label: "Dashboard", link: "" }];
  return (
    <>
      <TitleHeader heading="Dashboard - Purchase" breadcrumbs={breadcrumbs} />
      <div className="dashboard-data-top">
        <div className="flex-tiles-grid">
          <div className="dashboard-total-value gird-width-box ">
            <TotalPurchaseChart/>
          </div>
          <div className="dashboard-all-chart gird-width-box">
            <PurchaseRequestChart/>
          </div>
          <div className="dashboard-total-value gird-width-box ">
            <TotalRfqChart/>
          </div>
          <div className="dashboard-all-chart gird-width-box">
            <RfqChart/>
          </div>
          <div className="dashboard-all-table gird-width-box">
            <PurchaseQuotationsReceived/>
          </div>
          <div className="dashboard-all-table gird-width-box">
            <TopVendors/>
          </div>
          <div className="dashboard-all-table gird-width-box">
            <TopSubContractors/>
          </div>
          <div className="dashboard-all-chart gird-width-box">
            <PoAndRfqChart/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseDashboard

