import React from "react";
import "./addenquirymarketing.scss";
import { EnquiryMarketingForm, TitleHeader } from "../../../../components";

const AddEnquiryMarketing = () => {
  const breadcrumbs = [
    { label: "Inquiry", link: "" },
    { label: "Add Inquiry", link: "" },
  ];

  return (
    <>
      <TitleHeader heading="Add New enquiry" breadcrumbs={breadcrumbs} />
      <EnquiryMarketingForm />
    </>
  );
};

export default AddEnquiryMarketing;
