import React, { useState } from "react";
import {
  CommonFilters,
  EnquirySelectListModal,
  QuotationListTable,
  TitleHeader,
} from "../../../../components";

const QuotationList = () => {
  const breadcrumbs = [{ label: "Quotation List", link: "" }];

  const [showDocumentmodal, setShowDocumentModal] = useState(false);
  const handleCloseDocumentmodal = () => setShowDocumentModal(false);
  const handleShowDocumentmodal = () => setShowDocumentModal(true);
  const handleShowDocumentModal = () => {
    handleShowDocumentmodal(true);
  };

  return (
    <>
      <TitleHeader
        heading="Quotation List  "
        breadcrumbs={breadcrumbs}
        buttonText="+ Add New"
        onButtonClick={handleShowDocumentModal}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Quotation List" />
        <QuotationListTable />
      </div>

      <EnquirySelectListModal
        showDocumentmodal={showDocumentmodal}
        handleCloseDocumentmodal={handleCloseDocumentmodal}
      />
    </>
  );
};

export default QuotationList;
