import React from "react";
import PriceUtils from "./priceUtils";
import { images } from "../../../utils/customFn";
import { IconDots } from "../../../icon/icons";
import "./price.scss"
const Price = (props) => {
  const { price } = PriceUtils();
  const { price_main, price_symbol } = props;
  return (
    <div className="price-main">
      <h2>
        {price ? (
          <>
            <span className="symbol-svg">{price_symbol}</span>
            {price_main}
          </>
        ) : (
          <IconDots />
        )}
      </h2>
    </div>
  );
};

export default Price;
