import React from "react";
import "./estimatelocationmodal.scss";
import Modal from "react-bootstrap/Modal";
import { IconClose } from "../../../../../icon/icons";
import { Autocomplete, TextField } from "@mui/material";

const EstimateLocationModal = (props) => {
  const { showLocationmodal, handleCloseLocationmodal } = props;

  const TypesList = [
    { _id: "1", title: "Demo-1" },
    { _id: "2", title: "Demo-2" },
    { _id: "3", title: "Demo-3" },
    { _id: "4", title: "Demo-4" },
    // Add more property types as needed
  ];
  const selectedType = { _id: "2", title: "Demo-2" };
  const [selectedNewTypes, setSelectedNewTypes] = React.useState([]);
  return (
    <>
      <Modal
        className="modal-all-data modal-big-data location-modal-data-new"
        show={showLocationmodal}
        onHide={handleCloseLocationmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseLocationmodal}>
              <IconClose />
            </button>
          </div>
          <div className="modal-data-grid">
            <div className="map-data-fream">
              {/* <Map
            googleMapURL={googleMapURL} // Pass the Google Maps API URL
            loadingElement={<div style={{ height: `100%` }} />} // Loader while map is loading
            containerElement={<div style={{ height: `100%` }} />} // Container for the map
            mapElement={<div style={{ height: `100%` }} />} // Map element
            center={{ lat: 22.7527421, lng: 75.88371599999999 }} // Coordinates for the map center
          /> */}

              <iframe
                height="100%"
                src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
            <div className="location-addd-from">
              <div className="heading-main">
                <h2 className="common-heading">Location details</h2>
              </div>

              <div className="form-data-main">
                <div className="input-main-data">
                  <label>Select Type</label>
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="input-main-data">
                  <label>Unit No. </label>
                  <input placeholder="12345" />
                </div>

                <div className="input-main-data">
                  <label>Building Name </label>
                  <input placeholder="12345" />
                </div>

                <div className="input-main-data">
                  <label>Area</label>
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="input-main-data">
                  <label>State</label>
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="input-main-data">
                  <label>Country</label>
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="btn-form-send">
                  <button className="common-button">Save Location</button>

                  <button
                    className="common-button cancel-btn"
                    onClick={handleCloseLocationmodal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EstimateLocationModal;
