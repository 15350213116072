import React, { useState } from "react";
import "./polistselectlistmodal.scss";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import CommonFilters from "../../../../layout/commonfilters";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Price from "../../../../layout/price";
import CommonPaginate from "../../../../layout/commonpaginate";

const data = [
  {
    date: "19 Nov, 2023",
    Quote_Number: "#245879",
    Project_Type: "Material",
    Enquiry_Number: "#245879",
    Department: "Civil",

    pro_name: "Project one",
    pro_no: "#1458",

    category: "Material",

    client_name: "Mark Smith",
    client_no: "#2458",
    price_syb: "",
    price_value: "100,000.00",
    Approval: "Approved",
  },

  {
    date: "19 Nov, 2023",
    Quote_Number: "#245879",
    Project_Type: "Material",
    Enquiry_Number: "#245879",
    Department: "Civil",

    pro_name: "Project one",
    pro_no: "#1458",

    category: "Material",

    client_name: "Mark Smith",
    client_no: "#2458",
    price_syb: "",
    price_value: "100,000.00",
    Approval: "Approved",
  },

  {
    date: "19 Nov, 2023",
    Quote_Number: "#245879",
    Project_Type: "Material",
    Enquiry_Number: "#245879",
    Department: "Civil",

    pro_name: "Project one",
    pro_no: "#1458",

    category: "Material",

    client_name: "Mark Smith",
    client_no: "#2458",
    price_syb: "",
    price_value: "100,000.00",
    Approval: "Approved",
  },
];


const PolistSelectlistModal = (props) => {
  

  const { showPoListmodal, handleClosePoListmodal } = props;

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const navigate = useNavigate();
  const handlePageChange = () => {
    navigate("/estimation/addpo-list");
  };

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal-new"
        show={showPoListmodal}
        onHide={handleClosePoListmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleClosePoListmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="Quotation List" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Quote Number </th>
                  <th>Project Type </th>
                  <th>Enquiry Number </th>
                  <th>Department</th>
                  <th>Project Name</th>
                  <th>Category</th>

                  <th>Client</th>
                  <th>Value</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>


                    <td>
                      <div className="btn-dis-new">
                        <button
                          className="common-button"
                          onClick={() => {
                            handlePageChange();
                          }}
                        >
                          Select
                        </button>
                        <button
                          className="btn-viwe-icon"
                          // onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>
                      </div>
                    </td>


                    <td>
                      <div className="user-info">
                        <span>{item.date}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Quote_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Project_Type}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Enquiry_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Department}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.pro_name}</p>
                        <span>{item.pro_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.category}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.client_name}</p>
                        <span>{item.client_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="price-option-table">
                        <div className="price-all-main">
                          <Price
                            price_main={item.price_value}
                            price_symbol={item.price_syb}
                          />
                        </div>
                      </div>
                      <div className="user-info">
                        <p></p>
                        <span></span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span
                          className={`${
                            item.Approval === "Approved"
                              ? "approved"
                              : item.Approval === "Pending"
                              ? "pending"
                              : "rejected"
                          }`}
                        >
                          {item.Approval}
                        </span>
                      </div>
                    </td>

                  
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="paginate_all_common">
          <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PolistSelectlistModal;
