import React, { useState } from "react";
import "./pricelist.scss";
import TableActionButton from "../../../layout/tableactionbutton";
import CommonPaginate from "../../../layout/commonpaginate";
import { IconArrowUp, IconClose, IconEyes } from "../../../../icon/icons";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import Price from "../../../layout/price";
import { images } from "../../../../utils/customFn";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PriceHistoryModal from "../pricehistorymodal";
import PriceListUtils from "./pricelistUtils";

const data = [
  {
    item_code: "245879",
    manufacturer: "ACE",
    Department: "Civil",

    unit: "KG",
    price_syb_unit: "",
    price_unit: "100,000.00",

    price_syb: <IconArrowUp />,
    price_value: "50.00",
    Photo: images["image_table.png"],
  },
  {
    item_code: "245879",
    manufacturer: "ACE",
    Department: "Civil",

    unit: "KG",
    price_syb_unit: "",
    price_unit: "100,000.00",

    price_syb: <IconArrowUp />,
    price_value: "50.00",
    Photo: images["image_table.png"],
  },
  {
    item_code: "245879",
    manufacturer: "ACE",
    Department: "Civil",

    unit: "KG",
    price_syb_unit: "",
    price_unit: "100,000.00",

    price_syb: <IconArrowUp />,
    price_value: "50.00",
    Photo: images["image_table.png"],
  },
];
const PriceTable = () => {

  const {
    showItemsmodal,
    setShowItemsModal,
    handleCloseItemsmodal,
    handleShowItemsmodal,
  } = PriceListUtils();


  // Profile Drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const options = [
   
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
  ];

  return (
    <>
      <div className="table-data-main Estimation-table-new">
        <Table responsive>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Manufacturer</th>
              <th>Department</th>
              <th>Unit </th>
              <th>Price per unit</th>
              <th>Last change</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="user-type">
                    <span>#{item.item_code}</span>
                  </div>
                </td>

                <td>
                  <div className="user-type">
                    <span>{item.manufacturer}</span>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.Department}</p>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.unit}</p>
                  </div>
                </td>

                <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.price_unit}
                        price_symbol={item.price_syb_unit}
                      />
                    </div>
                  </div>
                  <div className="user-info"></div>
                </td>

                <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.price_value}
                        price_symbol={item.price_syb}
                      />
                    </div>
                  </div>
                  <div className="user-info"></div>
                </td>

                <td>
                  <div className="user-info-img">
                    <img src={item.Photo} />
                  </div>
                  <div className="user-info"></div>
                </td>

                <td>
                  <div className="table-inner-grid-dta">           
                    <div className="btn-table-new">           
                      <div className="btn-dis-new">
                        <button
                          className="btn-viwe-icon"
                          onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>
                        <button className="btn-dots-new">
                          <TableActionButton options={options} isOpen={true} />
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="profile_slider_bar side_barcomman_main"
      >
        <div className="side_bar_inner_data">
          <div className="closemodal_btn">
            <button onClick={toggleDrawer}>
              <IconClose />
            </button>
          </div>

          <div className="div_data_main">
            <div className="heading-main">
              <h2 className="common-heading">More details</h2>
            </div>

            <div className="mid-banner-image">
              <img src={images["price_image.png"]} alt="price_image"/>
          
            </div>

            <div className="data-list-flex">
              <div className="list-data-one">
                <p>Item Code</p>
                <span>#6590</span>
              </div>
              <div className="list-data-one">
                <p>Unit</p>
                <span>KG</span>
              </div>
              <div className="list-data-one">
                <p>Manufacturer</p>
                <span>ACE</span>
              </div>
              <div className="list-data-one">
                <p>Price</p>
                {/* <span>20,000.00</span> */}
                <div className="price-new-Drawer">
                  <Price price_main="100,000.00" price_symbol="" />
                </div>
              </div>

              <div className="list-data-one">
                <p>Department</p>
                <span>Civil</span>
              </div>


              <div className="list-data-one">
                <p>Last change</p>
                {/* <span>20,000.00</span> */}
                <div className="price-new-Drawer">
                  <Price price_main="50.00" price_symbol={ <IconArrowUp />} />
                </div>
              </div>
             
            </div>
          </div>
        </div>

        <div className="bottom-btn-attachmenet">
          <button
            class="common-button"
            onClick={() => handleShowItemsmodal()}
          >
            View History
          </button>
        </div>
      </Drawer>

      <div className="paginate_all_common">
        <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
      </div>


      <PriceHistoryModal
         showItemsmodal={showItemsmodal}
         handleCloseItemsmodal={handleCloseItemsmodal}
      />
    </>
  ); 
};

export default PriceTable;
