import React from "react";
import "./addpolistform.scss";
import { IconCalendarLight, IconDocs } from "../../../../../icon/icons";
import FlatpickrAll from "../../../../layout/flatpickrall";
const AddPolistForm = () => {
  return (
    <>
      <div className="form-data-main">
        <div className="page-card-bg">
          <div className="form-all-grid">
            <div className="input-main-data">
              <label>PO Number</label>
              <input placeholder="#2546221" />
            </div>

            <div className="input-main-data">
              <label>Quote no</label>
              <input placeholder="ERP Dashboard design" />
            </div>

            <div className="input-main-data">
              <label>Client Name & Client Number </label>
              <input placeholder="Enquiry" />
            </div> 
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Project Name & Number</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              <label>Contractual completion date</label>
              <FlatpickrAll/>
             
            </div>

            <div className="input-main-data">
              <label>DLP Period (From completion)</label>
              <input placeholder="12 months" />
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Discount</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              {/* <label>Sub Total Amount (Excl. VAT)</label>
            <input placeholder="Ahsan Hassan -25482" /> */}
            </div>

            <div className="input-main-data">
              {/* <label>Category</label>
            <input placeholder="Civil" /> */}
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Sub Total Amount (Excl. VAT)</label>
              <input placeholder="Ahsan Hassan -25482" />
            </div>

            <div className="input-main-data">
              <div className="input-check">
                {/* <input type="checkbox" />
                <label>VAT Applicable</label> */}
                 <div class="remember_me_input">
                  <input type="checkbox" id="remember" />
                  <label for="remember" class="checkbox-label"></label>
                  <span>VAT Applicable</span>
                </div>
              </div>
             
              {/* <label>VAT Amount (5%)</label>
            <input placeholder="#245879" /> */}
            </div>

            <div className="input-main-data">
              {/* <label>Total Amount (Incl. VAT)</label>
            <input placeholder="Ahsan Hassan -25482" /> */}
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>VAT Amount (5%)</label>
              <input placeholder="#245879" />
            </div>

            <div className="input-main-data">
              {/* <label>VAT Amount (5%)</label>
            <input placeholder="#245879" /> */}
            </div>

            <div className="input-main-data">
              {/* <label>VAT Amount (5%)</label>
            <input placeholder="#245879" /> */}
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Total Amount (Incl. VAT)</label>
              <input placeholder="Ahsan Hassan -25482" />
            </div>
            <div className="input-main-data">
              {/* <label>VAT Amount (5%)</label>
            <input placeholder="#245879" /> */}
            </div>

            <div className="input-main-data">
              {/* <label>VAT Amount (5%)</label>
            <input placeholder="#245879" /> */}
            </div>
          </div>

          <div className="title-button-data dis-gird-btn-new">
            <p>Upload Attachment</p>
            <div className="btn-inner-flex">
              <button className="common-button">
                <span>
                  <IconDocs />
                </span>{" "}
                Upload PO / confirmation (PDF)
              </button>
            </div>
          </div>
        </div>

        <div className="btn-form-send">
          <button className="common-button">Submit</button>

          <button className="common-button cancel-btn">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default AddPolistForm;
