import React from 'react'
import "./purchasequoteitemsmodal.scss"
import Modal from "react-bootstrap/Modal";

import { IconClose, IconDownload, IconPrint } from '../../../../../icon/icons';
import Table from "react-bootstrap/Table";
import Price from '../../../../layout/price';



const data = [
  {
    S_No: "#12345",
    Ref: "Item name",
    Description: "Lorem ipsum dolor sit amet. Et exercitationem suscipit ...",
    Unit: "123",
    Qty: "145",
    unit_price: "note",
    price_syb: "$",
    price_value: "250",
  },
  {
    S_No: "#12345",
    Ref: "Item name",
    Description: "Lorem ipsum dolor sit amet. Et exercitationem suscipit ...",
    Unit: "123",
    Qty: "145",
    unit_price: "note",
    price_syb: "$",
    price_value: "250",
  },
  {
    S_No: "#12345",
    Ref: "Item name",
    Description: "Lorem ipsum dolor sit amet. Et exercitationem suscipit ...",
    Unit: "123",
    Qty: "145",
    unit_price: "note",
    price_syb: "$",
    price_value: "250",
  },
  {
    S_No: "#12345",
    Ref: "Item name",
    Description: "Lorem ipsum dolor sit amet. Et exercitationem suscipit ...",
    Unit: "123",
    Qty: "145",
    unit_price: "note",
    price_syb: "$",
    price_value: "250",
  },
];



const PurchaseQuoteitemsModal = (props) => {
    const { showItemsmodal, handleCloseItemsmodal } = props;
  return (
    <>
    
    <Modal
        className="modal-all-data modal-purchase-new-data"
        show={showItemsmodal}
        onHide={handleCloseItemsmodal}
        centered
      >
        <Modal.Body>
         
        <div className="closemodal_btn">
            <button onClick={handleCloseItemsmodal}>
              <IconClose />
            </button>
          </div>

          <div className="modal-inner-data">
          <div className="table-modal-mid">
              <div className="table-data-main Estimation-table-new">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>S. No</th>
                      <th>Ref. BOQ</th>
                      <th>Description</th>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Unit price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="user-info-modal">
                            <p>{item.S_No}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-info-modal">
                            <p>{item.Ref}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-info-modal modal-description">
                            <p>{item.Description}</p>
                          </div>
                        </td>

                        <td>
                          <div className="user-info-modal">
                            <p>{item.Unit}</p>
                          </div>
                        </td>

                        <td>
                          <div className="user-info-modal">
                            <p>{item.Qty}</p>
                          </div>
                        </td>

                        <td>
                          <div className="user-info-modal">
                            <p>{item.unit_price}</p>
                          </div>
                        </td>

                        <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.price_value}
                        price_symbol={item.price_syb}
                      />
                    </div>
                  </div>
                  <div className="user-info">
                   
                  </div>
                </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="btn-acction">
              <button className="common-button" >
                <span>
                  <IconDownload />
                </span>
                Download PDF
              </button>

              <button className="common-button" >
                <span>
                  <IconPrint />
                </span>
                Print
              </button>
            </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PurchaseQuoteitemsModal