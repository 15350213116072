import React, { useState } from "react";

const QuotationListtableUtils = () => {
  const [showProfilemodal, setShowProfileModal] = useState(false);
  const handleCloseProfilemodal = () => setShowProfileModal(false);
  const handleShowProfilemodal = () => setShowProfileModal(true);

  const [showRequestmodal, setShowRequestModal] = useState(false);
  const handleCloseRequestmodal = () => setShowRequestModal(false);
  const handleShowRequestmodal = () => setShowRequestModal(true);

  return {
    showProfilemodal,
    setShowProfileModal,
    handleCloseProfilemodal,
    handleShowProfilemodal,
    showRequestmodal,
    setShowRequestModal,
    handleCloseRequestmodal,
    handleShowRequestmodal,
  };
};

export default QuotationListtableUtils;
