import React, { useContext } from "react";
import { GlobalContext } from "../../../../context";

const TotalClientsUtils = () => {
  const { themeMode } = useContext(GlobalContext);

  return {
    themeMode,
  };
};

export default TotalClientsUtils;
