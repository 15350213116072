import React from "react";
import "./agentdatabase.scss";
import {
  AgentTableData,
  CommonFilters,
  TitleHeader,
} from "../../../../components";
import { useNavigate } from "react-router-dom";

const AgentDatabase = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Marketing", link: "" },
    { label: "Agent database", link: "" },
    { label: "Agent lists", link: "" },
  ];

  const handleAddClientClick = () => {
    navigate("/marketing/agent-database/add-agent");
  };
  return (
    <>
      <TitleHeader
        heading="Agent Lists"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add Agent"
        onButtonClick={handleAddClientClick}
      />
      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Agent Lists" isDatebtn={false}/>
        <AgentTableData />
      </div>
    </>
  );
};

export default AgentDatabase;
