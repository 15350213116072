import React, { useState } from "react";
import "./clientform.scss";
import { Autocomplete, TextField } from "@mui/material";
import DetailsModal from "../detailsmodal";
import ClientFormUtils from "./clientformUtils";
import CompanyDetailsModal from "../companydetailsmodal";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
const ClientForm = () => {
  const {
    showAddclientmodal,
    setShowAddclientModal,
    handleCloseAddclientmodal,
    handleShowAddclientmodal,
    showAddCompanymodal,
    setShowAddCompanyModal,
    handleCloseAddCompanymodal,
    handleShowAddCompanymodal,
  } = ClientFormUtils();

  const TypesList = [
    { _id: "1", title: "Demo-1" },
    { _id: "2", title: "Demo-2" },
    { _id: "3", title: "Demo-3" },
    { _id: "4", title: "Demo-4" },
    // Add more property types as needed
  ];

  const selectedType = { _id: "2", title: "Demo-2" };

  const [countryCode, setCountryCode] = useState("+1");
  const [phone, setPhone] = useState("");

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  

  return (
    <div className="form-data-main">
      <div className="page-card-bg">
        <div className="form-all-grid">
          <div className="input-main-data autofilled-text-new">
            <label>Client Code</label>
            <input placeholder="abc12345" />
          </div>

          <div className="input-main-data">
            <label>Client name </label>
            <input placeholder="John Smith" />
          </div>

          <div className="input-main-data">
            <label>Type</label>

            {/* <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label" 
              id="demo-simple-select"
              placeholder="Select"
              // onChange={handleChange}
              value={10}
            >
              <MenuItem value={10}>- Select Option -</MenuItem>
              <MenuItem value={20}>Select</MenuItem>
              <MenuItem value={30}>Select</MenuItem>
            </Select>
          </FormControl> */}

            <Autocomplete
              fullWidth
              className="automcomplete_select_info"
              autoComplete={false}
              id="free-solo-demo"
              options={TypesList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedType}
              onChange={(e, value) => {
                console.log("Selected Value:", value);
              }}
            />
          </div>
        </div>

        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Source of enquiry</label>
            <Autocomplete
              fullWidth
              className="automcomplete_select_info"
              autoComplete={false}
              id="free-solo-demo"
              options={TypesList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedType}
              onChange={(e, value) => {
                console.log("Selected Value:", value);
              }}
            />

            {/* <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select"
                // onChange={handleChange}
                value={10}
              >
                <MenuItem value={10}>- Select Option -</MenuItem>
                <MenuItem value={20}>Select</MenuItem>
                <MenuItem value={30}>Select</MenuItem>
              </Select>
            </FormControl> */}
          </div>

          {/* <div className="input-main-data">
            <label>Contact number </label>
            <div className="contact-number-all-input">
              <select
                value={countryCode}
                onChange={handleCountryCodeChange}
                style={{ marginRight: "8px" }}
              >
                <option value="+1">+1 </option>
                <option value="+44">+44 </option>
                <option value="+91">+91</option>
                <option value="+61">+61 </option> 
                <option value="+81">+81</option>
              </select>
              <input placeholder="+1 1234567890" />
            </div>
          </div> */}


     <div className="input-main-data">
      <label>Contact Number</label>
      <PhoneInput 
        country={"us"}
        value={phone}
        onChange={(phone) => setPhone(phone)}
        // inputStyle={{ width: "100%", height: "57px" }}
        buttonStyle={{ marginRight: "8px" }}
        placeholder="+1 1234567890"
      />
      </div>

      
          <div className="input-main-data">
            <label>Email </label>
            <input placeholder="exaple@gmail.com" />
          </div>
        </div>

        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Point of Contact (Name) </label>
            <input placeholder="Jerry Wilson" />
          </div>

          <div className="input-main-data">
            <label>Point of Contact (No.) </label>
            <input placeholder="+1 1234567890" />
          </div>

          <div className="input-main-data">
            <label>Point of Contact (Email)</label>
            <input placeholder="exaple@gmail.com" />
          </div>
        </div>

        <div className="title-button-data">
          <button
            className="common-button"
            onClick={() => handleShowAddclientmodal()}
          >
            <span>+</span> Add More Details
          </button>

          <button
            className="common-button"
            onClick={() => handleShowAddCompanymodal()}
          >
            <span>+</span> Add More Details Company
          </button>
        </div>
      </div>

      <div className="btn-form-send">
        <button className="common-button">Submit</button>
        <button className="common-button cancel-btn">Cancel</button>
      </div>

      <DetailsModal
        showAddclientmodal={showAddclientmodal}
        handleCloseAddclientmodal={handleCloseAddclientmodal}
      />

      <CompanyDetailsModal
        showAddCompanymodal={showAddCompanymodal}
        handleCloseAddCompanymodal={handleCloseAddCompanymodal}
      />
    </div>
  );
};

export default ClientForm;
