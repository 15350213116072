import React, { useState } from 'react'
import "./poregisterfrom.scss"
import { Autocomplete, TextField } from "@mui/material";
import { IconClose } from '../../../../../icon/icons';

const PoRegisterFrom = () => {
    const TypesList = [
        { _id: "1", title: "Demo-1" },
        { _id: "2", title: "Demo-2" },
        { _id: "3", title: "Demo-3" },
        { _id: "4", title: "Demo-4" },
      ];
    
      const selectedType = { _id: "2", title: "Demo-2" };
    
      const [divs, setDivs] = useState([1]);
    
      const addDiv = () => {
        setDivs([...divs, divs.length + 1]);
      };
    
      const removeDiv = (index) => {
        setDivs(divs.filter((_, i) => i !== index));
      };
  return (
    <>
    <div className="form-data-main purchase-form-new">
      <div className="page-card-bg">
        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Date</label>
            <input placeholder="15/11/2023" />
          </div>

          <div className="input-main-data">
            <label>Project Name & Number </label>
            <input placeholder="Mark  - 1410 - ABC Enterprise" />
          </div>

          <div className="input-main-data">
            <label>PO Number</label>
            <input placeholder="#5648" />
          </div>
        </div>

        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Quote number</label>
            <input placeholder="14277" />
          </div>

          <div className="input-main-data"></div>
          <div className="input-main-data"></div>
        </div>



        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Discount</label>
            <input placeholder="15/11/2023" />
          </div>

          <div className="input-main-data">
            <label>Sub Total Amount (Excl. VAT) </label>
            <input placeholder="Mark  - 1410 - ABC Enterprise" />
          </div>

          <div className="input-main-data">
          <div className="input-check">
                {/* <input type="checkbox" />
                <label>VAT Applicable</label> */}
                 <div class="remember_me_input">
                  <input type="checkbox" id="remember" />
                  <label for="remember" class="checkbox-label"></label>
                  <span>VAT Applicable</span>
                </div>
              </div>
          </div>
        </div>

        
        <div className="form-all-grid">
          <div className="input-main-data">
            <label>VAT Amount (5%)</label>
            <input placeholder="15/11/2023" />
          </div>

          <div className="input-main-data">
            <label>Total Amount (Incl. VAT)</label>
            <input placeholder="Mark  - 1410 - ABC Enterprise" />
          </div>

          <div className="input-main-data">
            {/* <label>PO Number</label>
            <input placeholder="#5648" /> */}
          </div>
        </div>

        <div className="form-new-grid-main">
          <div className="purchase-overflow-data">
            <div className="div-label-all-grid">
              <div className="input-main-data">
                <label>S. No.</label>
              </div>
              <div className="input-main-data">
                <label>Ref. BOQ</label>
              </div>
              <div className="input-main-data">
                <label>Description</label>
              </div>

              <div className="input-main-data">
                <label>Unit</label>
              </div>
              <div className="input-main-data">
                <label>Qty</label>
              </div>

              <div className="input-main-data">
                <label>Unit price</label>
              </div>
              <div className="input-main-data">
                <label>Amount</label>
              </div>
              <div className="remove-btn"></div>
            </div>

            {divs.map((div, index) => (
              <div className="input-first-div-main" key={index}>
                <div className="input-main-data">
                  <input placeholder="#12345" />
                </div>
                <div className="input-main-data">
                  <input placeholder="item name" />
                </div>
                <div className="input-main-data">
                  <input placeholder="item name" />
                </div>

                <div className="input-main-data">
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="input-main-data">
                  <Autocomplete
                    fullWidth
                    className="automcomplete_select_info"
                    autoComplete={false}
                    id="free-solo-demo"
                    options={TypesList}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Type" />
                    )}
                    value={selectedType}
                    onChange={(e, value) => {
                      console.log("Selected Value:", value);
                    }}
                  />
                </div>

                <div className="input-main-data">
                  <input placeholder="note" />
                </div>

                <div className="input-main-data">
                  <input placeholder="note" />
                </div>

                {/* {index !== 0 && ( */}
                <div className="remove-btn">
                  <button onClick={() => removeDiv(index)}>
                    {" "}
                    <IconClose />
                  </button>
                </div>
                {/* )} */}
              </div>
            ))}
          </div>

          <div className="add-more-owner-btn">
            <button className="common-button" onClick={addDiv}>
              <span>+</span> Add item
            </button>
          </div>
        </div>
      </div>

      <div className="btn-form-send">
        <button className="common-button">Submit</button>

        <button className="common-button cancel-btn">Cancel</button>
      </div>
    </div>
  </>
  )
}

export default PoRegisterFrom