import React, { useEffect, useState } from "react";
import { images } from "../../../utils/customFn";
import "./header.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useLocation } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { IconBell, IconCalendar, IconMassage } from "../../../icon/icons";
import ThemeToggle from "../themetoggle";
import MenuItems from "../menuitems";
const DashboardHeader = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isOpen) {
      toggleDrawer(false);
    }
  }, [location]);
  return (
    <div className="header-deshboard-main">
      <div className="mobile-grid-data">
        <div className="mobile-drawer-button">
          <div className="header_drawertoglebtn">
            <button className="white_logo" onClick={toggleDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
              >
                <path
                  d="M22.5 3.50001H1.5C0.671577 3.50001 0 2.82843 0 2C0 1.17158 0.671577 0.5 1.5 0.5H22.5C23.3284 0.5 24 1.17158 24 2C24 2.82843 23.3284 3.50001 22.5 3.50001Z"
                  fill="#FCFDFF"
                />
                <path
                  d="M9.9375 7.5H23.0625C23.5803 7.5 24 8.17158 24 9C24 9.82842 23.5803 10.5 23.0625 10.5H9.9375C9.41974 10.5 9 9.82842 9 9C9 8.17158 9.41974 7.5 9.9375 7.5Z"
                  fill="#FCFDFF"
                />
                <path
                  d="M1.5 14.5H22.5C23.3284 14.5 24 15.1716 24 16C24 16.8284 23.3284 17.5 22.5 17.5H1.5C0.671577 17.5 0 16.8284 0 16C0 15.1716 0.671577 14.5 1.5 14.5Z"
                  fill="#FCFDFF"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="dis-grid">
          <div className="icon-list-main">
            {/* <ThemeToggle /> */}
            <ul>
              {/* <li>
                <button>
                  {" "}
                  <IconCalendar />{" "}
                </button>
              </li> */}
              <li>
                <button>
                  <IconBell />
                  <span className="badge-org badge-all">2</span>
                </button>
              </li>
              {/* <li>
                <button>
                  {" "}
                  <IconMassage />
                  <span className="badge-grn badge-all">2</span>
                </button>
              </li> */}
            </ul>
          </div>

          <div className="drop-down-main drop-profile-new">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="btn-drop-user">
                <div className="user-icon-profile">
                  <img src={images["user.png"]} alt="" />
                </div>
                <div className="user-info-login">
                  <h2>Jasmin</h2>
                  <p>Manager</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faUser} /> */}
                  </span>{" "}
                  Profile
                </Dropdown.Item>

                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                  </span>
                  Settings
                </Dropdown.Item>

                <div className="div-inner-dropdown">
                  <ThemeToggle />
                </div>

                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                  </span>
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className="mobile_nav_menu"
      >
        <div className="header-mobile-inner">
          <div className="sidebar_logo">
            <NavLink to="/">
              <img
                className="sidebar_logo_img"
                src={images["logo.png"]}
                alt="logo"
                loading="lazy"
              />
            </NavLink>
          </div>
          <div className="drop-down-main drop-profile-new">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="btn-drop-user">
                <div className="user-icon-profile">
                  <img src={images["user.png"]} alt="" />
                </div>
                <div className="user-info-login">
                  <h2>Jasmin</h2>
                  <p>Manager</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faUser} /> */}
                  </span>{" "}
                  Profile
                </Dropdown.Item>

                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                  </span>
                  Settings
                </Dropdown.Item>

                <div className="div-inner-dropdown">
                  <ThemeToggle />
                </div>

                <Dropdown.Item>
                  <span className="sidebar-icon">
                    {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                  </span>
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <MenuItems collapsed={false} />
        </div>
      </Drawer>
    </div>
  );
};

export default DashboardHeader;
