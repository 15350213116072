import React, { useState } from "react";
import "./purchaseselectmodal.scss";
import Price from "../../../../layout/price";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import CommonFilters from "../../../../layout/commonfilters";
import Table from "react-bootstrap/Table";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import { useNavigate } from "react-router-dom";
import CommonPaginate from "../../../../layout/commonpaginate";

const PurchaeSelectModal = (props) => {

  const { showDocumentmodal, handleCloseDocumentmodal } = props;
  const navigate = useNavigate();


  const data = [
    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",
      Project_no: "#2458",
      Department: "MEP",
      Type: "Material",
      Requested_name: "David",
      Requested_id: "#12345",
      action: () => {
        navigate("/purchase/add-enquiry");
      },
    },
    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",
      Project_no: "#2458",
      Department: "MEP",
      Type: "Material",
      Requested_name: "David",
      Requested_id: "#12345",
      action: () => {
        navigate("/purchase/add-project");
      },
    },

    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",
      Project_no: "#2458",
      Department: "MEP",
      Type: "Material",
      Requested_name: "David",
      Requested_id: "#12345",
      action: () => {
   
      },
    },
  ];


  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };




  

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal-new"
        show={showDocumentmodal}
        onHide={handleCloseDocumentmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseDocumentmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="PR List" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>PR No. </th>
                  <th>Project</th>
                  <th>Department</th>
                  <th>Type</th>
                  <th>Requested by</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <span>{item.date}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Enquiry_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Project_name}</p>
                        <span>#{item.Project_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Department}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Type}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Requested_name}</p>
                        <span>#{item.Requested_id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="btn-dis-new">
                        <button
                          className="common-button"
                          onClick={item?.action}
                        >
                          Select
                        </button>
                        
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PurchaeSelectModal;
