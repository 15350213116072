import React, { useContext } from "react";
import { GlobalContext } from "../../../../context";

const DashTotalVendorsUtils = () => {
  const { themeMode } = useContext(GlobalContext);
  return {
    themeMode,
  };
};

export default DashTotalVendorsUtils;
