import React, { useState } from "react";

const ClientFormUtils = () => {
  const [showAddclientmodal, setShowAddclientModal] = useState(false);
  const handleCloseAddclientmodal = () => setShowAddclientModal(false);
  const handleShowAddclientmodal = () => setShowAddclientModal(true);

  const [showAddCompanymodal, setShowAddCompanyModal] = useState(false);
  const handleCloseAddCompanymodal = () => setShowAddCompanyModal(false);
  const handleShowAddCompanymodal = () => setShowAddCompanyModal(true);

  return {
    showAddclientmodal,
    setShowAddclientModal,
    handleCloseAddclientmodal,
    handleShowAddclientmodal,
    showAddCompanymodal,
    setShowAddCompanyModal,
    handleCloseAddCompanymodal,
    handleShowAddCompanymodal,
  };
};

export default ClientFormUtils;
