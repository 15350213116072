import React, { useState } from 'react'
import './enquirymarketingform.scss'
import { Autocomplete, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { IconDocs, IconLocation, IconUpload } from '../../../../../icon/icons';
import EnquiryLocationModal from '../enquirylocationmodal';

const EnquiryMarketingForm = () => {

 // Loaction Modal
 const [showLocationmodal, setShowLocationModal] = useState(false);
 const handleCloseLocationmodal = () => setShowLocationModal(false);
 const handleShowLocationmodal = () => setShowLocationModal(true);


    const TypesList = [
        { _id: "1", title: "Demo-1" },
        { _id: "2", title: "Demo-2" },
        { _id: "3", title: "Demo-3" },
        { _id: "4", title: "Demo-4" },
        // Add more property types as needed
      ];
    
      const selectedType = { _id: "2", title: "Demo-2" };
      // Switch btn
    
      const [isOn, setIsOn] = useState(false);
      const toggleSwitch = () => {
        setIsOn(!isOn);
      };
    
      // Multiple Type
      const TypesListNew = [
        { title: "Type 1" },
        { title: "Type 2" },
        { title: "Type 3" },
        // Add more types here
      ];
      const [selectedNewTypes, setSelectedNewTypes] = React.useState([]);
    
  return (
    <>
    <div className="form-data-main">
      <div className="page-card-bg">
        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Date</label>
            <input placeholder="15/11/2023" />
          </div>

          <div className="input-main-data">
            <label>Enquiry Number </label>
            <input placeholder="12345" />
          </div>

          <div className="input-main-data">
            <label>Enquiry Type</label>
            <Autocomplete
              fullWidth
              className="automcomplete_select_info"
              autoComplete={false}
              id="free-solo-demo"
              options={TypesList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedType}
              onChange={(e, value) => {
                console.log("Selected Value:", value);
              }}
            />
          </div>
        </div>

        <div className="form-all-grid">
          <div className="input-main-data">
            <label>Description</label>
            <input placeholder="Description here..." />
          </div>
        </div>

      

        <div className="form-all-grid">
          {/* <div className="input-main-data">
            <label>Client Name & Client Number  </label>
            <Autocomplete
              fullWidth
              className="automcomplete_select_info"
              autoComplete={false}
              id="free-solo-demo"
              options={TypesList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedType}
              onChange={(e, value) => {
                console.log("Selected Value:", value);
              }}
            />
          </div> */}


          <div className="input-main-data">
            <label>Client Name & Client Number</label>
            <Autocomplete
              multiple
              fullWidth
              className="automcomplete_select_info_multiple"
              id="multiple-select-demo"
              options={TypesListNew}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedNewTypes}
              onChange={(e, value) => {
                setSelectedNewTypes(value);
                console.log("Selected Values:", value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option?.title} {...getTagProps({ index })} />
                ))
              }
            />
          </div>


          <div className="input-main-data">
            <label>Project Category </label>
            <Autocomplete
              multiple
              fullWidth
              className="automcomplete_select_info_multiple"
              id="multiple-select-demo"
              options={TypesListNew}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedNewTypes}
              onChange={(e, value) => {
                setSelectedNewTypes(value);
                console.log("Selected Values:", value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option?.title} {...getTagProps({ index })} />
                ))
              }
            />
          </div>

          <div className="input-main-data select-all-type">
            <div className="data-switch-btn-gird">
              <label>Select Applicable Authorities</label>
              <div
                className={`toggle-switch ${isOn ? "on" : "off"}`}
                onClick={toggleSwitch}
              >
                <div className="toggle-knob"></div>
              </div>
            </div>
            <Autocomplete
              multiple
              fullWidth
              className="automcomplete_select_info_multiple"
              id="multiple-select-demo"
              options={TypesListNew}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedNewTypes}
              onChange={(e, value) => {
                setSelectedNewTypes(value);
                console.log("Selected Values:", value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option?.title} {...getTagProps({ index })} />
                ))
              }
            />
          </div>
        </div>


        <div className="form-all-grid">
          <div className="input-main-data">
            <label>General Notes</label>
            <input placeholder="Write here" />
          </div>
        </div>


        <div className="title-button-data dis-gird-btn-new">
          <p>Location</p>
          <button
            className="common-button"
            onClick={() => handleShowLocationmodal()}
          >
            <span>
              <IconLocation />
            </span>{" "}
            Add Location
          </button>
        </div>

        <div className="title-button-data dis-gird-btn-new">
          <p>Add Attachment</p>
          <button className="common-button">
            <span>
              <IconUpload />
            </span>{" "}
            Upload Enquiry mail
          </button>

          <button className="common-button">
            <span>
              <IconDocs />
            </span>{" "}
            Upload Tender Docs
          </button>
        </div>
      </div>

      <div className="btn-form-send">
        <button className="common-button">Submit</button>

        <button className="common-button cancel-btn">Cancel</button>
      </div>
    </div>

    <EnquiryLocationModal
      showLocationmodal={showLocationmodal}
      handleCloseLocationmodal={handleCloseLocationmodal}
    />
  </>
  )
}

export default EnquiryMarketingForm