import React from "react";
import "./purchaserequestlist.scss";
import { CommonFilters, PurchaseListTable, TitleHeader } from "../../../../components";
import { useNavigate } from "react-router-dom";

const PurchaseRequestList = () => {
  const breadcrumbs = [{ label: "", link: "" }];
  const navigate = useNavigate();
  const handleAddClientClick = () => {
    navigate("/purchase/add-request");
  };

  return (
    <>
      <TitleHeader
        heading="Purchase Request"
        breadcrumbs={breadcrumbs}
        buttonText="+ New Purchase Request"
        onButtonClick={handleAddClientClick}
      />
      <div className="page-card-bg table-data-new">
        <CommonFilters heading="List" />
        <PurchaseListTable/>
      </div>
    </>
  );
};

export default PurchaseRequestList;
