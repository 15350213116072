import React from 'react'
import "./topsubcontractors.scss"
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import { IconArrowRight } from '../../../../icon/icons';
const data = [
    {
      Rank: "1",
      id: 245879,
      name: "Mark Smith",
      Total_Enquiries: "1,396",
      Total_Projects: "1,150",
      Total_Value: "750",
    },
    {
      Rank: "1",
      id: 245879,
      name: "Mark Smith",
      Total_Enquiries: "1,396",
      Total_Projects: "1,150",
      Total_Value: "750",
    },
    {
      Rank: "1",
      id: 245879,
      name: "Mark Smith",
      Total_Enquiries: "1,396",
      Total_Projects: "1,150",
      Total_Value: "750",
    },
    {
      Rank: "1",
      id: 245879,
      name: "Mark Smith",
      Total_Enquiries: "1,396",
      Total_Projects: "1,150",
      Total_Value: "750",
    },
  ];

const TopSubContractors = () => {
  return (
    <>
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> top subcontractors </h2>
          </div>
          <div className="view-all">
            <NavLink>
              View all{" "}
              <span>
                {" "}
                <IconArrowRight />{" "}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="all-table-data">
          <div className="table-data-main table-all-inner">
            <Table responsive>
              <thead>
                <tr>
                  <th> Rank</th>
                  <th>subcon Name</th>
                  <th>Total project Involved </th>
                  <th>Total Value</th>
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-rank">
                        <p>{item.Rank}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.name}</p>
                        <span>#{item.id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Total_Enquiries}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Total_Value}</p>
                      </div>
                    </td>

                   
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopSubContractors
