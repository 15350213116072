import React from "react";
import { Outlet } from "react-router-dom";
import UseSidebar from "./sidebar";
import DashboardHeader from "./header";

const Master = () => {
  return (
    <>
      <div className="admin-main-layout">
        <div className="dashboard-sidebar">
          <UseSidebar />
        </div>
        <div className="dashbord-page-content">
          <DashboardHeader />

          <div className="all-router-data">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Master;
