import React from "react";
import { NavLink } from "react-router-dom";
import "./titleheader.scss";
import TableActionButton from "../tableactionbutton";
const TitleHeader = ({
  heading,
  breadcrumbs,
  buttonText,
  onButtonClick,
  buttonText2, 
  onButtonClick2,
  isDropbtn = false,
  isDropOptions = {},
}) => {
  return (
    <div className="title-header-main">
      <div className="dis-grid-title">
        <div className="title-text-data">
          <div className="heading-main">
            <h2 className="common-heading">{heading}</h2>
          </div>
          <div className="breadcrumbs-list">
            <ul>
              {breadcrumbs.map((breadcrumb, index) => (
                <li key={index}>
                  <NavLink to={breadcrumb.link}>
                    {breadcrumb.label}
                    {index !== breadcrumbs.length - 1 && " /"}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="title-button-data">
          {onButtonClick && buttonText && (
            <button className="common-button" onClick={onButtonClick}>
              {/* <span>+</span>  */}
              {buttonText}
            </button>
          )}

          {onButtonClick2 && buttonText2 && (
            <button className="common-button" onClick={onButtonClick2}>
              {/* <span>+</span>  */}
              {buttonText2}
            </button>
          )}

          {isDropbtn === true ? (
           <div className="drop-down-btn-new">
            <TableActionButton
              options={isDropOptions?.options}
              isOpen={isDropOptions?.isOpen}
              isDropIcon={isDropOptions?.isDropIcon}
            />
          </div>
           
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TitleHeader;
