import React from "react";
import "./addpo.scss";
import { AddPolistForm, TitleHeader } from "../../../../components";
const AddPo = () => {
  const breadcrumbs = [
    { label: "PO", link: "" },
    { label: "Add PO", link: "" },
  ];
  return (
    <>
      <TitleHeader heading="Add New PO" breadcrumbs={breadcrumbs} />

      <AddPolistForm />
    </>
  );
};

export default AddPo;
