import React from "react";
import "./estimationenquirieschart.scss";
import Chart from "react-apexcharts";
const EstimationEnquiriesChart = () => {
  // Define the data for each chart
  const series1 = [20, 30, 40, 10]; // Values for the first chart
  const series2 = [50, 50]; // Values for the second chart

  // Define the options for each chart
  



  const chartOptions1 = {
    chart: {
      type: "donut",
    },
    labels: ["MEP", "Civil", "Joinery", "Others"],
    colors: ["#F2C94C", "#002855", "#f3c619bd", "#5B7192"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: true, // Show the legend
      position: "bottom", // Position the legend at the bottom
      horizontalAlign: "center", // Optional: Center the legend horizontally
      labels: {
        colors: "var(--btn-text)",
      },
    },
    stroke: {
      width: 0, // Removes the border
    },
    responsive: [
      {
        breakpoint: 1600, // Adjust settings for smaller screens
        options: {
          chart: {
            width:280, // Smaller width for mobile
          },
        },
      },
      {
        breakpoint: 1136, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 350, // Smaller width for mobile
          },
        },
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: "donut",
    },
    labels: ["Submitted", "Approved"],
    colors: ["#F2C94C", "#002855", "#f3c619bd", "#5B7192"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: true, // Show the legend
      position: "bottom", // Position the legend at the bottom
      horizontalAlign: "center", // Optional: Center the legend horizontally
      labels: {
        colors: "var(--btn-text)",
      },
    },
    stroke: {
      width: 0, // Removes the border
    },
    responsive: [
      {
        breakpoint: 1600, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 280, // Smaller width for mobile
          },
        },
      },
      {
        breakpoint: 1136, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 350, // Smaller width for mobile
          },
        },
      },
    ],
  };
  return (
    <div className="page-card-bg">
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> Enquiries </h2>
        </div>
        {/* <div className='view-all'>
      <NavLink>View all <span> <IconArrowRight/> </span></NavLink>
    </div>
     */}
      </div>
      <div className="chart-new-main">
        <div className="chart-one-new chart-inner">
          <h3>Deparments</h3>
          <div className="chart-option-info">
            <Chart
              options={chartOptions1}
              series={series1}
              type="donut"
              width="320"
            />
          </div>
        </div>
        <div className="chart-two-new chart-inner">
          <h3>Status</h3>
          <div className="chart-option-info">
            <Chart
              options={chartOptions2}
              series={series2}
              type="donut"
              width="320"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimationEnquiriesChart;
 