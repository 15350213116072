import React from "react";
import "./enquiryestimationprofilemodal.scss";
import { images } from "../../../../../utils/customFn";
import Modal from "react-bootstrap/Modal";

const EnquiryEstimationProfileModal = (props) => {
  const { showProfilemodal, handleCloseProfilemodal } = props;
  return (
    <>
      <Modal
        className="profile-modal-main"
        show={showProfilemodal}
        onHide={handleCloseProfilemodal}
        centered
      >
        <Modal.Body>
          <div className="modal-image">
            <img src={images["modal-image.png"]} alt="" />
          </div>
          {/* <div className="closemodal_btn"> 
       <button onClick={handleCloseProfilemodal}>
         <IconClose />
       </button>
     </div> */}

          {/* <div className="modal-inner-data">
       <div className="heading-main">
         <h2 className="common-heading">Add more details - Individual</h2>
       </div>
     </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnquiryEstimationProfileModal;
