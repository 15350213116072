import React from "react";
import { useNavigate } from "react-router-dom";
import { IconCalendarLight } from "../../icon/icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import "./dashboard.scss";
import {
  AllProject,
  Calender,
  InfoBoxs,
  RecentProject,
  Revenue,
  TitleHeader,
} from "../../components";

const MainDashboard = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Lorem ipsum dolor si amet welcome back Jasmin.", link: "" },
  ];

  const handleAddClientClick = () => {
    navigate("");
  };

  return (
    <>
      <div className="main-dashboard-header-top">
        <div className="flex-data-main">
          <div className="header-top-data">
            <TitleHeader
              heading="Welcome Jasmin!"
              breadcrumbs={breadcrumbs}
              buttonText=""
              onButtonClick={""}
            />
          </div>

          {/* <div className="data-select-main">
            <div className="date-all-btn">
              <div className="date-input-inner">
                <span>
                  <IconCalendarLight />
                </span>
                <Flatpickr
                  id="date"
                  className="date_picker"
                  placeholder="Select dates"
                  name="date"
                  options={{
                    disableMobile: "true",
                    minDate: "today",
                    dateFormat: "d/m/Y",
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>

        <InfoBoxs />

        <div className="layout-main-dashboard">
          <div className="layout-flex-data">
            <div className="grid-wd-one"> 
              <Revenue />
            </div>
            <div className="grid-wd-two">
              <AllProject />
            </div>
          </div>
        </div>

        <div className="layout-main-dashboard">
          <div className="layout-flex-data">
            <div className="grid-wd-one">
              <RecentProject />
            </div>
            <div className="grid-wd-two">
              <Calender />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
