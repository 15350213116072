import React, { useState } from "react";
import "./purchasequoteselectmodal.scss";
import Price from "../../../../layout/price";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import CommonFilters from "../../../../layout/commonfilters";
import Table from "react-bootstrap/Table";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import { useNavigate } from "react-router-dom";
import CommonPaginate from "../../../../layout/commonpaginate";

const PurchaseQuoteSelectModal = (props) => {
  const { showDocumentmodal, handleCloseDocumentmodal } = props;
  const navigate = useNavigate();

  const data = [
    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",

      Project_Enquiry:"Project",
      Project_no: "#2458",
      client_name: "Mark smith",
      client_id: "12345",

      Vendor:"Vendor",
      Type: "Material",
      Department: "Civil",
    
    
      action: () => {
        navigate("/purchase/add-quote-enquiry");
        
      },
    },
    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",
      Project_Enquiry:"Project",
      Project_no: "#2458",
      client_name: "Mark smith",
      client_id: "12345",
      Vendor:"Vendor",
      Type: "Material",
      Department: "Civil",
    
     
      action: () => {
        navigate("/purchase/add-other");
      },
    },

    {
      date: "19 Nov, 2023",
      Enquiry_Number: "#245879",
      Project_name: "Project one",
      Project_Enquiry:"Project",
      Project_no: "#2458",
      client_name: "Mark smith",
      client_id: "12345",
      Vendor:"Vendor",
      Type: "Material",
      Department: "Civil",
    
     
      action: () => {},
    },
  ];

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal-new"
        show={showDocumentmodal}
        onHide={handleCloseDocumentmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleCloseDocumentmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="Quotation List" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>PR No. </th>
                  <th>Project/Enquiry </th>
                  <th>Project Name</th>
                  <th>Client</th>
                  <th>Subcon/Vendor</th>

                  <th>Type</th>
                  <th>Department</th>
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <span>{item.date}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Enquiry_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Project_Enquiry}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Project_name}</p>
                        <span>#{item.Project_no}</span>
                      </div>
                    </td>
                     
                    <td>
                      <div className="user-info">
                        <p>{item.client_name}</p>
                        <span>#{item.client_id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Vendor}</span>
                      </div>
                    </td>
                   

                    <td>
                      <div className="user-info">
                        <span>{item.Type}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Department}</span>
                      </div>
                    </td>

                    

                    <td>
                      <div className="btn-dis-new">
                        <button
                          className="common-button"
                          onClick={item?.action}
                        >
                          Select
                        </button>
                        <button
                          className="btn-viwe-icon"
                          // onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PurchaseQuoteSelectModal;
