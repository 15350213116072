import React, { useContext } from "react";
import { GlobalContext } from "../../../../context";

const TotalRfqChartUtils = () => {
  const { themeMode } = useContext(GlobalContext);
  return {
    themeMode,
  };
};

export default TotalRfqChartUtils;
