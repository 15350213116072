import React from "react";
import "./companydocumentsdata.scss";
import { IconArrowRight } from "../../../../icon/icons";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";

const data = [
  {
    doc_name: "Doc name",
    doc_id: "2587",
    type: "Emirates ID",
    add_date: "13 Nov, 2023",
    expiry_date: "15 Dec, 2023",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
  {
    doc_name: "Doc name",
    doc_id: "2587",
    type: "Emirates ID",
    add_date: "13 Nov, 2023",
    expiry_date: "15 Dec, 2023",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
  {
    doc_name: "Doc name",
    doc_id: "2587",
    type: "Emirates ID",
    add_date: "13 Nov, 2023",
    expiry_date: "15 Dec, 2023",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
  {
    doc_name: "Doc name",
    doc_id: "2587",
    type: "Emirates ID",
    add_date: "13 Nov, 2023",
    expiry_date: "15 Dec, 2023",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
  {
    doc_name: "Doc name",
    doc_id: "2587",
    type: "Emirates ID",
    add_date: "13 Nov, 2023",
    expiry_date: "15 Dec, 2023",
    Added_by_name: "Ella Marie",
    Added_by_id: "245879",
  },
];

const CompanyDocumentsData = () => {
  return (
    <>
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> recently added Company documents </h2>
          </div>
          <div className="view-all">
            <NavLink>
              View all{" "}
              <span>
                {" "}
                <IconArrowRight />{" "}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="all-table-data">
          <div className="table-data-main table-all-inner">
            <Table responsive>
              <thead>
                <tr>
                  <th>Document <br/> name & ID</th>
                  <th>Type</th>
                  <th>Addition Date </th>
                  <th>Expiry date </th>
                  <th>Added by</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <p>{item.doc_name}</p>
                        <span>#{item.doc_id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.type}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.add_date}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.expiry_date}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Added_by_name}</p>
                        <span>#{item.Added_by_id}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDocumentsData;
