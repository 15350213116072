import React, { useState } from "react";

const AgentTableDataUtils = () => {
  const [showProfilemodal, setShowProfileModal] = useState(false);
  const handleCloseProfilemodal = () => setShowProfileModal(false);
  const handleShowProfilemodal = () => setShowProfileModal(true);
  return {
    showProfilemodal,
    setShowProfileModal,
    handleCloseProfilemodal,
    handleShowProfilemodal,
  };
};

export default AgentTableDataUtils;
