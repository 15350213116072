import React, { useState } from 'react'
import "./purchasereturnstable.scss"
import CommonPaginate from '../../../../layout/commonpaginate'
import { IconEyes } from '../../../../../icon/icons'
import TableActionButton from '../../../../layout/tableactionbutton'
import Table from "react-bootstrap/Table";
import Price from '../../../../layout/price'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const data = [
    {
      date: "19 Nov, 2023",
      Return_number: "245879",
      INV_number: "#245879",
      INV_date: "3 Aug, 2024",
      project_name: "Project one",
      project_id: "#2458",
      cl_name: "Mark Smith",
      cl_id: "#2458",
      ve_name: "John Doe",
      ve_id: "Subcontractor",
      Department: "Civil",
      Type: "Material",
      Approval: "Approved",
      price_syb: "$",
    price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      Return_number: "245879",
      INV_number: "#245879",
      INV_date: "3 Aug, 2024",
      project_name: "Project one",
      project_id: "#2458",
      cl_name: "Mark Smith",
      cl_id: "#2458",
      ve_name: "John Doe",
      ve_id: "Subcontractor",
      Department: "Civil",
      Type: "Material",
      Approval: "Approved",
      price_syb: "$",
    price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      Return_number: "245879",
      INV_number: "#245879",
      INV_date: "3 Aug, 2024",
      project_name: "Project one",
      project_id: "#2458",
      cl_name: "Mark Smith",
      cl_id: "#2458",
      ve_name: "John Doe",
      ve_id: "Subcontractor",
      Department: "Civil",
      Type: "Material",
      Approval: "Approved",
      price_syb: "$",
    price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      Return_number: "245879",
      INV_number: "#245879",
      INV_date: "3 Aug, 2024",
      project_name: "Project one",
      project_id: "#2458",
      cl_name: "Mark Smith",
      cl_id: "#2458",
      ve_name: "John Doe",
      ve_id: "Subcontractor",
      Department: "Civil",
      Type: "Material",
      Approval: "Approved",
      price_syb: "$",
    price_value: "100,000.00",
    },
    {
      date: "19 Nov, 2023",
      Return_number: "245879",
      INV_number: "#245879",
      INV_date: "3 Aug, 2024",
      project_name: "Project one",
      project_id: "#2458",
      cl_name: "Mark Smith",
      cl_id: "#2458",
      ve_name: "John Doe",
      ve_id: "Subcontractor",
      Department: "Civil",
      Type: "Material",
      Approval: "Approved",
      price_syb: "$",
    price_value: "100,000.00",
    },
    
  ];

const PurchaseReturnsTable = () => {
 // Pagination all status
 const [page, setPage] = React.useState(2);
 const [rowsPerPage, setRowsPerPage] = React.useState(10);
 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };
 const handleChangeRowsPerPage = (event) => {
   setRowsPerPage(parseInt(event.target.value, 10));
   setPage(0);
 };
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(5);
 const indexOfLastPost = currentPage * itemsPerPage;
 const indexOfFirstPost = indexOfLastPost - itemsPerPage;
 const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
 const paginate = ({ selected }) => {
   setCurrentPage(selected + 1);
 };


    const options = [
       
        { 
          title: "Edit",
          icon: <EditIcon />,
          action: () => {
            // console.log("Vishal");
          },
        },
        {
          title: "Delete",
          icon: <DeleteIcon />,
          action: () => {
            // console.log("Vishal");
          },
        },
      ];

  return (
    <>
    <div className="table-data-main Estimation-table-new">
      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Return number</th>
            <th>ReF. INV Date/No</th>
            <th>Project</th>

            <th>Client</th>

            <th>Suncorn/Vendor</th>

            <th>Department</th>
            <th>Type</th>
            <th>Amount</th>
            {/* <th>View</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="user-info">
                  <span>{item.date}</span>
                </div>
              </td>
              <td>
                <div className="user-info">
                  <span>#{item.Return_number}</span>
                </div>
              </td>
              <td>
                <div className="user-info">
                  <p>{item.INV_number}</p>
                  <span>{item.INV_date}</span>
                </div>
              </td>

              <td>
                <div className="user-info">
                  <p>{item.project_name}</p>
                  <span>{item.project_id}</span>
                </div>
              </td>

              <td>
                <div className="user-info">
                  <p>{item.cl_name}</p>
                  <span>{item.cl_id}</span>
                </div>
              </td>

              <td>
                <div className="user-info">
                  <p>{item.ve_name}</p>
                  <span>{item.ve_id}</span>
                </div>
              </td>

              <td>
                <div className="user-info">
                  <span>{item.Department}</span>
                </div>
              </td>

              <td>
                <div className="user-info">
                  <span>{item.Type}</span>
                </div>
              </td>

              <td>
                  <div className="price-option-table">
                    <div className="price-all-main">
                      <Price
                        price_main={item.price_value}
                        price_symbol={item.price_syb}
                      />
                    </div>
                  </div>
                  <div className="user-info">
                   
                  </div>
                </td>

              <td>
                <div className="table-inner-grid-dta">
                  <div className="btn-table-new">
                    {/* <div className="btn-dis-new btn-create-quote">
                      <button className="common-button">Create Quote</button>
                    </div> */}
                    <div className="btn-dis-new">
                      <button
                        className="btn-viwe-icon"
                        // onClick={() => handleShowPurchaseModal()}
                      >
                        {/* <IconEyes /> */}
                        View More
                      </button>
                      <TableActionButton options={options} isOpen={true} />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>

    <div className="paginate_all_common">
        <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
      </div>

   
  </>
  )
}

export default PurchaseReturnsTable