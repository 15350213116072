import React from "react";
import { SubconForm, TitleHeader } from "../../../../components";

const AddSubcon = () => {
  const breadcrumbs = [
    { label: "Subcontractor", link: "" },
    { label: "Subcontractor list", link: "" },
    { label: "Add new Subcontractor", link: "" },
  ];
  return (
    <>
      <TitleHeader heading="Add New Subcontractor" breadcrumbs={breadcrumbs} />

      <SubconForm />
    </>
  );
};

export default AddSubcon;
