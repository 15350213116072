import React, { useState } from "react";
import { images } from "../../../utils/customFn";
import { NavLink } from "react-router-dom";
import { Sidebar } from "react-pro-sidebar";
import "./sidebar.scss";
import UseSidebar from "./sidebarUtils";
import MenuItems from "../menuitems";
import { useClickAway } from "@uidotdev/usehooks";

const SidebarDashboard = () => {
  const { IsCollapsed, setIsCollapsed } = UseSidebar();

  

  return (
    <div className="sidebar_profile admin-sidebar-main">
      <div style={{ display: "flex", height: "100vh" }}>
        <Sidebar collapsed={IsCollapsed} className="info-sidebar">
          <div className="sidebar_logo">
            <NavLink to="/">
              <img
                className="sidebar_logo_img"
                src={
                  IsCollapsed ? images["logo_small.png"] : images["logo.png"]
                }
                alt="logo"
                loading="lazy"
              />
            </NavLink>
          </div>
          <MenuItems collapsed={IsCollapsed} />
        </Sidebar>
        <main
          className={`${
            IsCollapsed ? "icon_rounded" : "icon_square"
          } btn_close_sidebar`}
        >
          <button
            onClick={() => {
              setIsCollapsed(!IsCollapsed);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="16"
              viewBox="0 0 8 16"
              fill="none"
            >
              <path
                d="M6.87686 15.0802C6.71852 15.0802 6.56019 15.0219 6.43519 14.8969L1.00186 9.46358C0.118522 8.58024 0.118522 7.13024 1.00186 6.24691L6.43519 0.813574C6.67686 0.571908 7.07686 0.571908 7.31852 0.813574C7.56019 1.05524 7.56019 1.45524 7.31852 1.69691L1.88519 7.13024C1.48519 7.53024 1.48519 8.18024 1.88519 8.58024L7.31852 14.0136C7.56019 14.2552 7.56019 14.6552 7.31852 14.8969C7.19352 15.0136 7.03519 15.0802 6.87686 15.0802Z"
                fill="white"
              />
            </svg>
          </button>
        </main>
      </div>
    </div>
  );
};

export default SidebarDashboard;
