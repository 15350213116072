import React, { useState } from "react";
import "./poregisterselectmodal.scss";
import { IconClose, IconEyes } from "../../../../../icon/icons";
import CommonFilters from "../../../../layout/commonfilters";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Price from "../../../../layout/price";
import CommonPaginate from "../../../../layout/commonpaginate";

const data = [
  {
    date: "19 Nov, 2023",
    Quote_Number: "#245879",
    Project_Enquiry:"Project",
    pro_name: "Project Name",
    pro_no: "#1458",
    pro_name_new: "Name Here",
    pro_no_new: "#457841",
    client_name: "Mark Smith",
    Subcontractor_Vendor: "Subcontractor",
    Type: "Machinery",
    Department: "Civil",

    
  },

 
];


const PoRegisterSelectModal = (props) => {
  

  const { showPoListmodal, handleClosePoListmodal } = props;

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const navigate = useNavigate();
  const handlePageChange = () => {
    navigate("/purchase/add-poregister");
  };

  return (
    <>
      <Modal
        className="modal-all-data modal-big-data project-list-modal-new"
        show={showPoListmodal}
        onHide={handleClosePoListmodal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={handleClosePoListmodal}>
              <IconClose />
            </button>
          </div>

          <CommonFilters heading="Quotation List" />

          <div className="table-data-main table-old-data-main">
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Quote Number </th>
                  <th>Project/Enquiry</th>
                  <th>Name</th>
                  <th>Project Title  </th>
                  <th>Client  </th>
                  <th>Subcontractor / Vendor  </th>
                  <th>Type  </th>
                  <th>Department  </th>
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <span>{item.date}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Quote_Number}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Project_Enquiry}</span>
                      </div>
                    </td>



                    <td>
                      <div className="user-info">
                        <p>{item.pro_name}</p>
                        <span>{item.pro_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.pro_name_new}</p>
                        <span>{item.pro_no_new}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.client_name}</p>
                      
                      </div>
                    </td>


                    <td>
                      <div className="user-info">
                        <span>{item.Subcontractor_Vendor}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Type}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <span>{item.Department}</span>
                      </div>
                    </td>

                   

                    <td>
                      <div className="btn-dis-new">
                        <button
                          className="common-button"
                          onClick={() => {
                            handlePageChange();
                          }}
                        >
                          Select
                        </button>
                       
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="paginate_all_common">
          <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PoRegisterSelectModal;
