import React from "react";
import "./quoteforother.scss";
import { IconDocs } from "../../../../../icon/icons";
const QuoteForOtherForm = () => {
  return (
    <>
      <div className="form-data-main">
        <div className="page-card-bg">
          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Date</label>
              <input placeholder="#2546221" />
            </div>

            <div className="input-main-data">
              <label>24 Nov,2023</label>
              <input placeholder="#457841" />
            </div> 

            <div className="input-main-data">
            
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>Discount</label>
              <input placeholder="100,000.00" />
            </div>

            <div className="input-main-data">
              <label>Sub Total Amount (Excl. VAT)</label>
              <input placeholder="100,000.00" />
            </div>

            <div className="input-main-data">
            <div className="input-check">
                {/* <input type="checkbox" />
                <label>VAT Applicable</label> */}
                 <div class="remember_me_input">
                  <input type="checkbox" id="remember" />
                  <label for="remember" class="checkbox-label"></label>
                  <span>VAT Applicable</span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-all-grid">
            <div className="input-main-data">
              <label>VAT Amount (5%)</label>
              <input placeholder="5,000.00" />
            </div>

            <div className="input-main-data">
              <label>Total Amount (Incl. VAT)</label>
              <input placeholder="105,000.00" />
            </div>

            <div className="input-main-data">
            
            </div>
            
          </div>


          <div className="title-button-data dis-gird-btn-new">
            <p>Add Attachment</p>
            <div className="btn-inner-flex">
              <button className="common-button">
                <span>
                  <IconDocs />
                </span>{" "}
                upload  Quote (PDF)
              </button>

              {/* <button className="common-button">
                <span>
                  <IconDocs />
                </span>{" "}
                Quote (PDF)
              </button> */}
            </div>
          </div>
        </div>

        <div className="btn-form-send">
          <button className="common-button">Submit</button>

          <button className="common-button cancel-btn">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default QuoteForOtherForm;
