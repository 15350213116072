import React from "react";
import { ClientForm, TitleHeader } from "../../../../components";

const AddnewClient = () => {
  const breadcrumbs = [
    { label: "Marketing", link: "" },
    { label: "Client database", link: "" },
    { label: "Add new Client", link: "" },
  ];

  return (
    <>
      <TitleHeader heading="Add New Client" breadcrumbs={breadcrumbs} />
      <ClientForm />
    </>
  );
};

export default AddnewClient;
