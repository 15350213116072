import React, { useState } from "react";
import "./purchaserqflist.scss";
import {
  CommonFilters,
  PurchaeSelectModal,
  PurchaseRqfListTable,
  TitleHeader,
} from "../../../../components";
import { IconDownloadArrow } from "../../../../icon/icons";
const PurchaseRqfList = () => {
  const breadcrumbs = [
    { label: "Purhcase", link: "" },
    { label: "RFQ", link: "" },
  ];

  const [showDocumentmodal, setShowDocumentModal] = useState(false);
  const handleCloseDocumentmodal = () => setShowDocumentModal(false);
  const handleShowDocumentmodal = () => setShowDocumentModal(true);
  const handleShowDocumentModal = () => {
    handleShowDocumentmodal(true);
  };

  const options = [
    {
      title: "For Enquiry",
      icon: "",
      action: () => {
        handleShowDocumentModal();
      },
    },
    {
      title: "For Projects",
      icon: "",
      action: () => {
        handleShowDocumentModal();
      },
    },
  ];

  return (
    <>
      <TitleHeader
        heading="RFQ"
        breadcrumbs={breadcrumbs}
        isDropbtn={true}
        isDropOptions={{
          options: options,
          isOpen: true,
          isDropIcon: false,
        }}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="RFQ" />
        <PurchaseRqfListTable />
      </div>

      <PurchaeSelectModal
        showDocumentmodal={showDocumentmodal}
        handleCloseDocumentmodal={handleCloseDocumentmodal}
      />
    </>
  );
};

export default PurchaseRqfList;
