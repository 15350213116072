import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RouteArr from "../configuration/getRoutes";
import Main from "../pages/main";
import { Login } from "../pages";
// import FrontApp from "../pages/Front/FrontApp";
// import VendorApp from "../pages/Vendor/VendorApp";
// import vendorRoutes from "../hooks/vendorRoutes";

const router = createBrowserRouter([
  {
    name: "Login",
    path: "/",
    key: "login",
    route: "/",
    element: <Login />,
  },
  {
    path: "/",
    element: <Main />,
    children: RouteArr,
  },
  // {
  //   path: "/vendor",
  //   element: <VendorApp />,
  //   children: vendorRoutes,
  // },
]);
const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
