import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButtonDots } from "../../../icon/icons";
import "./tableactionbutton.scss"

const TableActionButton = ({
  options = [],
  isOpen = false,
  isDropIcon = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (isOpen) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCLick = (fun) => {
    fun();
    handleClose();
  };
  return (
    <div className="btn-action-table">
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        className="btn-dots-new"
        // endIcon={<KeyboardArrowDownIcon />}
      >
        {isDropIcon ? isDropIcon : <IconButtonDots />}
      </Button>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.length > 0
          ? options.map((value, index) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleCLick(value.action);
                  }}
                  disableRipple
                  key={index}
                >
                  {/* <EditIcon /> */}
                  {value?.icon ? value?.icon : ""}
                  {value?.title}
                </MenuItem>
              );
            })
          : ""}
      </Menu>
    </div>
  );
};

export default TableActionButton;
