import React from "react";
import "./addnewvendor.scss";
import { TitleHeader, VendorForm } from "../../../../components";
const AddVendor = () => {
  const breadcrumbs = [
    { label: "Vendor", link: "" },
    { label: "Vendor list", link: "" },
    { label: "Add new vendor", link: "" },
  ];
  return (
    <>
      <TitleHeader heading="Vendor list" breadcrumbs={breadcrumbs} />
      <VendorForm />
    </>
  );
};

export default AddVendor;
