import React, { useState } from 'react'
import "./addpurchaserequest.scss"
import { AddPurchaseForm, TitleHeader } from '../../../../components'
import { useNavigate } from 'react-router-dom';

const AddPurchaseRequest = () => {


  const breadcrumbs = [
    { label: "Vehicles", link: "" },
    { label: "Add New Vehicle", link: "" },
  ];
  const navigate = useNavigate();

  // const handleAddClientClick = () => {
  //   // navigate("");
  // };

  // const [showBoqmodal, setShowBoqModal] = useState(false);
  // const handleCloseBoqmodal = () => setShowBoqModal(false);
  // const handleShowBoqmodal = () => setShowBoqModal(true);

  // const handleShowBoqModal = () => {
  //   handleShowBoqmodal(true);
  // };

  return (
    <>
     <TitleHeader
        heading="Add New Purchase Request"
        breadcrumbs={breadcrumbs}
        buttonText="View BOQ"
        // onButtonClick={handleShowBoqModal}
      />
    

     <AddPurchaseForm/>
  

    </>
  )
}

export default AddPurchaseRequest 