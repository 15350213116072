import React from "react";
import "./dashexpiringdocuments.scss";
import { NavLink } from "react-router-dom";
import { IconArrowRight } from "../../../../icon/icons";
import Table from "react-bootstrap/Table";

const data = [
  {
    type: "Vendor",
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    documents_type: "trade lisc",
  },
  {
    type: "Vendor",
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    documents_type: "trade lisc",
  },
  {
    type: "Vendor",
    id: 245879,
    name: "Mark Smith",
    role: "Agent",
    date: "23,jun 2024",
    documents_type: "trade lisc",
  },
];
const DashExpiringDocuments = () => {
  return (
    <>
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading">
              Vendors / Subcon - Expiring Documents{" "}
            </h2>
          </div>
          <div className="view-all">
            <NavLink>
              View all{" "}
              <span>
                {" "}
                <IconArrowRight />{" "}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="all-table-data">
          <div className="table-data-main table-all-inner">
            <Table responsive>
              <thead>
                <tr>
                  <th> Type</th>
                  <th> Name</th>
                  <th>Documents Type </th>
                  <th>Expiry date</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <p>{item.type}</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-info">
                        <p>{item.name}</p>
                        <span>#{item.id}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.documents_type}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.date}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashExpiringDocuments;
