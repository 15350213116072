import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context";

const ThemeToggleUtils = () => {
  const { size, setSize, setThemeMode, price, setPrice } =
    useContext(GlobalContext);

  const [isOn, setIsOn] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });

  const toggleSwitch = () => {
    const newTheme = !isOn ? "dark" : "light";
    setIsOn(!isOn);
    setThemeMode(newTheme);
    document.body.classList.toggle("dark-theme", !isOn);
    localStorage.setItem("theme", newTheme);
  };

  // useEffect(() => {
  //   const savedTheme = localStorage.getItem("theme");
  //   console.log("savedTheme", savedTheme);

  //   if (savedTheme === "dark") {
  //     document.body.classList.add("dark-theme");
  //   } else {
  //     document.body.classList.remove("dark-theme");
  //   }
  // }, []);

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const togglePrice = () => {
    setPrice((prev) => !prev);
    localStorage.setItem("Isprice", (prev) => !prev);
  };

  return {
    isOn,
    toggleSwitch,
    size,
    handleSizeChange,
    price,
    setPrice,
    togglePrice,
  };
};

export default ThemeToggleUtils;
