import React from "react";
import "./pricelist.scss";
import { CommonFilters, PriceTable, TitleHeader } from "../../../components";
import { Autocomplete, TextField } from "@mui/material";
const PriceList = () => {
  const breadcrumbs = [
    { label: "Lorem ipsum dolor si amet welcome back Jasmin.", link: "" },
  ];

  const TypesList = [
    { _id: "1", title: "Demo-1" },
    { _id: "2", title: "Demo-2" },
    { _id: "3", title: "Demo-3" },
    { _id: "4", title: "Demo-4" },
    // Add more property types as needed
  ];

  const selectedType = { _id: "2", title: "Demo-2" };
  return (
    <>
      <TitleHeader heading="Price List" breadcrumbs={breadcrumbs} />

      <div className="page-mid-data-filter">
        <div className="select-department">
        <div className="input-main-data">
            <label>Select Department</label>
            <Autocomplete
              fullWidth
              className="automcomplete_select_info"
              autoComplete={false}
              id="free-solo-demo"
              options={TypesList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Type" />
              )}
              value={selectedType}
              onChange={(e, value) => {
                console.log("Selected Value:", value);
              }}
            />

            {/* <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select"
                // onChange={handleChange}
                value={10}
              >
                <MenuItem value={10}>- Select Option -</MenuItem>
                <MenuItem value={20}>Select</MenuItem>
                <MenuItem value={30}>Select</MenuItem>
              </Select>
            </FormControl> */}
          </div>
        </div>
        <div className="show-date-bottom">
          <span>Last updated:30/09/2024</span>
        </div>
      </div>  


      <div className="page-card-bg table-data-new">
        <CommonFilters heading="List" />
        <PriceTable/>
      </div>



    </>
  );
};

export default PriceList;
