import React from 'react'
import "./purchasereturns.scss"
import { CommonFilters, PurchaseReturnsTable, TitleHeader } from '../../../components'
import { useNavigate } from 'react-router-dom';
const PurchaseReturns = () => {

    const breadcrumbs = [
        { label: "Purchase", link: "" },
        { label: " Purchase Return", link: "" }
    ];
 
     

  return (
   <>
     <TitleHeader
        heading="Purchase Return"
        breadcrumbs={breadcrumbs}
      />

        <div className="page-card-bg table-data-new">
        <CommonFilters heading="Purchase Returns" />
        <PurchaseReturnsTable/>
      </div>
     
   </>
  )
}

export default PurchaseReturns 