import React from "react";
import "./infoboxs.scss";
import {
  IconArrowDown,
  IconArrowUp,
  IconATotalProject,
  IconFinance,
  IconFinishedTask,
  IconIncidents,
} from "../../../icon/icons";
import Price from "../../layout/price";

const data = [
  {
    icon: <IconATotalProject />,
    iconClass: "icon-green",
    label: "Total Project",
    price_syb: "",
    price_value: "6,784",

    percentage: "35%",
    arrowIcon: <IconArrowUp />,
    textColor: "text-green",
    additionalText: "+1$50 Today",
  },
  {
    icon: <IconFinance />,
    iconClass: "icon-blue",
    label: "Financials",
    price_syb: "$",
    price_value: "1,920",
    percentage: "10%",
    arrowIcon: <IconArrowDown />,
    textColor: "text-red",
    additionalText: "+1$50 Today",
  },
  {
    icon: <IconFinishedTask />,
    iconClass: "icon-sky-blue",
    label: "Finished Task",
    price_syb: "",
    price_value: "4,412",
    percentage: "10%",
    arrowIcon: <IconArrowUp />,
    textColor: "text-green",
    additionalText: "+1$50 Today",
  },
  {
    icon: <IconIncidents />,
    iconClass: "icon-red",
    label: "Incidents",

    price_syb: "",
    price_value: "0",

    percentage: "0%",
    arrowIcon: <IconArrowDown />,
    textColor: "text-red",
    additionalText: "0 Today",
  },
];

const InfoBoxs = () => {
 
  return (
    <div className="info-boxs-main-components">
      <div className="flex-data-box">
        {data.map((item, index) => (
          <div className="card-data-main" key={index}>
            <div className={`icon-image ${item.iconClass}`}>{item.icon}</div>
            <div className="text-data-new">
              <span>{item.label}</span>

              <div className="price-all-main">
                <Price
                  price_main={item.price_value}
                  price_symbol={item.price_syb}
                />
              </div>

              <div className="btm-text-small">
                <h2>
                  <span className={item.textColor}>
                    {item.arrowIcon}
                    {item.percentage}
                  </span>
                  {item.additionalText}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBoxs;
