import React, { useState } from "react";

const PoRegisterListTableUtils = () => {
  const [showItemsmodal, setShowItemsModal] = useState(false);
  const handleCloseItemsmodal = () => setShowItemsModal(false);
  const handleShowItemsmodal = () => setShowItemsModal(true);

  return {
    showItemsmodal,
    setShowItemsModal,
    handleCloseItemsmodal,
    handleShowItemsmodal,
  };
};

export default PoRegisterListTableUtils;
