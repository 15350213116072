import React, { useState } from 'react'
import "./quoteforenquiry.scss"
import {  QuoteForEnquiryForm, TitleHeader } from '../../../../../components'
import { useNavigate } from 'react-router-dom';


const QuoteForOther = () => {

  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Vehicles", link: "" },
    { label: "Add New Vehicle", link: "" },
  ];
 
  const handleAddClientClick = () => {
    // navigate("/subcon-vendor/add-subcon");
  };

  return (
    <>
     <TitleHeader
        heading="Add New Quotations"
        breadcrumbs={breadcrumbs}
        buttonText="View BOQ"
        onButtonClick={handleAddClientClick}
      />
    

     <QuoteForEnquiryForm/>
  

    </>
  )
}

export default QuoteForOther 