import React from "react";
import "./dashboard.scss";
import {
  NewenQuiries,
  RecentlyAddedClients,
  TitleHeader,
  TopAgents,
  TotalClients,
} from "../../../components";

const MarketingDashboard = () => {
  const breadcrumbs = [{ label: "Dashboard", link: "" }];

  return (
    <>
      <TitleHeader heading="Dashboard" breadcrumbs={breadcrumbs} />

      <div className="dashboard-data-top">
        <div className="flex-tiles-grid">
          <div className="dashboard-total-value gird-width-box ">
            <TotalClients />
          </div>
          <div className="dashboard-all-table gird-width-box">
            <RecentlyAddedClients />
          </div>
          <div className="dashboard-all-table gird-width-box">
            <TopAgents />
          </div>
          <div className="dashboard-all-chart gird-width-box">
            <NewenQuiries />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingDashboard;
