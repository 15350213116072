import React, { useState } from 'react'
import "./totalrfqchart.scss"
import TotalRfqChartUtils from './totalrfqchartUtils';

import Chart from "react-apexcharts";
import { IconCalendarLight } from '../../../../icon/icons';
import FlatpickrAll from '../../../layout/flatpickrall';
const TotalRfqChart = () => {
    const { themeMode } = TotalRfqChartUtils();
    const [chartData, setChartData] = useState({
      series: [
        {
          name: "RFQ",
          data: [10, 20, 15, 25, 20, 18, 25],
        },
        {
          name: "Total Projects", // Hidden series
          data: [0,15,10, 22, 18, 15, 20, 40],
        },
       
      ],
      options: {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          text: "Total Clients",
          align: "left",
        },
        colors: ["#F3C619", "#01265D", ], // Use dynamic background color for the second series
        xaxis: {
          categories: [
            "15 Jun",
            "20 Jun",
            "25 Jun",
            "30 Jun",
            "1 Jul",
            "5 Jul",
            "10 Jul",
            "15 Jul",
          ],
          labels: {
            style: {
              colors: "var(--btn-text)",
            },
          },
        },
        yaxis: {
          min: 5,
          max: 30,
          tickAmount: 5,
          labels: {
            style: {
              colors: "var(--btn-text)",
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
          labels: {
            colors: "var(--btn-text)",
          },
          markers: {
            shape: 'line',  // Change shape to 'line'
            width: 80, // Line width
            height: 8, // Line height (optional)
          },
           
        },
      },
    }); 



  return (
    <>
    <div
      className={`page-card-bg ${
        themeMode === "dark" ? "dark-mode" : "light-mode"
      }`}
    >
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> RFQ & total projects  </h2>
        </div>
        <div className="date-all-btn">
        <FlatpickrAll/>
        </div>
      </div>

      <div className="chart-new-data">
        <Chart
          key={JSON.stringify(chartData)}
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      </div>
    </div>
  </>
  )
}

export default TotalRfqChart
