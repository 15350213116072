import React, { useState } from "react";
import "./purchaserqflistquotations.scss";
import {
  CommonFilters,
  PurchaseQuotationsTable,
  PurchaseQuoteSelectModal,
  TitleHeader,
} from "../../../../components";
import { IconDownloadArrow } from "../../../../icon/icons";

const PurchaseRqfListQuotations = () => {


  
  const breadcrumbs = [
    { label: "Quotations", link: "" },
  ];

  const [showDocumentmodal, setShowDocumentModal] = useState(false);
  const handleCloseDocumentmodal = () => setShowDocumentModal(false);
  const handleShowDocumentmodal = () => setShowDocumentModal(true);
  const handleShowDocumentModal = () => {
    handleShowDocumentmodal(true);
  };

  const options = [
    {
      title: "For Enquiry / Projects",  
      icon: "",
      action: () => { 
        handleShowDocumentModal();
      },
    },
    {
      title: "For Others",
      icon: "",
      action: () => {
        handleShowDocumentModal();
      },
    },
    
  ];

  return (
    <>
      <TitleHeader 
        heading="Quotations "
        breadcrumbs={breadcrumbs}
        isDropbtn={true}
        isDropOptions={{
          options: options,
          isOpen: true,
          isDropIcon: false,
        }}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Quotations List" />
        <PurchaseQuotationsTable/>
      </div>

      <PurchaseQuoteSelectModal
        showDocumentmodal={showDocumentmodal}
        handleCloseDocumentmodal={handleCloseDocumentmodal}
      />
    </>
  );
};

export default PurchaseRqfListQuotations;
