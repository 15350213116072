import React, { useState } from "react";

const DetailsModalUtils = () => {
  const [emiratesIdFile, setEmiratesIdFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [visaFile, setVisaFile] = useState(null);

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };
  return {
    emiratesIdFile,
    setEmiratesIdFile,
    passportFile,
    setPassportFile,
    visaFile,
    setVisaFile,
    handleFileChange,
  };
};

export default DetailsModalUtils;
