import React from "react";
import { CommonFilters, TableData, TitleHeader } from "../../../../components";
import { useNavigate } from "react-router-dom";
import "./clientdatabase.scss";

const ClientDatabase = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Marketing", link: "" },
    { label: "Client database", link: "" },
    { label: "Clients lists", link: "" },
  ];

  const handleAddClientClick = () => {
    navigate("/marketing/client-database/add-client");
  };

  return (
    <>

    
      <TitleHeader
        heading="Client Lists"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add Client"
        onButtonClick={handleAddClientClick}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Client Lists"  isDatebtn={false}/>
        <TableData />
      </div>
    </>
  );
};

export default ClientDatabase;
