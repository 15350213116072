import React, { useState } from "react";
import "./polistdata.scss";
import {
  CommonFilters,
  PolistSelectlistModal,
  PolistTable,
  TitleHeader,
} from "../../../../components";

const PolistData = () => {
  const breadcrumbs = [{ label: "PO List ", link: "" }];

  const [showPoListmodal, setShowPoListModal] = useState(false);
  const handleClosePoListmodal = () => setShowPoListModal(false);
  const handleShowPoListmodal = () => setShowPoListModal(true);
  const handleShowPoListModal = () => {
    handleShowPoListmodal(true);
  };

  return (
    <>
      <TitleHeader
        heading="PO List"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add New"
        onButtonClick={handleShowPoListModal}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="PO List" />
        <PolistTable />
      </div>

      <PolistSelectlistModal
        showPoListmodal={showPoListmodal}
        handleClosePoListmodal={handleClosePoListmodal}
      />
    </>
  );
};

export default PolistData;
