import React from "react";
import "./login.scss";
import { NavLink } from "react-router-dom";
import { images } from "../../utils/customFn";
import { IconPassword } from "../../icon/icons";

const Login = () => {
  return (
    <div className="login_main_page">
      <div className="page_side_pd">
        <div className="page_logo_main">
          <h2>
            Str<span>u</span>ct
          </h2>
        </div>

        <div className="page_flex_data">
          <div className="page_form_data">
            <div className="form-inner-data">
              <div className="page_heading">
                <h2 className="heading_main">Log in </h2>
              </div>

              <div className="all_input">
                <label>Email/Mobile</label>
                <input type="text" placeholder="Brooklyn Simmons" />
              </div>

              <div className="all_input">
                <label>Password</label>
                <div className="password-input-main">
                  <input type="text" placeholder="Enter password" />
                  <div className="btn-icon">
                    <button>
                      <IconPassword />
                    </button>
                  </div>
                </div>
              </div>

              <div className="input_flex">
                {/* <div className="remember_me_input">
                  <input type="checkbox" />
                 
                  <label>Remember Me</label>
                </div> */}

                <div class="remember_me_input">
                  <input type="checkbox" id="remember" />
                  <label for="remember" class="checkbox-label"></label>
                  <span>Remember Me</span>
                </div>

                <div className="btn_forget">
                  <NavLink>Forget Password? </NavLink>
                </div>
              </div>

              <div className="btn_main_login">
                <NavLink to="/main-dashboard" className="btn-comman-main">
                  Log In
                </NavLink>
              </div>
            </div>
          </div>

          <div className="page_image_sider">
            <img src={images["Login_Light_new.png"]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
