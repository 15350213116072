import React from "react";
import "./requestapprovalmodal.scss";
import { IconClose } from "../../../../../icon/icons";
import Modal from "react-bootstrap/Modal";

const RequestApprovalModal = (props) => {
  const { showRequestmodal, handleCloseRequestmodal } = props;
  return (
    <Modal
      className="modal-all-data modal-request-approval"
      show={showRequestmodal}
      onHide={handleCloseRequestmodal}
      centered
    >
      <Modal.Body>
        <div className="closemodal_btn">
          <button onClick={handleCloseRequestmodal}>
            <IconClose />
          </button>
        </div>

        <div className="modal-inner-data-new">
          <div className="heading-main">
            <h2 className="common-heading">Request Approval</h2>
          </div>

          <div className="input-main-data">
            <label>User & Number</label>
            <input placeholder="Jenny Wilson" />
          </div>

          <div className="input-main-data">
            <label>Position</label>
            <input placeholder="Manager" />
          </div>

          <div className="btn-form-send">
            <button className="common-button">Submit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestApprovalModal;
