import React from "react";
import "./taball.scss";
import {
  IconArrowDownSmall,
  IconArrowUpSmall,
  IconCreditCard,
  IconDollar,
  IconIncomeMoney,
} from "../../../../icon/icons";
import Price from "../../../layout/price";
import Chart from "react-apexcharts";

const cards = [
  {
    title: "Income",
    amount: "$5000",

    price_syb: "$",
    price_value: "26,000",

    change: "10%",
    icon: <IconIncomeMoney />,
    iconClass: "icon-blue",
    changeClass: "text-green",
    arrowIcon: <IconArrowUpSmall />,
  },
  {
    title: "Expenses",

    amount: "$5000",

    price_syb: "$",
    price_value: "18,000",

    change: "10%",
    icon: <IconCreditCard />,
    iconClass: "icon-org",
    changeClass: "text-red",
    arrowIcon: <IconArrowDownSmall />,
  },
  {
    title: "Profit",

    price_syb: "$",
    price_value: "8,000",

    change: "10%",
    icon: <IconDollar />,
    iconClass: "icon-sky-blu",
    changeClass: "text-red",
    arrowIcon: <IconArrowDownSmall />,
  },
];

const TabAll = () => {
 

  const chartOptions = {
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100, 90, 85, 75, 60, 55],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41, 38, 49, 60, 70, 65],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false, // Disable toolbar
        },
      },
      colors: ["#F3C619", "#ECE9F1"], // Change color of series lines
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          show: true,
          rotate: 0, // Keep the labels horizontal
          style: {
            colors: "var(--btn-text)",
          },
        },
        tickPlacement: "on", // Ensures the ticks align with each label
      },
      yaxis: {
        labels: {
          style: {
            colors: "var(--btn-text)",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "14px",
        fontFamily: "Arial, sans-serif",
        labels: {
          colors: "var(--btn-text)",
        },
      },
    },
  };

  return (
    <div className="tab-all-main-com">
      <div className="card-flex-data">
        <div className="inner-card-new">
          {cards.map((card, index) => (
            <div className="card-gird-new" key={index}>
              <div className={`icon-new ${card.iconClass}`}>{card.icon}</div>
              <div className="text-card-data">
                <h2>{card.title}</h2>

                <div className="price-all-main flex-data">
                  <Price
                    price_main={card.price_value}
                    price_symbol={card.price_syb}
                  />
                  <span className={`text-small ${card.changeClass}`}>
                    {card.change} {card.arrowIcon}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="chart">
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default TabAll;
