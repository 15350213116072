import React from 'react'
import "./poandrfqchart.scss"
import Chart from "react-apexcharts";
const PoAndRfqChart = () => {
            // Define the data for each chart
  const series1 = [20, 30, 40, 10]; // Values for the first chart
  const series2 = [50, 50]; // Values for the second chart

  // Define the options for each chart
  const chartOptions1 = {
    chart: {
      type: "donut",
      width: 300,
    },
    labels: ["MEP", "Civil", "Joinery", "Others"],
    colors: ["#F2C94C", "#002855", "#f3c619bd", "#5B7192"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          expandOnClick: false,
          size: "65%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 200,
          },
        },
      },
      {
        breakpoint: 1136,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: "donut",
      width: 300,
    },
    labels: ["Submitted", "Approved"],
    colors: ["#F2C94C", "#002855", "#f3c619bd", "#5B7192"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          expandOnClick: false,
          size: "65%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 200,
          },
        },
      },
      {
        breakpoint: 1136,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
  };
  return (
    <div className="page-card-bg">
    <div className="flex-top-heading">
      <div className="heading-main">
        <h2 className="common-heading"> PO & RFQ    </h2>
      </div>
      {/* <div className='view-all'>
    <NavLink>View all <span> <IconArrowRight/> </span></NavLink>
  </div>
   */}
    </div>
    <div className="chart-new-main">
      <div className="chart-one-new chart-inner">
        <h3>PO Issued - Department </h3>
        <div className="chart-option-info">
          <Chart
            options={chartOptions1}
            series={series1}
            type="donut"
            width="300"
          />
        </div>
      </div>
      <div className="chart-two-new chart-inner">
        <h3>RFQ - Department</h3>
        <div className="chart-option-info">
          <Chart
            options={chartOptions2}
            series={series2}
            type="donut"
            width="300"
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default PoAndRfqChart
