import React from "react";
import "./documentsnearingexpiry.scss";
import { IconArrowRight } from "../../../../icon/icons";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";

const data = [
  {
    doc_name: "Doc name",
    doc_id: "2587",
    expiry_date: "15 Dec, 2023",
    expiry_day: "3 Day",
    Approval: "Valid",
  },
  {
    doc_name: "Doc name",
    doc_id: "2587",
    expiry_date: "15 Dec, 2023",
    expiry_day: "3 Day",
    Approval: "Valid",
  }, {
    doc_name: "Doc name",
    doc_id: "2587",
    expiry_date: "15 Dec, 2023",
    expiry_day: "3 Day",
    Approval: "Valid",
  }, {
    doc_name: "Doc name",
    doc_id: "2587",
    expiry_date: "15 Dec, 2023",
    expiry_day: "3 Day",
    Approval: "Valid",
  }, {
    doc_name: "Doc name",
    doc_id: "2587",
    expiry_date: "15 Dec, 2023",
    expiry_day: "3 Day",
    Approval: "Valid",
  },
];

const DocumentsNearingExpiryData = () => {
  return (
    <>
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> Documents nearing expiry & expired </h2>
          </div>
          <div className="view-all">
            <NavLink>
              View all{" "}
              <span>
                {" "}
                <IconArrowRight />{" "}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="all-table-data">
          <div className="table-data-main table-all-inner">
            <Table responsive>
              <thead>
                <tr>
                  <th>Document <br/> name & ID</th>
                  <th>Expiry date </th>
                  <th>Days to expiry</th>
                  <th>Status </th>
               
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="user-info">
                        <p>{item.doc_name}</p>
                        <span>#{item.doc_id}</span>
                      </div>
                    </td>

                   

                  

                    <td>
                      <div className="user-info">
                        <p>{item.expiry_date}</p>
                      </div>
                    </td>


                    <td>
                      <div className="user-info">
                        <p>{item.expiry_day}</p>
                      </div>
                    </td>
                   

                    <td>
                  <div className="user-info">
                    <span
                      className={`${
                        item.Approval === "Valid"
                          ? "valid"
                          : item.Approval === "Expired"
                          ? "expired"
                          : "rejected"
                      }`}
                    >
                      {item.Approval}
                    </span>
                  </div>
                </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsNearingExpiryData;
