import React, { useState } from "react";
import "./enquiryestimationtablelist.scss";
import { IconButtonDots, IconClose, IconEyes } from "../../../../../icon/icons";
import ReactPaginate from "react-paginate";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Table from "react-bootstrap/Table";
import EnquiryEstimationTablelistUtils from "./enquiryestimationtablelistUtils";
import EnquiryEstimationProfileModal from "../enquiryestimationprofilemodal";
import TableActionButton from "../../../../layout/tableactionbutton";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const EnquiryEstimationTableList = () => {
  const {
    showProfilemodal,
    setShowProfileModal,
    handleCloseProfilemodal,
    handleShowProfilemodal,
  } = EnquiryEstimationTablelistUtils();

  const data = [
    {
      date: "19 Nov, 2023",
      number: "245879",
      title: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
      Category: "Civil",
      name: "Mark Smith",
      id: "245879",
      Source: "Marketing",
      Agent_name: "Jenny Wilson",
      Agent: "Agent",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      title: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
      Category: "Civil",
      name: "Mark Smith",
      id: "245879",
      Source: "Marketing",
      Agent_name: "Jenny Wilson",
      Agent: "Agent",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      title: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
      Category: "Civil",
      name: "Mark Smith",
      id: "245879",
      Source: "Marketing",
      Agent_name: "Jenny Wilson",
      Agent: "Agent",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      title: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
      Category: "Civil",
      name: "Mark Smith",
      id: "245879",
      Source: "Marketing",
      Agent_name: "Jenny Wilson",
      Agent: "Agent",
    },
    {
      date: "19 Nov, 2023",
      number: "245879",
      title: "Lorem Ipsum Generator. Generate Lorem Ipsum...",
      Category: "Civil",
      name: "Mark Smith",
      id: "245879",
      Source: "Marketing",
      Agent_name: "Jenny Wilson",
      Agent: "Agent",
    },
  ];

  // Profile Drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const options = [
    {
      title: " Create RFQ",
      icon: "",
      action: () => {
        // console.log("Vishal");
      },
    },
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    
  ];

  return (
    <>
      <div className="table-data-main Estimation-table-new">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Enquiry Number</th>
              <th>Enquiry Title</th>
              <th>Project Category</th>
              <th>Client</th>
              <th>Source of Enquiry</th>
              <th>Agent / Employee</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="user-info">
                    <span>{item.date}</span>
                  </div>
                </td>
                <td>
                  <div className="user-type">
                    <span>#{item.number}</span>
                  </div>
                </td>
                <td>
                  <div className="contact-info">
                    <p>{item.title}</p>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.Category}</p>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.name}</p>
                    <span>#{item.id}</span>
                  </div>
                </td>
                <td>
                  <div className="user-info">
                    <p>{item.Source}</p>
                  </div>
                </td>

                <td>
                  <div className="user-info">
                    <p>{item.Agent_name}</p>
                    <span>{item.Agent}</span>
                  </div>
                </td>

                <td>
                  <div className="table-inner-grid-dta">
                    <div className="btn-table-new">
                      <div className="btn-dis-new btn-create-quote">
                        <button className="common-button">Create Quote</button>
                      </div>
                      <div className="btn-dis-new">
                        <button
                          className="btn-viwe-icon"
                          onClick={toggleDrawer}
                        >
                          {/* <IconEyes /> */}
                          View More 
                        </button>
                        <TableActionButton options={options} isOpen={true} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="profile_slider_bar side_barcomman_main"
      >
        <div className="side_bar_inner_data">
          <div className="closemodal_btn">
            <button onClick={toggleDrawer}>
              <IconClose />
            </button>
          </div>

          <div className="div_data_main">
            <div className="heading-main">
              <h2 className="common-heading">Enquiry List</h2>
            </div>
            <div className="data-list-flex">
              <div className="list-data-one">
                <p>Date</p>
                <span>19 Nov, 2023</span>
              </div>
              <div className="list-data-one">
                <p>Enquiry Number</p>
                <span>#245879</span>
              </div>
              <div className="list-data-one">
                <p>Type</p>
                <span>Material</span>
              </div>
              <div className="list-data-one">
                <p>Client Name</p>
                <span>Mark Smith</span>
              </div>

              <div className="list-data-one">
                <p>Client Number</p>
                <span>#2458</span>
              </div>
              <div className="list-data-one">
                <p>Source of Enquir:</p>
                <span>Marketing</span>
              </div>
              <div className="list-data-one">
                <p>Point of contact</p>
                <span>Jenny Wilson</span>
              </div>
              <div className="list-data-one">
                <p>Source</p>
                <span>Agent</span>
              </div>

              <div className="list-data-one">
                <p>General Notes</p>
                <span>Lorem ipsum dolor.</span>
              </div>

              <div className="list-data-one">
                <p>Location</p>
                <span>4140 Parker Rd. Allentown</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-btn-attachmenet">
          <button
            class="common-button"
            onClick={() => handleShowProfilemodal()}
          >
            View Attachments
          </button>
        </div>
      </Drawer>

      <EnquiryEstimationProfileModal
        showProfilemodal={showProfilemodal}
        handleCloseProfilemodal={handleCloseProfilemodal}
      />
    </>
  );
};

export default EnquiryEstimationTableList;
