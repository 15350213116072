import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CommonFilters,
  SubconTableData,
  TitleHeader,
} from "../../../../components";

const SubconList = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Subcontractor Management", link: "" },
    { label: "Subcontractor list", link: "" },
    // { label: "Clients lists", link: "" },
  ];

  const handleAddClientClick = () => {
    navigate("/subcon-vendor/add-subcon");
  };
  return (
    <>
      <TitleHeader
        heading="Subcontractor"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add Subcon"
        onButtonClick={handleAddClientClick}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Subcontractor Lists" isDatebtn={false}/>
        <SubconTableData />
      </div>
    </>
  );
};

export default SubconList;
