import React, { useState } from "react";

const AgentCompanyDetailsModalUtils = () => {
  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  const [emiratesIdFile, setEmiratesIdFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [visaFile, setVisaFile] = useState(null);
  const [Emirates, setEmirates] = useState(null);

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };
  return {
    emiratesIdFile,
    setEmiratesIdFile,
    passportFile,
    setPassportFile,
    visaFile,
    setVisaFile,
    Emirates,
    setEmirates,
    handleFileChange,
    isOn,
    setIsOn,
    toggleSwitch,
  };
};

export default AgentCompanyDetailsModalUtils;
