import React from "react";

import {
  IconCalendarLight,
  IconDownloadArrow,
  IconFilter,
  IconSearch,
} from "../../../icon/icons";
import "./commonfilters.scss";
import FlatpickrAll from "../flatpickrall";
const CommonFilters = ({
   heading,  
   isDatebtn = true,}
) => {
  return (
    <div className="common-filter-main">
      <div className="flex-dis">
        <div className="heading-main">
          <h2 className="common-heading">{heading}</h2> 
        </div>

        <div className="filer-all-btn">
          <div className="search-input">
            <div className="input-main-all">
              <span>
                <IconSearch />
              </span>
              <input placeholder="Search" />
            </div>
          </div>
 
          {isDatebtn === true ? (
          <div className="date-all-btn">
                <FlatpickrAll/>
          </div>  ) : (
            ""
          )}

          <div className="btn-filter-main">
            <button className="common-button-light">
              <span>
                <IconFilter />
              </span>
              <p> Filters</p>
            </button>
          </div>

          <div className="title-button-data">
            <button className="common-button">
              {" "}
              Download <IconDownloadArrow />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFilters;
