import React, { useContext } from "react";
import { GlobalContext } from "../../../context";

const PriceUtils = () => {
  const { price } = useContext(GlobalContext);
  return {
    price,
  };
};

export default PriceUtils;
