import React, { useEffect, useState } from "react";
import Routes from "./routes/Routes";
import "./assets/scss/main.scss";
import { GlobalContext } from "./context";

function App() {
  const [size, setSize] = useState("medium");
  const [price, setPrice] = useState(localStorage.getItem("Isprice"));
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, []);

  return (
    <>
      <GlobalContext.Provider
        value={{ size, setSize, themeMode, setThemeMode, price, setPrice }}
      >
        <div className={`App ${size}`}>
          <Routes />
        </div>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
