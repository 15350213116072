import React from "react";
import "./estdashboard.scss";
import {
  EstimationEnquiriesChart,
  EstimationEnquiriesChartone,
  EstimationTopClient,
  EstimationTotalEnquiries,
  TitleHeader,
} from "../../../components";
const EstimationDashboard = () => {
  const breadcrumbs = [{ label: "Dashboard", link: "" }];
  return (
    <>
      <TitleHeader heading="Dashboard" breadcrumbs={breadcrumbs} />

      <div className="dashboard-data-top">
        <div className="flex-tiles-grid">
          <div className="dashboard-total-value gird-width-box ">
            <EstimationTotalEnquiries />
          </div>

          <div className="dashboard-all-table gird-width-box">
            <EstimationTopClient />
          </div>

          <div className="dashboard-all-chart gird-width-box">
            <EstimationEnquiriesChart />
          </div>

          <div className="dashboard-all-table gird-width-box">
            <EstimationTopClient />
          </div>

          <div className="dashboard-all-chart gird-width-box">
            <EstimationEnquiriesChartone />
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimationDashboard;
