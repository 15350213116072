import React from "react";
import "./newenquiries.scss";
import { NavLink } from "react-router-dom";
import { IconArrowRight } from "../../../../icon/icons";
import Chart from "react-apexcharts";
const NewenQuiries = () => {

  const chartData = [
    { status: "In Progress", count: 1400, className: "tap-one" },
    { status: "Finished", count: 600, className: "tap-two" },
    { status: "Unfinished", count: 200, className: "tap-three" },
  ];




  const chartOptions1 = { 
    chart: {
      type: "donut",
    },
    labels: ["Agent", "Company"],
    colors: ["#F2C94C", "#002855"], 
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: true, // Show the legend
      position: "bottom", // Position the legend at the bottom
      horizontalAlign: "center", // Optional: Center the legend horizontally
      labels: {
        colors: "var(--btn-text)",
      },
    },
    stroke: {
      width: 0, // Removes the border
    },
    responsive: [
      {
        breakpoint: 1600, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 250, // Smaller width for mobile
          },
        },
      },
      {
        breakpoint: 1136, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 350, // Smaller width for mobile
          },
        },
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: "donut",
    },
    labels: ["Agent", "Company"],
    colors: ["#F2C94C", "#002855"], 
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
    },
    legend: {
      show: true, // Show the legend
      position: "bottom", // Position the legend at the bottom
      horizontalAlign: "center", // Optional: Center the legend horizontally
      labels: {
        colors: "var(--btn-text)",
      },
    },
    stroke: {
      width: 0, // Removes the border
    },
    responsive: [
      {
        breakpoint: 1600, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 250, // Smaller width for mobile
          },
        },
      },
      {
        breakpoint: 1136, // Adjust settings for smaller screens
        options: {
          chart: {
            width: 350, // Smaller width for mobile
          },
        },
      },
    ],
  };



  const series1 = [50, 50]; // Existing Vs New
  const series2 = [40, 60]; // Agent vs Company

  return (
    <div className="page-card-bg">
      <div className="flex-top-heading">
        <div className="heading-main">
          <h2 className="common-heading"> New Enquiries </h2>
        </div>
        {/* <div className='view-all'>
        <NavLink>View all <span> <IconArrowRight/> </span></NavLink>
      </div>
       */}
      </div>
      <div className="chart-new-main">
        <div className="chart-one-new chart-inner">
          <h3>Existing Vs New</h3>
          <div className="chart-option-info">
            <Chart
              options={chartOptions1}
              series={series1}
              type="donut"
              width="320"
            />
           
          </div>
        </div>
        <div className="chart-two-new chart-inner">
          <h3>Source</h3>
          <div className="chart-option-info">
            <Chart
              options={chartOptions2}
              series={series2}
              type="donut"
              width="320"
            />
             {/* <div className="chart-dots-list">
              <ul>
                {chartData.map((item, index) => (
                  <li key={index}>
                    <h2>
                      <span className={item.className}></span> {item.status}
                    </h2>
                    <h3>{item.count}</h3>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewenQuiries;
