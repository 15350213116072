import React from "react";
import "./allproject.scss";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const AllProject = () => {
  const chartData = [
    { status: "In Progress", count: 1400, className: "tap-one" },
    { status: "Finished", count: 600, className: "tap-two" },
    { status: "Unfinished", count: 200, className: "tap-three" },
  ];
  return (
    <>
      <div className="all-project-main-box">
        <div className="page-card-bg">
          <div className="flex-top-heading">
            <div className="heading-main">
              <h2 className="common-heading"> All Project</h2>
              <span> Based On status </span>
            </div>
            {/* <div className="date-all-btn"></div> */}
          </div>

          <div className="new-chart-bottom">
            <div className="chart-from-data">
              <div style={{ width: 200, height: 200 }}>
                <CircularProgressbarWithChildren
                  value={50}
                  strokeWidth={12}
                  styles={buildStyles({
                    pathColor: "#F3C619",
                    trailColor: "#eee",
                    strokeLinecap: "butt",
                  })}
                >
                  {/* Foreground path */}
                  <CircularProgressbar
                    value={15}
                    strokeWidth={12}
                    styles={buildStyles({
                      trailColor: "transparent",
                      strokeLinecap: "butt",
                      pathColor: "#FF007A",
                    })}
                  />
                  <div
                    className="mid-text-data"
                    style={{
                      fontSize: 12,
                      marginTop: -5,
                      position: "absolute",
                    }}
                  >
                    <strong>66%</strong> Total New Customers
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div className="chart-dots-list">
              <ul>
                {chartData.map((item, index) => (
                  <li key={index}>
                    <h2>
                      <span className={item.className}></span> {item.status}
                    </h2>
                    <h3>{item.count}</h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProject;
