import React from 'react'
import { IconDay, IconNight } from '../../../icon/icons'
import ThemeToggleUtils from './themeToggleUtils'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import "./themetoggle.scss";
import { ImageSharp } from '@mui/icons-material';
import { images } from '../../../utils/customFn';
const ThemeToggle = () => {

    const {isOn,toggleSwitch,size ,handleSizeChange ,price, setPrice,togglePrice,} = ThemeToggleUtils()
  return (

    <>
    
    <div className="new-toggle-lig">
      <div className='drak-mode-div-new'>
        <div className='text-data-mode'>
          <p>Dark Mode</p>
        </div>
      
      <div
        className={`toggle-switch ${isOn ? "on" : "off"}`}
        onClick={toggleSwitch}
      >
       
        <div className="toggle-knob">
         
          {/* {isOn ? (
            <IconNight className="theme-icon" />
          ) : (
            <IconDay className="theme-icon" />
          )} */}
        </div>
      </div>
      </div>
     
 

    
      {/* <div className='price-show-hide'>
        <div className='hide-amount-new'>
        <div className='text-data-mode'>
          <p>Hide amounts</p>
          </div>
          <button onClick={togglePrice}>
             <img src={price ? images["closed-eye.png"] : images["open-eye.png"]} alt="eye" width="100%" height="auto" loading='lazy' />
          </button>
        </div>
      </div> */}


      <div className="price-show-hide">
      <div className='hide-amount-new'>
      <div className="text-data-mode">
          <p>{price ? 'Hide amounts ' : 'Hide amounts'}</p>
        </div>
      <div
        className={`toggle-switch ${price ? 'off' : 'on'}`}
        onClick={togglePrice} // Use the togglePrice function to change the price state
      >
        <div className="toggle-knob">
          {/* {price ? (
            <img
              src={images['closed-eye.png']}
              alt="Hide Price"
              width="100%"
              height="auto"
              loading="lazy"
            />
          ) : (
            <img
              src={images['open-eye.png']}
              alt="Show Price"
              width="100%"
              height="auto"
              loading="lazy"
            />
          )} */}
        </div>
        
      </div>
        </div>
    
    </div>

      {/* <div className='all_input'>
    <div className='select-data-main'>
    <FormControl fullWidth>
      <InputLabel id="size-select-label">Font Size</InputLabel>
      <Select
        labelId="size-select-label"
        id="size-select"
        value={size}
        onChange={handleSizeChange}
        label="Font Size"
      >
        <MenuItem value="small">Small</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="large">Large</MenuItem>
      </Select>
    </FormControl>
    </div>    
    </div> */}

      
    </div> 


    
 
     
    </>
   

  )
}

export default ThemeToggle