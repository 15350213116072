import React from "react";
import AgentCompanyDetailsModalUtils from "./agentcompanydetailsmodalUtils";
import {
  IconCalendarLight,
  IconClose,
  IconFile,
  IconFileAdd
} from "../../../../../icon/icons";
import Modal from "react-bootstrap/Modal";


import "./agentcompanydetailsmodal.scss";
import FlatpickrAll from "../../../../layout/flatpickrall";
const AgentCompanyDetailsModal = (props) => {
  const { showAddCompanymodal, handleCloseAddCompanymodal } = props;
  const {
    emiratesIdFile,
    setEmiratesIdFile,
    passportFile,
    setPassportFile,
    visaFile,
    setVisaFile,
    Emirates,
    setEmirates,
    handleFileChange,
    isOn,
    setIsOn,
    toggleSwitch,
  } = AgentCompanyDetailsModalUtils();
  return (
    <Modal
      className="modal-all-data modal-big-data  agent-company-details-modal"
      show={showAddCompanymodal}
      onHide={handleCloseAddCompanymodal}
    >
      <Modal.Body>
        <div className="closemodal_btn">
          <button onClick={handleCloseAddCompanymodal}>
            <IconClose />
          </button>
        </div>

        <div className="modal-inner-data-new">
          <div className="heading-main">
            <h2 className="">Add more details Agent</h2>
          </div>

          <div className="select-document-section section-all-modal">
            <div className="heading-main-top">
              <h2 className="common-heading">Select Document</h2>
              <p>Select document for company</p>
            </div>

            <div className="select-all-type">
              <h2>Select Document type</h2>
              <div className="data-fle-grid">
                <div className="div-data-switch">
                  <label>Passport</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Emirates ID</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Visa</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Trade license Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Trade license Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Trade license Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-emirates").click()
                  }
                >
                  {!emiratesIdFile ? (
                    <div className="icon-bs">
                      <IconFileAdd size={100} className="icon-upload" />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {emiratesIdFile
                      ? emiratesIdFile.name
                      : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-emirates"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="company-owners-document-section section-all-modal">
            <div className="heading-main-top">
              <h2 className="common-heading">Company Owners</h2>
              <p>Select documents for company Owners</p>

              <div className="input-main-data">
                <input placeholder="Eida Al Menhali" />
              </div>
            </div>

            <div className="select-all-type">
              <h2>Select Document type</h2>
              <div className="data-fle-grid">
                <div className="div-data-switch">
                  <label>Passport</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Emirates ID</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Visa</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Passport Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Passport Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Passport Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-passport").click()
                  }
                >
                  {!passportFile ? (
                    <div className="icon-bs">
                      <IconFileAdd size={100} className="icon-upload" />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {passportFile
                      ? passportFile.name
                      : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-passport"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setPassportFile)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="company-owners-document-section section-all-modal div-data-comman">
            <div className="heading-main-top">
              {/* <h2 className="common-heading">Company Owners</h2>
            <p>Select documents for company Owners</p> */}

              <div className="input-main-data">
                <input placeholder="Eida Al Menhali" />
              </div>
            </div>

            <div className="select-all-type">
              <h2>Select Document type</h2>
              <div className="data-fle-grid">
                <div className="div-data-switch">
                  <label>Passport</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Emirates ID</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Visa</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Emirates ID Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Emirates Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Emirates Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-emirates-new").click()
                  }
                >
                  {!Emirates ? (
                    <div className="icon-bs">
                      <IconFileAdd size={100} className="icon-upload" />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {Emirates ? Emirates.name : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-emirates-new"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setEmirates)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="company-owners-document-section section-all-modal div-data-comman">
            <div className="heading-main-top">
              {/* <h2 className="common-heading">Company Owners</h2>
            <p>Select documents for company Owners</p> */}

              <div className="input-main-data">
                <input placeholder="Hasan Malik" />
              </div>
            </div>

            <div className="select-all-type">
              <h2>Select Document type</h2>
              <div className="data-fle-grid">
                <div className="div-data-switch">
                  <label>Passport</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Emirates ID</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>

                <div className="div-data-switch">
                  <label>Visa</label>
                  <div
                    className={`toggle-switch ${isOn ? "on" : "off"}`}
                    onClick={toggleSwitch}
                  >
                    <div className="toggle-knob"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Visa Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Visa Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Visa Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-visa").click()
                  }
                >
                  {!visaFile ? (
                    <div className="icon-bs">
                      <IconFileAdd size={100} className="icon-upload" />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {visaFile ? visaFile.name : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-visa"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setVisaFile)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="add-more-owner-btn">
            <button className="common-button">
              <span>+</span> Add More Owner
            </button>
          </div>

          <div className="btn-form-send">
            <button className="common-button">Submit</button>
            <button
              className="common-button cancel-btn"
              onClick={handleCloseAddCompanymodal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgentCompanyDetailsModal;
