import { Route } from "react-router-dom";
import content from "../config/content.json";

const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export const images = importAll(require.context("../assets/images", false));

//Get Routs
export const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.route) {
      return (
        <Route exact path={route.route} element={route.page} key={route.key} />
      );
    }

    return null;
  });

export const getText = (text) => {
  let formatText = text?.replace(/ /g, "");
  if (content[formatText]) {
    return content[formatText];
  } else {
    let formatted = formatText?.replace(/_/g, " ");
    return formatted?.charAt(0)?.toUpperCase() + formatted.slice(1);
  }
};
