import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  IconDashboard,
  IconDocuments,
  IconEstimation,
  IconFinanceSide,
  IconPurchase,
  IconSubconVendor,
} from "../../../icon/icons";
import { useClickAway } from "@uidotdev/usehooks";
const MenuItems = ({ collapsed }) => {
  // State to track the currently open SubMenu
  const [activeMenu, setActiveMenu] = useState(null);

  // Function to handle SubMenu click
  const handleMenuClick = (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
  };

  const location = useLocation();

  const isClientActive =
    location.pathname === "/marketing/client/dashbaord" ||
    location.pathname.startsWith("/add-client");
  const isAgentActive =
    location.pathname === "/marketing/agent-database" ||
    location.pathname.startsWith("/add-agent");
  const isSubconVendor =
    location.pathname === "/subcon-vendor/vendor-list" ||
    location.pathname.startsWith("/subcon-vendor/add-vendor");
  const isSubconList =
    location.pathname === "/subcon-vendor/subcon-list" ||
    location.pathname.startsWith("/subcon-vendor/add-subcon");

  const isEnquiryMarketing =
    location.pathname === "/estimation/enquiry-marketing-list" ||
    location.pathname.startsWith("/estimation/add-enquiry");

  const isEnquiryEstimation =
    location.pathname === "/estimation/enquiry-estimation-list" ||
    location.pathname.startsWith("/estimation/add-estimation");

  const isQuotationList =
    location.pathname === "/estimation/quotation-list" ||
    location.pathname.startsWith("/estimation/add-quotation");

  const isPoList =
    location.pathname === "/estimation/polist-data" ||
    location.pathname.startsWith("/estimation/addpo-list");

  const isPurchaseRequest =
    location.pathname === "/purchase/request-list" ||
    location.pathname.startsWith("/purchase/add-request");

  const isPoRegisterList =
    location.pathname === "/purchase/register-list" ||
    location.pathname.startsWith("/purchase/add-poregister");

  const isPoRqfList =
    location.pathname === "/purchase/rqf-list" ||
    location.pathname.startsWith("/purchase/add-enquiry") ||
    location.pathname.startsWith("/purchase/add-project");
  const isQuotList =
    location.pathname === "/purchase/quotations-list" ||
    location.pathname.startsWith("/purchase/add-other") ||
    location.pathname.startsWith("/purchase/add-quote-enquiry");
  const filterRef = useClickAway(() => {
    if (collapsed) {
      handleMenuClick(false);
    }
    // setTimeout(() => {
    // }, setTimeoutSec); // Adding a delay
  });

  return (
    <Menu className="sidebar_icon_list" ref={filterRef}>
      {/* Marketing SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconDashboard />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconDashboard />
                </span>
                <p>Marketing</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "marketing"}
        onClick={() => handleMenuClick("marketing")}
      >
        <MenuItem>
          <NavLink
            to="/marketing/dashboard"
            className={isClientActive ? "active" : ""}
          >
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/marketing/client-database"
            className={isClientActive ? "active" : ""}
          >
            <p>Client</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/marketing/agent-database"
            className={isAgentActive ? "active" : ""}
          >
            <p>Agent</p>
          </NavLink>
        </MenuItem>
      </SubMenu>

      {/* Estimation SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconEstimation />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconEstimation />
                </span>
                <p>Estimation</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "estimation"}
        onClick={() => handleMenuClick("estimation")}
      >
        <MenuItem>
          <NavLink to="/estimation/dashboard">
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/estimation/enquiry-marketing-list"
            className={isEnquiryMarketing ? "active" : ""}
          >
            <p>Enquiry Marketing</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/estimation/enquiry-estimation-list"
            className={isEnquiryEstimation ? "active" : ""}
          >
            <p>Enquiry Estimation</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/estimation/quotation-list"
            className={isQuotationList ? "active" : ""}
          >
            <p>Quotation</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/estimation/polist-data"
            className={isPoList ? "active" : ""}
          >
            <p>PO</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/estimation/descope-list">
            <p>Descope</p>
          </NavLink>
        </MenuItem>
      </SubMenu>

      {/* Subcon & Vendor SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconSubconVendor />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconSubconVendor />
                </span>
                <p>Subcon & Vendor</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "subcon-vendor"}
        onClick={() => handleMenuClick("subcon-vendor")}
      >
        <MenuItem>
          <NavLink to="/subcon-vendor/subcon-dashboard">
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/subcon-vendor/subcon-list"
            className={isSubconList ? "active" : ""}
          >
            <p>Subcontractor</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/subcon-vendor/vendor-list"
            className={isSubconVendor ? "active" : ""}
          >
            <p>Vendors</p>
          </NavLink>
        </MenuItem>
      </SubMenu>

      {/* Purchase SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconPurchase />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconPurchase />
                </span>
                <p>Purchase</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "purchase"}
        onClick={() => handleMenuClick("purchase")}
      >
        <MenuItem>
          <NavLink to="/purchase/dashboard">
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="purchase/price-list">
            <p>Price List</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/purchase/request-list"
            className={isPurchaseRequest ? "active" : ""}
          >
            <p>Purchase request</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/purchase/rqf-list"
            className={isPoRqfList ? "active" : ""}
          >
            <p>RFQ</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/purchase/quotations-list"
            className={isQuotList ? "active" : ""}
          >
            <p>Quote</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink
            to="/purchase/register-list"
            className={isPoRegisterList ? "active" : ""}
          >
            <p>PO</p>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/purchase/returns">
            <p>Returns</p>
          </NavLink>
        </MenuItem>
      </SubMenu>

      {/* Documents SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconDocuments />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconDocuments />
                </span>
                <p>Documents</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "documents"}
        onClick={() => handleMenuClick("documents")}
      >
        <MenuItem>
          <NavLink to="/documents/dashboard">
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="">
            <p>My docs</p>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="">
            <p>Company docs</p>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="">
            <p>Project docs</p>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="">
            <p>NOC’s & Permits</p>
          </NavLink>
        </MenuItem>
      </SubMenu>

      {/* Finance SubMenu */}
      <SubMenu
        className="new-sub-menu-active sidebar-page-menu"
        label={
          <div className="div-inner-menu">
            {collapsed ? (
              <span className="sidebar-icon">
                <IconFinanceSide />
              </span>
            ) : (
              <>
                <span className="sidebar-icon">
                  <IconFinanceSide />
                </span>
                <p>Finance</p>
              </>
            )}
          </div>
        }
        open={activeMenu === "finance"}
        onClick={() => handleMenuClick("finance")}
      >
        <MenuItem>
          <NavLink to="">
            <p>Dashboard</p>
          </NavLink>
        </MenuItem>

        <MenuItem>
          {/* Nested Menu Items for Sales Invoice */}
          <SubMenu label="Sales Invoice" className="submenu-inner-data">
            <MenuItem>
              <NavLink to="/projects">
                <p>Projects</p>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/others">
                <p>Others</p>
              </NavLink>
            </MenuItem>
          </SubMenu>
        </MenuItem>

        <MenuItem>
          {/* Nested Menu Items for Sales Invoice */}
          <SubMenu label="Purchase Invoice" className="submenu-inner-data">
            <MenuItem>
              <NavLink to="/projects">
                <p>Subcontractors</p>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/others">
                <p>Vendors</p>
              </NavLink>
            </MenuItem>
          </SubMenu>
        </MenuItem>

        <MenuItem>
          {/* Nested Menu Items for Sales Invoice */}
          <SubMenu label="Salary" className="submenu-inner-data">
            <MenuItem>
              <NavLink to="/projects">
                <p>Payroll</p>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/others">
                <p>Advances & Loans</p>
              </NavLink>
            </MenuItem>
          </SubMenu>
        </MenuItem>
      </SubMenu>
    </Menu>
  );
};

export default MenuItems;
