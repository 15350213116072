import React from 'react'
import "./purchasequotationsreceived.scss"
import { IconArrowRight } from '../../../../icon/icons';
import { NavLink } from 'react-router-dom';
import Table from "react-bootstrap/Table";

const data = [
    {
      id: 245879,
      name: "Mark Smith",
      type: "Vendor",
      date: "23,jun 2024",
      Project_nane:"ABC Enterprise",
      Project_no:"12345",
      Added_by_name: "Ella Marie",
      Added_by_id: "245879",
      Amount: "1,150",
    },
    {
        id: 245879,
        name: "Mark Smith",
        type: "Vendor",
        date: "23,jun 2024",
        Project_nane:"ABC Enterprise",
        Project_no:"12345",
        Added_by_name: "Ella Marie",
        Added_by_id: "245879",
        Amount: "1,150",
      },
      {
        id: 245879,
        name: "Mark Smith",
        type: "Vendor",
        date: "23,jun 2024",
        Project_nane:"ABC Enterprise",
        Project_no:"12345",
        Added_by_name: "Ella Marie",
        Added_by_id: "245879",
        Amount: "1,150",
      },
      {
        id: 245879,
        name: "Mark Smith",
        type: "Vendor",
        date: "23,jun 2024",
        Project_nane:"ABC Enterprise",
        Project_no:"12345",
        Added_by_name: "Ella Marie",
        Added_by_id: "245879",
        Amount: "1,150",
      },
   
  ];


const PurchaseQuotationsReceived = () => {


  return (
     <>
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> Recent quotations Received </h2>
          </div>
          <div className="view-all">
            <NavLink>
              View all{" "}
              <span>
                {" "}
                <IconArrowRight />{" "}
              </span>
            </NavLink>
          </div>
        </div>

        <div className="all-table-data">
          <div className="table-data-main table-all-inner">
            <Table responsive>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Date Received </th>
                  <th>Project Name & Number </th>
                  <th>Added by</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>

                    <td>
                    <div className="user-info">
                        <p>{item.type}</p>
                    </div>
                    </td>


                    <td>
                      <div className="user-info">
                        <p>{item.name}</p>
                        <span>#{item.id}</span>
                      </div>
                    </td>
                  

                    <td>
                      <div className="user-info">
                        <p>{item.date}</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Project_nane}</p>
                        <span>{item.Project_no}</span>
                      </div>
                    </td>

                    <td>
                      <div className="user-info">
                        <p>{item.Added_by_name}</p>
                        <span>#{item.Added_by_id}</span>
                      </div>
                    </td>

                    <td>
                    <div className="user-info">
                        <p>{item.Amount}</p>
                    </div>
                    </td>

                    
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseQuotationsReceived
