import React, { useState } from 'react'
import "./addrfqenquiry.scss"
import {  AddRfqEnquiryForm, TitleHeader } from '../../../../../components'


const AddRfqEnquiry = () => {


  const breadcrumbs = [
    { label: "Vehicles", link: "" },
    { label: "Add New Vehicle", link: "" },
  ];
 

  return (
    <>
     <TitleHeader
        heading="Add New RFQ - Enquiry "
        breadcrumbs={breadcrumbs} 
        
      />
    

     <AddRfqEnquiryForm/>
  

    </>
  )
}

export default AddRfqEnquiry 