import React, { useEffect, useState } from "react";
import { images } from "../../../utils/customFn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import "./revenue.scss";
import TabAll from "./taball";

const Revenue = () => {
  return (
    <div className="revenue-main-box">
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> Revenue</h2>
            <span> Your Revenue This Year</span>
          </div>
          {/* <div className="date-all-btn"></div> */}
        </div>

        <div className="chart-tab-data-new">
          <Tabs
            defaultActiveKey="all"
            id="justify-tab-example"
            className="revenue-tab-new"
            justify
          >
            <Tab eventKey="all" title="All">
              <TabAll />
            </Tab>
            <Tab eventKey="income" title="Income">
              Tab content for Profile
            </Tab>
            <Tab eventKey="expenses" title="Expenses">
              Tab content for Loooonger Tab
            </Tab>
            <Tab eventKey="profit" title="Profit">
              Tab content for Contact
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
