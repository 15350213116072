import React, { useContext } from "react";
import { GlobalContext } from "../../../../context";

const EstimationTotalEnquiriesUtils = () => {
  const { themeMode } = useContext(GlobalContext);
  return {
    themeMode,
  };
};

export default EstimationTotalEnquiriesUtils;
