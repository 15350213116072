import React from 'react'
import "./documentsdashboard.scss"
import { CompanyDocumentsData, DocumentsNearingExpiryData, MyDocumentsData, ProjectDocumentsData } from '../../../components'
const DocumentsDashboard = () => {


  return (
    <>
     
      <div className="dashboard-data-top">
        <div className="flex-tiles-grid">
         
          <div className="dashboard-all-table gird-width-box">
            <MyDocumentsData/>
          </div>
          <div className="dashboard-all-table gird-width-box">
            <CompanyDocumentsData/>
          </div> 
          <div className="dashboard-all-table gird-width-box">
            <ProjectDocumentsData/>
          </div>
          <div className="dashboard-all-table gird-width-box">
            <DocumentsNearingExpiryData/>
          </div>
         
        </div>
      </div>
    </>
  )
}

export default DocumentsDashboard

