import React from "react";
import "./vendorlist.scss";
import {
  CommonFilters,
  TitleHeader,
  VendorTableData,
} from "../../../../components";
import { useNavigate } from "react-router-dom";

const VendorList = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: "Vendor Management", link: "" },
    { label: "Vendor list", link: "" },
    // { label: "Clients lists", link: "" },
  ];

  const handleAddClientClick = () => {
    navigate("/subcon-vendor/add-vendor");
  };
  return (
    <>
      <TitleHeader
        heading="Vendor list"
        breadcrumbs={breadcrumbs}
        buttonText="+ Add Vendor"
        onButtonClick={handleAddClientClick}
      />

      <div className="page-card-bg table-data-new">
        <CommonFilters heading="Vendor List" isDatebtn={false}/>
        <VendorTableData />
      </div>
    </>
  );
};

export default VendorList;
