import React, { useState } from 'react'
import './vendortabledata.scss'
import VendorTableDataUtils from './vendortabledataUtils';
import Table from "react-bootstrap/Table";
import { NavLink } from 'react-router-dom';
import { IconButtonDots, IconClose, IconDownload, IconEyes, IconPrint } from '../../../../../icon/icons';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import ReactPaginate from "react-paginate"; 
import VendorProfileModal from '../vendorprofilemodal';
import VendorProjectList from '../vendorprojectlist';
import TableActionButton from '../../../../layout/tableactionbutton';
import CommonPaginate from '../../../../layout/commonpaginate';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const data = [
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
  {
    id: 245879,
    name: "Mark Smith",
    role: "Manpower",
    department:"NA"
  },
];



const VendorTableData = () => {

    const {
        showProfilemodal,
        setShowProfileModal,
        handleCloseProfilemodal,
        handleShowProfilemodal,
        showProjectmodal,
        setShowProjectModal,
        handleCloseProjectmodal, 
        handleShowProjectmodal,
    }=VendorTableDataUtils();

 


  // Profile Drawer
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };


  // Pagination all status
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };


  const options = [
   
    { 
      title: "Edit",
      icon: <EditIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      action: () => {
        // console.log("Vishal");
      },
    },
  ];

  return (
    <>
      <div className="table-data-main table-old-data-main">
      <Table responsive>
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Type </th>
            <th>Department</th>

            <th>Contact No.</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="user-info">
                  <p>{item.name}</p>
                  <span>#{item.id}</span>
                </div>
              </td>
              <td>
                <div className="user-info">
                  <span>{item.role}</span>
                </div>
              </td>
              <td>
                <div className="user-info">
                  <span>{item.department}</span>
                </div>
              </td>

              <td>
                <div className="contact-info">
                  <p>(+971) 1234567890</p>
                  <NavLink to="mailto:example@gmail.com">
                    {" "}
                    example@gmail.com
                  </NavLink>
                </div>
              </td>
         

              <td>
                <div className="btn-dis-new">
                  <button className="common-button"
                    onClick={() => handleShowProjectmodal()}
                  >
                    View Projects
                  </button>
                  <button className="btn-viwe-icon"  onClick={toggleDrawer} >
                    {/* <IconEyes /> */}
                    View More
                  </button>
                  <TableActionButton options={options} isOpen={true} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>


      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="profile_slider_bar side_barcomman_main"
      >
       <div className='side_bar_inner_data'>
         <div className="closemodal_btn">
          <button onClick={toggleDrawer}>
            <IconClose /> 
          </button>
        </div>

        <div className='div_data_main'>
           <div className="heading-main">
             <h2 className="common-heading">Vendor Details</h2>
           </div>
            <div className='data-list-flex'>
              <div className='list-data-one'>
                  <p>Vendor Name</p>
                  <span>Mark Smith</span>
              </div>
              <div className='list-data-one'>
                  <p>Vendor Number</p>
                  <span>#245879</span>
              </div>
              <div className='list-data-one'>
                  <p>Type</p>
                  <span>Material</span>
              </div>
              <div className='list-data-one'>
                  <p>Department</p>
                  <span>MEP</span>
              </div>

              <div className='list-data-one'>
                  <p>Contact Number</p>
                  <span>(+971) 1234567890</span>
              </div>
              <div className='list-data-one'>
                  <p>Contact Email</p>
                  <span>example@gmail.com</span>
              </div>
            </div>
            </div>
{/* 
            <div className="btn-acction">
              <button className="common-button" >
                <span>
                  <IconDownload />
                </span>
                Download PDF
              </button>

              <button className="common-button" >
                <span>
                  <IconPrint />
                </span>
                Print
              </button>
            </div> */}
        </div>

         <div className='bottom-btn-attachmenet'>
             <button class="common-button" 
              onClick={() => handleShowProfilemodal()}
             >View Attachments</button>
          </div>
      </Drawer>


      <div className="paginate_all_common">
        <CommonPaginate
          itemsPerPage={itemsPerPage}
          paginate={paginate}
          length={data?.length}
        />
      </div>

      <VendorProfileModal
        showProfilemodal={showProfilemodal}
        handleCloseProfilemodal={handleCloseProfilemodal}
      />
 
     <VendorProjectList
      showProjectmodal={showProjectmodal}
      handleCloseProjectmodal={handleCloseProjectmodal}
     />

    </>
  )
}

export default VendorTableData