import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";

import './vendordetailsmodal.scss'
import { IconCalendarLight, IconClose, IconFile,IconFileAdd } from '../../../../../icon/icons';
import FlatpickrAll from '../../../../layout/flatpickrall';
const VendorDetailsModal = (props) => {

  const { showAddVendormodal, handleCloseAddVendormodal } = props;

  const [emiratesIdFile, setEmiratesIdFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [visaFile, setVisaFile] = useState(null);

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const [isOn, setIsOn] = useState(false); 
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };
  return (
    <>
    <Modal
      className="modal-all-data modal-big-data vendor-details-more-modal"
      show={showAddVendormodal}
      onHide={handleCloseAddVendormodal}
      centered
    >
      <Modal.Body>
        <div className="closemodal_btn">
          <button onClick={handleCloseAddVendormodal}>
            <IconClose />
          </button>
        </div>

        <div className="modal-inner-data">
          <div className="heading-main">
            <h2 className="common-heading">Add more details Vendor</h2>
          </div>

          <div className="add-client-form-data">
            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Trade license Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Trade license Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Trade license Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-emirates").click()
                  }
                >
                  {!emiratesIdFile ? (
                    <div className="icon-bs">
                      <IconFileAdd
                        size={100}
                        className="icon-upload"
                      />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {emiratesIdFile
                      ? emiratesIdFile.name
                      : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-emirates"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                  />
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>VAT license Number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Vat license Expiry Date</label>
                <FlatpickrAll/>
              </div>

              <div className="input-main-data">
                <label>Vat license Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-passport").click()
                  }
                >
                  {!passportFile ? (
                    <div className="icon-bs">
                      <IconFileAdd
                        size={100}
                        className="icon-upload"
                      />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {passportFile
                      ? passportFile.name
                      : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-passport"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setPassportFile)}
                  />
                </div>
              </div>
            </div>

            <div className="form-all-grid">
              <div className="input-main-data">
                <label>Corporate tax number</label>
                <input placeholder="874211442475" />
              </div>

              <div className="input-main-data">
                <label>Corporate tax Date</label>
                <FlatpickrAll/>
              </div>
              <div className="input-main-data">
                <label>Corporate tax Attachment</label>
                <div
                  className="wrap-image-false"
                  onClick={() =>
                    document.querySelector(".input-field-visa").click()
                  }
                >
                  {!visaFile ? (
                    <div className="icon-bs">
                      <IconFileAdd
                        size={100}
                        className="icon-upload"
                      />
                    </div>
                  ) : (
                    <div className="icon-bs">
                      <IconFile />
                    </div>
                  )}
                  <p className="file-name">
                    {visaFile ? visaFile.name : "Add Attachment"}
                  </p>
                  <input
                    type="file"
                    hidden
                    className="input-field-visa"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setVisaFile)}
                  />
                </div>
              </div>
            </div>

            <div className="Modal-mid-data">
              <div className="heading-main-mid">
                <h2 className="common-heading-new">Company Owner 1</h2>
              </div>
              <div className="flex-data-mid">
                <div className="input-main-data">
                  <label>Owner Name</label>
                  <input placeholder="John Smith" />
                </div>
                <div className="select-all-type">
                  <h2>Documents type</h2>
                  <div className="data-fle-grid">
                    <div className="div-data-switch">
                      <label>Passport</label>
                      <div
                        className={`toggle-switch ${isOn ? "on" : "off"}`}
                        onClick={toggleSwitch}
                      >
                        <div className="toggle-knob"></div>
                      </div>
                    </div>

                    <div className="div-data-switch">
                      <label>Emirates ID</label>
                      <div
                        className={`toggle-switch ${isOn ? "on" : "off"}`}
                        onClick={toggleSwitch}
                      >
                        <div className="toggle-knob"></div>
                      </div>
                    </div>

                    <div className="div-data-switch">
                      <label>Visa</label>
                      <div
                        className={`toggle-switch ${isOn ? "on" : "off"}`}
                        onClick={toggleSwitch}
                      >
                        <div className="toggle-knob"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-all-grid">
                <div className="input-main-data">
                  <label>Trade license Number</label>
                  <input placeholder="874211442475" />
                </div>

                <div className="input-main-data">
                  <label>Trade license Expiry Date</label>
                  <FlatpickrAll/>
                </div>

                <div className="input-main-data">
                  <label>Trade license Attachment</label>
                  <div
                    className="wrap-image-false"
                    onClick={() =>
                      document.querySelector(".input-field-emirates").click()
                    }
                  >
                    {!emiratesIdFile ? (
                      <div className="icon-bs">
                        <IconFileAdd
                          size={100}
                          className="icon-upload"
                        />
                      </div>
                    ) : (
                      <div className="icon-bs">
                        <IconFile />
                      </div>
                    )}
                    <p className="file-name">
                      {emiratesIdFile
                        ? emiratesIdFile.name
                        : "Add Attachment"}
                    </p>
                    <input
                      type="file"
                      hidden
                      className="input-field-emirates"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add-more-owner-btn">
            <button className="common-button">
              <span>+</span> Add More Owner
            </button>
          </div>

          <div className="btn-form-send">
            <button className="common-button">Submit</button>

            <button
              className="common-button cancel-btn"
              onClick={handleCloseAddVendormodal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
  )
}

export default VendorDetailsModal