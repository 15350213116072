import React from "react";
import "./addnewagent.scss";
import { AgentForm, TitleHeader } from "../../../../components";
const AddnewAgent = () => {
  const breadcrumbs = [
    { label: "Marketing", link: "" },
    { label: "Agent database", link: "" },
    { label: "Add new Agent", link: "" },
  ];

  return (
    <>
      <TitleHeader heading="Add New Agent" breadcrumbs={breadcrumbs} />
      <AgentForm />
    </>
  );
};

export default AddnewAgent;
