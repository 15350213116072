import React from "react";
import "./recentproject.scss";
import {
  IconCalendarLight,
  IconDataDown,
  IconFilter,
} from "../../../icon/icons";
import Table from "react-bootstrap/Table";
import { images } from "../../../utils/customFn";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FlatpickrAll from "../../layout/flatpickrall";

const data = [
  {
    id: 245879,
    projec_img: images["project-image-1.png"],
    project_name: "Your project Name",
    cilent_name: "Client Name",
    date: "23,jun 2024",
    status: "Sucess",
    percentage: 80,
  },

  {
    id: 245871,
    projec_img: images["project-image-1.png"],
    project_name: "Your project Name",
    cilent_name: "Client Name",
    date: "23,jun 2024",
    status: "Sucess",
    percentage: 70,
  },

  {
    id: 245870,
    projec_img: images["project-image-1.png"],
    project_name: "Your project Name",
    cilent_name: "Client Name",
    date: "23,jun 2024",
    status: "Pending",
    percentage: 100,
  },
];
const RecentProject = () => {
 

  return (
    <div className="recent-project-data-box">
      <div className="page-card-bg">
        <div className="flex-top-heading">
          <div className="heading-main">
            <h2 className="common-heading"> Recent Project</h2>
          </div>
          <div className="btn-filter-flex">
            <div className="date-all-btn">
            <FlatpickrAll/>
            </div>
            <div className="btn-filter-main">
              <button className="common-button-light">
                <span>
                  <IconFilter />
                </span>
                <p> Filters</p>
              </button>
            </div>
            <div className="btn-see-more">
              <button className="common-button-light">See All</button>
            </div>
          </div>
        </div>

        <div className="table-data-main look-data-table-new">
          <Table responsive>
            <thead>
              <tr>
                <th>Project</th>
                <th>
                  {" "}
                  <div className="table-filter-icon">
                    {" "}
                    Progress <IconDataDown />{" "}
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="table-filter-icon">
                    {" "}
                    Due Date <IconDataDown />
                  </div>{" "}
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className=" image-flex-tabe">
                      <div className="image-icon">
                        <img src={item.projec_img} />
                      </div>
                      <div className="user-info">
                        <p>{item.project_name}</p>
                        <span>#{item.cilent_name}</span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="progress-circle">
                      <div style={{ width: 40, height: 40 }}>
                        <CircularProgressbar
                          value={item.percentage}
                          text={`${item.percentage}%`}
                          styles={buildStyles({
                            textColor: `var(--btn-text)`,
                            pathColor:
                              item.percentage === 100 ? "#009E83" : "#F3C619",
                            trailColor: "#D9D9D9",
                            textSize: "30px",
                          })}
                        />
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="user-type">
                      <span>{item.date}</span>
                    </div>
                  </td>

                  <td>
                    <div className="user-info">
                      <span
                        className={`${
                          item.status === "Sucess"
                            ? "sucess"
                            : item.status === "Pending"
                            ? "pending"
                            : "rejected"
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RecentProject;
