import {
  AddenquiryEstimation,
  AddEnquiryMarketing,
  AddnewAgent,
  AddnewClient,
  AddPo,
  AddPoRegister,
  AddPurchaseRequest,
  AddQuotation,
  AddRfqEnquiry,
  AddRfqProject,
  AddSubcon,
  AddVendor,
  AgentDatabase,
  ClientDatabase,
  DescopeList,
  DocumentsDashboard,
  EnquiryEstimationList,
  EnquiryMarketingList,
  EstimationDashboard,
  MainDashboard,
  MarketingDashboard,
  PolistData,
  PoRegisterList,
  PriceList,
  PurchaseDashboard,
  PurchaseRequestList,
  PurchaseReturns,
  PurchaseRqfList,
  PurchaseRqfListQuotations,
  QuotationList,
  QuoteForEnquiry,
  QuoteForOther,
  SubconDashboard,
  SubconList,
  VendorList,
} from "../pages";

const RouteArr = [
  {
    name: "MainDashboard",
    path: "/main-dashboard",
    key: "main-dashboard",
    route: "/main-dashboard",
    element: <MainDashboard />,
  },

  {
    name: "MarketingDashboard",
    path: "/marketing/dashboard",
    key: "/marketing/dashboard",
    route: "/marketing/dashboard",
    element: <MarketingDashboard />,
  },

  {
    name: "ClientDatabase",
    path: "/marketing/client-database",
    key: "/marketing/client-database",
    route: "/marketing/client-database",
    element: <ClientDatabase />,
  },

  {
    name: "AddnewClient",
    path: "/marketing/client-database/add-client",
    key: "/marketing/client-database/add-client",
    route: "/marketing/client-database/add-client",
    element: <AddnewClient />,
  },

  {
    name: "AgentDatabase",
    path: "/marketing/agent-database",
    key: "/marketing/agent-database",
    route: "/marketing/agent-database",
    element: <AgentDatabase />,
  },

  {
    name: "AddnewAgent",
    path: "/marketing/agent-database/add-agent",
    key: "/marketing/agent-database/add-agent",
    route: "/marketing/agent-database/add-agent",
    element: <AddnewAgent />,
  },

  {
    name: "SubconDashboard",
    path: "/subcon-vendor/subcon-dashboard",
    key: "/subcon-vendor/subcon-dashboard",
    route: "/subcon-vendor/subcon-dashboard",
    element: <SubconDashboard />,
  },

  {
    name: "VendorList",
    path: "/subcon-vendor/vendor-list",
    key: "/subcon-vendor/vendor-list",
    route: "/subcon-vendor/vendor-list",
    element: <VendorList />,
  },

  {
    name: "AddVendor",
    path: "/subcon-vendor/add-vendor",
    key: "/subcon-vendor/add-vendor",
    route: "/subcon-vendor/add-vendor",
    element: <AddVendor />,
  },

  {
    name: "SubconList",
    path: "/subcon-vendor/subcon-list",
    key: "/subcon-vendor/subcon-list",
    route: "/subcon-vendor/subcon-list",
    element: <SubconList />,
  },

  {
    name: "AddSubcon",
    path: "/subcon-vendor/add-subcon",
    key: "/subcon-vendor/add-subcon",
    route: "/subcon-vendor/add-subcon",
    element: <AddSubcon />,
  },

  {
    name: "EstimationDashboard",
    path: "/estimation/dashboard",
    key: "/estimation/dashboard",
    route: "/estimation/dashboard",
    element: <EstimationDashboard />,
  },

  {
    name: "EnquiryMarketingList",
    path: "/estimation/enquiry-marketing-list",
    key: "/estimation/enquiry-marketing-list",
    route: "/estimation/enquiry-marketing-list",
    element: <EnquiryMarketingList />,
  },

  {
    name: "AddEnquiryMarketing",
    path: "/estimation/add-enquiry",
    key: "/estimation/add-enquiry",
    route: "/estimation/add-enquiry",
    element: <AddEnquiryMarketing />,
  },

  {
    name: "EnquiryEstimationList",
    path: "/estimation/enquiry-estimation-list",
    key: "/estimation/enquiry-estimation-list",
    route: "/estimation/enquiry-estimation-list",
    element: <EnquiryEstimationList />,
  },

  {
    name: "AddenquiryEstimation",
    path: "/estimation/add-estimation",
    key: "/estimation/add-estimation",
    route: "/estimation/add-estimation",
    element: <AddenquiryEstimation />,
  },

  {
    name: "DescopeList",
    path: "/estimation/descope-list",
    key: "/estimation/descope-list",
    route: "/estimation/descope-list",
    element: <DescopeList />,
  },

  {
    name: "QuotationList",
    path: "/estimation/quotation-list",
    key: "/estimation/quotation-list",
    route: "/estimation/quotation-list",
    element: <QuotationList />,
  },

  {
    name: "AddQuotation",
    path: "/estimation/add-quotation",
    key: "/estimation/add-quotation",
    route: "/estimation/add-quotation",
    element: <AddQuotation />,
  },

  {
    name: "PolistData",
    path: "/estimation/polist-data",
    key: "/estimation/polist-data",
    route: "/estimation/polist-data",
    element: <PolistData />,
  },

  {
    name: "AddPo",
    path: "/estimation/addpo-list",
    key: "/estimation/addpo-list",
    route: "/estimation/addpo-list",
    element: <AddPo />,
  },

  {
    name: "PurchaseDashboard",
    path: "/purchase/dashboard",
    key: "/purchase/dashboard",
    route: "/purchase/dashboard",
    element: <PurchaseDashboard />,
  },

  {
    name: "PriceList",
    path: "/purchase/price-list",
    key: "/purchase/price-list",
    route: "/purchase/price-list",
    element: <PriceList />,
  },

  {
    name: "PurchaseRequestList",
    path: "/purchase/request-list",
    key: "/purchase/request-list",
    route: "/purchase/request-list",
    element: <PurchaseRequestList />,
  },

  {
    name: "AddPurchaseRequest",
    path: "/purchase/add-request",
    key: "/purchase/add-request",
    route: "/purchase/add-request",
    element: <AddPurchaseRequest />,
  },

  {
    name: "PurchaseReturns",
    path: "/purchase/returns",
    key: "/purchase/returns",
    route: "/purchase/returns",
    element: <PurchaseReturns />,
  },

  {
    name: "PoRegisterList",
    path: "/purchase/register-list",
    key: "/purchase/register-list",
    route: "/purchase/register-list",
    element: <PoRegisterList />,
  },

  {
    name: "AddPoRegister",
    path: "/purchase/add-poregister",
    key: "/purchase/add-poregister",
    route: "/purchase/add-poregister",
    element: <AddPoRegister />,
  },

  {
    name: "PurchaseRqfList",
    path: "/purchase/rqf-list",
    key: "/purchase/rqf-list",
    route: "/purchase/rqf-list",
    element: <PurchaseRqfList />,
  },

  {
    name: "AddRfqProject",
    path: "/purchase/add-project",
    key: "/purchase/add-project",
    route: "/purchase/add-project",
    element: <AddRfqProject />,
  },

  {
    name: "AddRfqEnquiry",
    path: "/purchase/add-enquiry",
    key: "/purchase/add-enquiry",
    route: "/purchase/add-enquiry",
    element: <AddRfqEnquiry />,
  },

  {
    name: "PurchaseRqfListQuotations",
    path: "/purchase/quotations-list",
    key: "/purchase/quotations-list",
    route: "/purchase/quotations-list",
    element: <PurchaseRqfListQuotations />,
  },

  {
    name: "QuoteForOther",
    path: "/purchase/add-other",
    key: "/purchase/add-other",
    route: "/purchase/add-other",
    element: <QuoteForOther />,
  },

  {
    name: "QuoteForEnquiry",
    path: "/purchase/add-quote-enquiry",
    key: "/purchase/add-quote-enquiry",
    route: "/purchase/add-quote-enquiry",
    element: <QuoteForEnquiry />,
  },

  {
    name: "DocumentsDashboard",
    path: "/documents/dashboard",
    key: "/documents/dashboard",
    route: "/documents/dashboard",
    element: <DocumentsDashboard />,
  },
];

export default RouteArr;
